import React, { Component } from 'react';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';

import routeTemplates from 'ui/common/routes/templates';
import Menu from 'ui/common/menu';

export class AdminNavItems extends Component {
  render() {
    return (
      <li className="nav-item my-auto pr-3">
        <Menu text={<FormattedMessage id="app.layout.header.admin" defaultMessage="Admin" />}>
          <div className="py-3">
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.users.index}>
              <FormattedMessage id="app.layout.header.admin.users" defaultMessage="Users" />
            </DropdownItem>
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.companies.root}>
              <FormattedMessage id="app.layout.header.admin.companies" defaultMessage="Companies" />
            </DropdownItem>
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.ems.root}>
              <FormattedMessage id="app.layout.headers.features.ems" defaultMessage="EMS" />
            </DropdownItem>
            {process.env.NODE_ENV !== 'production' && (
              <DropdownItem tag={RouterLink} to={routeTemplates.admin.mongoMails}>
                <FormattedMessage
                  id="app.layout.header.admin.mongoMails"
                  defaultMessage="Mails (Dev)"
                />
              </DropdownItem>
            )}
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.skills.root}>
              <FormattedMessage id="app.layout.header.admin.skills" defaultMessage="Skills" />
            </DropdownItem>
            {/*<DropdownItem tag={RouterLink} to={routeTemplates.admin.badges.root}>*/}
            {/*  <FormattedMessage id="app.layout.header.admin.badges" defaultMessage="Badges" />*/}
            {/*</DropdownItem>*/}
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.claims.root}>
              <FormattedMessage id="app.layout.header.admin.claims" defaultMessage="Claims" />
            </DropdownItem>
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.assignments.root}>
              <FormattedMessage
                id="app.layout.header.admin.assignments"
                defaultMessage="Assignments"
              />
            </DropdownItem>
            <DropdownItem tag={RouterLink} to={routeTemplates.devControlPanel.root}>
              <FormattedMessage
                id="app.layout.header.admin.devControlPanel"
                defaultMessage="Control Panel (Dev)"
              />
            </DropdownItem>
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.stats.root}>
              <FormattedMessage id="app.layout.header.admin.stats" defaultMessage="Stats" />
            </DropdownItem>
            <DropdownItem tag={RouterLink} to={routeTemplates.admin.publicAlpha.root}>
              <FormattedMessage
                id="app.layout.header.admin.publicAlpha"
                defaultMessage="Blockbots (Public Alpha)"
              />
            </DropdownItem>

            {/*<DropdownItem tag={RouterLink} to={routeTemplates.admin.features.root}>*/}
            {/*  <FormattedMessage id="app.layout.header.features.stats" defaultMessage="Features" />*/}
            {/*</DropdownItem>*/}
            {/*<DropdownItem tag={RouterLink} to={routeTemplates.admin.reports.root}>*/}
            {/*  <FormattedMessage id="app.layout.header.reports.stats" defaultMessage="Reports" />*/}
            {/*</DropdownItem>*/}
          </div>
          {/*<div className="py-3">*/}
          {/*  /!*<DropdownItem tag={RouterLink} to={routeTemplates.admin.caseStudy.root}>*!/*/}
          {/*  /!*  <FormattedMessage*!/*/}
          {/*  /!*    id="app.layout.headers.features.case-study"*!/*/}
          {/*  /!*    defaultMessage="Case Study"*!/*/}
          {/*  /!*  />*!/*/}
          {/*  /!*</DropdownItem>*!/*/}
          {/*</div>*/}
        </Menu>
      </li>
    );
  }
}

export default withRouter(AdminNavItems);
