/*
  ClaimViewPdf
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';

import Avatar from 'ui/users/avatar';
import Alert from 'ui/common/alert';
import { pdfViewMessages } from './messages';
import ClaimProof from 'ui/graphql/ClaimProof/loadable';
import Feedback from 'ui/claims/details/feedback';
import PDFExportButton from 'ui/components/pdf-export-button/loadable';
import AssignmentDetails from 'ui/graphql/AssignmentDetails';
import FacebookShareButton from 'ui/components/facebook-share-button/loadable';
import TwitterShareButton from 'ui/components/twitter-share-button/loadable';
import LinkedinShareButton from 'ui/components/linkedin-share-button/loadable';
import FlaggedReasonsDisplay from 'ui/components/flagged-reasons-display/loadable';
// import Button from 'ui/common/button';
import ExternalLink from 'ui/common/external-link';
import styles from './index.module.scss';
import routeGenerators from 'ui/common/routes/generators';
import ProgressChart from 'ui/common/charts/progress-chart';
import { getSkillDisplayName } from 'store/features/skills/helpers';
import PercentileCurve from '../details/percentileCurve';
import { processFlaggedReasons } from 'store/features/claims/helpers';
import { COMPANY_IDS_WITH_DISABLED_VOTES_COLUMNS } from '../details/feedback';

const placeholderDescriptions = ['partner claim'];

class ClaimReportView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  static propTypes = {
    claim: PropTypes.object,
    isCurrentUserAdmin: PropTypes.bool,
    dataSets: PropTypes.arrayOf(PropTypes.object),
    noOfMinVotes: PropTypes.number,
    endorsedCount: PropTypes.number,
    flaggedCount: PropTypes.number,
    isUserCompanyAdmin: PropTypes.bool,
    isClaimOwnerProfileOwner: PropTypes.bool,
    showSocialShareButtons: PropTypes.bool,
    totalNumberOfVotes: PropTypes.number,
  };

  renderClaimGeneralInfoPDF() {
    const {
      claim,
      intl: { formatMessage },
      dataSets,
      endorsedCount,
      // flaggedCount,
    } = this.props;
    const { hackathonMeta, normalizedRating } = claim;
    const currentCompanyId = claim && claim.company && claim.company.id;
    const votesChartDisabled =
      COMPANY_IDS_WITH_DISABLED_VOTES_COLUMNS.indexOf(currentCompanyId) > -1;
    return (
      <div className="col-12">
        {(claim.type === 'BOAST' || !claim.type) &&
          hackathonMeta && (
            <div className={classnames(styles.whiteShadedBox)}>
              <div className="row pb-1 d-flex">
                <div>
                  <div className="col-12 pt-2 d-flex align-self-center">Name:</div>
                  <div className="col-12 pt-1 d-flex align-self-center">
                    <p className="gray text-whitespace-pre-wrap pb-1">
                      {hackathonMeta.participantFirstName} {hackathonMeta.participantLastName}
                    </p>
                  </div>
                </div>
                {votesChartDisabled && (
                  <div style={{ marginRight: '25px' }} className="ml-auto">
                    <div className="col-12 pt-2 d-flex align-self-center">Score:</div>
                    <div className="col-12 pt-1 d-flex align-self-center">
                      <p className="gray text-whitespace-pre-wrap pb-1">{normalizedRating}</p>
                    </div>
                  </div>
                )}
                {/* <div className="ml-auto">
                  <Button
                    style={{ outline: 'none' }}
                    isReady={false}
                    className="color-light-orange mx-1"
                  >
                    <i className={classnames('fa fa-arrow-up pr-2')} /> Upvotes (
                    {hackathonMeta.upvote})
                  </Button>
                  <Button
                    style={{ outline: 'none' }}
                    isReady={false}
                    className="color-light-red mx-1"
                  >
                    <i className={classnames('fa fa-arrow-down pr-2')} /> Downvotes ({hackathonMeta.downvote})
                  </Button>
                </div> */}
              </div>
              {hackathonMeta.participantCV && (
                <div className="row pb-1">
                  <div className="col-12 pt-2 d-flex align-self-center">CV Link:</div>
                  <div className="col-12 pt-1 d-flex align-self-center">
                    <ExternalLink href={hackathonMeta.participantCV} openInNewTab>
                      <p className="gray text-whitespace-pre-wrap pb-1">
                        {hackathonMeta.participantCV}
                      </p>
                    </ExternalLink>
                  </div>
                </div>
              )}
            </div>
          )}
        {!hackathonMeta &&
          (claim.type === 'BOAST' || !claim.type) &&
          claim.desc &&
          placeholderDescriptions.indexOf(claim.desc.toLowerCase()) < 0 && (
            <div className={classnames(styles.whiteShadedBox)}>
              <div className="row pb-1">
                <div className="col-12 pt-2 d-flex align-self-center">
                  {formatMessage(pdfViewMessages.description)}
                </div>
                <div className="col-12 pt-1 d-flex align-self-center">
                  <p
                    className="gray text-whitespace-pre-wrap pb-1"
                    dangerouslySetInnerHTML={{
                      __html: claim.desc.substring(0, 58),
                    }} // Quick fix, i am sorry
                  />
                </div>
              </div>
            </div>
          )}

        {!votesChartDisabled &&
          dataSets &&
          dataSets.length && (
            <div className={classnames(styles.whiteShadedBox, 'mt-3')}>
              <div className="row">
                {/* Recommended by 5 out of 6 Experts */}
                <div className="col-12 text-center">
                  <FormattedMessage
                    id="graphql.ClaimViewPdf.pdfViewMessages.recommendedByExperts"
                    defaultMessage="Recommended by {endorsedCount} out of {totalNumberOfVotes} Experts"
                    values={{
                      endorsedCount: endorsedCount,
                      totalNumberOfVotes:
                        claim.feedback &&
                        claim.feedback.explanation.filter(exp => {
                          return exp && !exp.isCommentHidden;
                        }).length,
                    }}
                  />
                </div>
                <div className="col-2 pt-3 d-flex align-self-center">
                  {formatMessage(pdfViewMessages.votesReceived)}
                </div>
                <div className="col-10 pt-3">
                  <ProgressChart labelLegend="Votes" dataSets={dataSets} />
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }

  getSkillDisplayNames() {
    const { claim } = this.props;
    if (!claim) return [];
    let skillNames =
      claim.skills && claim.skills.length > 0
        ? claim.skills
            .map(cs => cs && cs.skill && cs.skill.name && cs.skill.name.trim())
            .filter(Boolean)
        : [];
    if (!skillNames || skillNames.length === 0) {
      skillNames =
        claim.skill && claim.skill.name ? [claim.skill.name.trim()].filter(Boolean) : [''];
    }
    const skillDisplayNames = skillNames
      .map(name => getSkillDisplayName({ skill: { name } }, true))
      .filter(Boolean);
    return skillDisplayNames;
  }

  renderClaimantGeneralInfo() {
    const { claim, intl: { formatMessage }, isCurrentUserAdmin } = this.props;
    const { owner: user } = claim;

    const skillDisplayNames = this.getSkillDisplayNames();

    return (
      <div className={'col-12 px-5'}>
        <div className={'row justify-content-center'}>
          {user &&
            user.name && (
              <div className={classnames('col-md-6')}>
                <div className={classnames(styles.parentCell)}>
                  <a
                    className="pointer"
                    href={`${routeGenerators.users.vanityProfile(user)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={classnames(styles.childCell)}>
                      <Avatar
                        user={user}
                        size={70}
                        className={classnames('text-center', 'align-middle', 'mr-2')}
                        disableURL
                      />
                    </div>
                    <div className={classnames(styles.childCell, styles.wordWrap)}>
                      <div>{user.name}</div>
                      <div>@{user.username}</div>
                    </div>
                  </a>
                </div>
              </div>
            )}

          <div
            className={classnames({
              'col-md-6': user && user.name,
              'col-md-12': !(user && user.name),
            })}
          >
            <div className={classnames(styles.subTitle)}>
              {claim &&
                claim.company &&
                claim.company.id !== '5e259d516750c80001cf3b7f' &&
                skillDisplayNames &&
                skillDisplayNames.length > 0 && (
                  <div className="d-flex d-inline-items pt-3">
                    <span className="gray">{formatMessage(pdfViewMessages.skill)}</span>

                    <span className="px-1">
                      <b>{skillDisplayNames.join(', ')}</b>
                    </span>
                  </div>
                )}

              {claim &&
                !claim.company &&
                skillDisplayNames &&
                skillDisplayNames.length > 0 && (
                  <div className="d-flex d-inline-items pt-3">
                    <span className="gray">{formatMessage(pdfViewMessages.skill)}</span>

                    <span className="px-1">
                      <b>{skillDisplayNames.join(', ')}</b>
                    </span>
                  </div>
                )}

              {/* {claim &&
                claim.level &&
                claim.level.name && (
                  <div className="d-flex d-inline-items pt-3">
                    <span className="gray">{formatMessage(pdfViewMessages.proficiencyLevel)}</span>
                    <span className="px-1">
                      <b>{claim.level.name}</b>
                    </span>
                  </div>
                )} */}
              {/* Show partner claim details, e.g cutshort.io */}
              {isCurrentUserAdmin &&
                claim.company &&
                claim.company.companyName && (
                  <div className="table-row ml-auto">
                    <div className="table-cell pt-3">
                      <span className="gray">
                        {formatMessage(pdfViewMessages.claimPartnerName)}
                      </span>
                    </div>
                    <div className="table-cell pl-3 pt-3">
                      <b>{claim.company.companyName}</b>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* Show repo link as requested by cutshort.io */}
        {claim.proof && (
          <div className={classnames(styles.subTitle, 'row pb-2')}>
            <div className="col-12 pt-3 d-flex align-self-center">
              <span className="gray">{formatMessage(pdfViewMessages.repositoryLink)}</span>
            </div>
            <div className="col-12 d-flex align-self-center">
              <b>
                <ClaimProof claimId={claim.id} claimProof={claim.proof} />
              </b>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderPDFViewTypeBoast() {
    // ID-1470: HUGE assumption, only show with print-friendly view.
    const { claim, intl: { formatMessage }, noOfMinVotes } = this.props;

    return (
      <div className={classnames('container pb-5 mt-2', styles.printWidth)}>
        <div className={classnames('row py-3', styles.greyBackground)} id="export-pdf-title">
          <div className="col-12 px-5">
            <h4 className="font-weight-bold">{formatMessage(pdfViewMessages.title)}</h4>
          </div>
        </div>
        <div
          className={classnames('row justify-content-center py-3', styles.greyBackground)}
          id="export-pdf-content-1"
        >
          {this.renderClaimantGeneralInfo()}
          {/* {this.renderReportedReasons()} */}
          {this.renderClaimGeneralInfoPDF()}
          {this.renderPercentileCurve()}
          <div className="col-12">
            <div className={classnames(styles.whiteShadedBox, 'mt-3')}>
              {claim.feedback &&
                !!noOfMinVotes && (
                  <Feedback
                    id="feedback-section"
                    className="mx-auto"
                    claim={claim}
                    feedback={claim.feedback}
                    isPrintView={true}
                    showOnly="starRatings"
                  />
                )}
            </div>
          </div>
        </div>

        {this.moreThanXComments(0) && this.renderCommentsPage('export-pdf-content-2', 0, 4)}
        {this.moreThanXComments(4) && this.renderCommentsPage('export-pdf-content-3', 4, 7)}
        {this.moreThanXComments(7) && this.renderCommentsPage('export-pdf-content-4', 7, 10)}
      </div>
    );
  }

  moreThanXComments(x) {
    const { claim } = this.props;
    return (
      claim.feedback &&
      claim.feedback.explanation &&
      claim.feedback.explanation.filter(exp => {
        return exp && !exp.isCommentHidden;
      }).length > x
    );
  }

  renderPercentileCurve() {
    const { claim } = this.props;
    const currentCompanyId = claim && claim.company && claim.company.id;
    const showBottomText = !(
      COMPANY_IDS_WITH_DISABLED_VOTES_COLUMNS.indexOf(currentCompanyId) > -1
    );
    return (
      <div className="col-12">
        <div className={classnames(styles.whiteShadedBox, 'mt-3')}>
          <PercentileCurve claim={claim} showBottomText={showBottomText} />
        </div>
      </div>
    );
  }

  renderCommentsPage(divId, startCommentIndex, endCommentIndex) {
    const { claim, noOfMinVotes } = this.props;

    return (
      <div
        className={classnames('row justify-content-center py-3', styles.greyBackground)}
        id={divId}
      >
        {this.renderClaimantGeneralInfo()}
        <div className="col-12">
          <div className={classnames(styles.whiteShadedBox, 'mt-3')}>
            {claim.feedback &&
              !!noOfMinVotes && (
                <Feedback
                  id="feedback-section"
                  className="col-12 mx-auto"
                  claim={claim}
                  feedback={claim.feedback}
                  isPrintView={true}
                  startCommentIndex={startCommentIndex}
                  endCommentIndex={endCommentIndex}
                  showOnly="textFeedback"
                />
              )}
          </div>
        </div>
      </div>
    );
  }

  renderPDFViewTypeAssignment() {
    // ID-1470: HUGE assumption, only show with print-friendly view.
    const { claim, intl: { formatMessage }, noOfMinVotes } = this.props;

    return (
      <div className={classnames('container pb-5 mt-2', styles.printWidth, styles.greyBackground)}>
        <div className={classnames('row py-3', styles.greyBackground)} id="export-pdf-title">
          <div className="col-12 px-5">
            <h4 className="font-weight-bold">{formatMessage(pdfViewMessages.title)}</h4>
          </div>
        </div>
        <div
          className={classnames('row justify-content-center py-3', styles.greyBackground)}
          id="export-pdf-content-1"
        >
          {this.renderClaimantGeneralInfo()}
          {/* {this.renderReportedReasons()} */}
          {claim &&
            claim.assignmentId && (
              <AssignmentDetails assignmentId={claim.assignmentId} isPrintPDF={true} />
            )}
        </div>
        <div
          className={classnames('row justify-content-center py-3', styles.greyBackground)}
          id="export-pdf-content-2"
        >
          {this.renderClaimantGeneralInfo()}
          {this.renderClaimGeneralInfoPDF()}
          {this.renderPercentileCurve()}
          <div className="col-12">
            <div className={classnames(styles.whiteShadedBox, 'mt-3')}>
              {claim.feedback &&
                !!noOfMinVotes && (
                  <Feedback
                    id="feedback-section"
                    className="col-12 mx-auto"
                    claim={claim}
                    feedback={claim.feedback}
                    isPrintView={true}
                    showOnly="starRatings"
                  />
                )}
            </div>
          </div>
        </div>
        {this.moreThanXComments(0) && this.renderCommentsPage('export-pdf-content-3', 0, 4)}
        {this.moreThanXComments(4) && this.renderCommentsPage('export-pdf-content-4', 4, 7)}
        {this.moreThanXComments(7) && this.renderCommentsPage('export-pdf-content-5', 7, 10)}
      </div>
    );
  }

  renderPDFView() {
    const { claim } = this.props;
    if (claim.type === 'ASSIGNMENT') {
      return this.renderPDFViewTypeAssignment();
    } else if (claim.type === 'BOAST' || !claim.type) {
      return this.renderPDFViewTypeBoast();
    }
  }

  /*
    ID-1862 Show red box in page 1 if one validator flag plagiarism
  */

  renderReportedReasons() {
    const { claim } = this.props;
    const processedReasons = processFlaggedReasons(claim.flaggedReasonType);
    if (processedReasons.length) {
      return (
        <div className="col-12">
          <Alert className="color-light-red" closeBtn={false} disableTimeout>
            <span>
              <FlaggedReasonsDisplay
                flaggedReasonType={claim.flaggedReasonType}
                displayView="pureText"
              />
            </span>
          </Alert>
        </div>
      );
    }

    return null;
  }

  /* ID-1824 Let people share report pdf link on social media */
  renderSocialShareButtons() {
    const finalUrlToShare = `${window.location.href}`;

    return (
      <div className="ml-2">
        <FacebookShareButton
          className="mr-2"
          quote={`
            Here it is! My very own Code Report by Indorse!
            #Coding #Programming
            `}
          hashtag="#indorse"
          href={`${finalUrlToShare}?utm_source=Facebook_Button&utm_content=Coding_Skill_Report_Facebook_Button`}
        />

        <LinkedinShareButton
          className="mr-2"
          contentToShare={`
Here it is! My very own #Code Report by #Indorse!
#Coding #Programming
${finalUrlToShare}?utm_source=LinkedIn_Button&utm_content=Coding_Skill_Report_LinkedIn_Button
          `}
        />

        <TwitterShareButton
          contentToShare={`I just received my #Code Report by @joinindorse! This is only the beginning! 🚀`}
          url={`${finalUrlToShare}?utm_source=Twitter_Button&utm_content=Coding_Skill_Report_Twitter_Button`}
          hashtags={'Coding,Programming,Indorse'}
        />
      </div>
    );
  }

  renderAdminPDFController() {
    const { claim, intl: { formatMessage }, showSocialShareButtons } = this.props;

    // if (!isCurrentUserAdmin && !isUserCompanyAdmin && !isClaimOwnerProfileOwner) return null;

    let divIDsToExport;
    if (claim.type === 'ASSIGNMENT') {
      divIDsToExport = ['export-pdf-content-1', 'export-pdf-content-2', 'export-pdf-content-3'];
      if (this.moreThanXComments(4)) {
        divIDsToExport.push('export-pdf-content-4');
      }

      if (this.moreThanXComments(7)) {
        divIDsToExport.push('export-pdf-content-5');
      }
    } else if (claim.type === 'BOAST' || !claim.type) {
      divIDsToExport = ['export-pdf-content-1', 'export-pdf-content-2'];
      if (this.moreThanXComments(4)) {
        divIDsToExport.push('export-pdf-content-3');
      }

      if (this.moreThanXComments(7)) {
        divIDsToExport.push('export-pdf-content-4');
      }
    }

    return (
      <div className={classnames('container', styles.printWidth)}>
        <div className={classnames('d-flex justify-content-end mt-3')}>
          <PDFExportButton
            divIDsToExport={divIDsToExport}
            fileName={`claim-${claim.id}`}
            divIDTitle="export-pdf-title"
          >
            {formatMessage(pdfViewMessages.generatePDF)}
          </PDFExportButton>

          {showSocialShareButtons && this.renderSocialShareButtons()}
        </div>
      </div>
    );
  }

  render() {
    const { claim } = this.props;

    // Very important, without claim all render functions will not work
    if (!claim) return null;

    return (
      <div className={classnames('py-4')}>
        {this.renderAdminPDFController()}
        {this.renderPDFView()}
      </div>
    );
  }
}

export default injectIntl(ClaimReportView);
