import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const label = defineMessages({
  [fieldNames.adminEmail]: {
    id: 'graphql.create-ems.company-admin.email',
    defaultMessage: `Email`,
  },
});

export const buttons = defineMessages({
  submitButton: {
    id: 'graphql.create-ems.submit.company',
    defaultMessage: 'Start Free Trial',
  },
  slackButton: {
    id: 'graphql.create-ems.slack.company',
    defaultMessage: 'Try with Slack',
  },
});
export const content = defineMessages({
  pageTitle: {
    id: 'pages.assignment-magic-thanks.page-title',
    defaultMessage: 'Verify',
  },
  pageSubTitle: {
    id: 'pages.assignment-magic-thanks.page-sub-title',
    defaultMessage:
      'To verify your email, please check your inbox. Do not forget to check your spam folder and whitelist our email - info@indorse.io',
  },
  pageCompanies: {
    id: 'pages.assignment-magic-thanks.page-companies',
    defaultMessage: "You're in good company.",
  },
});

export const hero = {
  subTitle: {
    id: 'pages.ems.hero.graphql.subtitle',
    defaultMessage:
      "Back your intuition with data and engineering insights. Improve your team's performance, reduce tech debt, and deliver projects faster.",
  },
};
