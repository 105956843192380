import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import styles from './index.module.scss';
import Card from 'ui/common/card/Card';
import { faq_list } from './faq-list';

const MetamorphFAQContainer = props => {
  const [collapsed, setCollapsed] = useState(null);
  return (
    <div className={classnames(styles.faqContainer)}>
      <div className={classnames('page-max-width')}>
        <div className={classnames('container-fluid')}>
          <h1 className={classnames('text-center', styles.title)}>Frequently Asked Questions</h1>
          <section>
            {faq_list.map((faq, idx) => (
              <Card
                key={idx}
                borderless
                className={classnames(styles.collapse)}
                onClick={() => setCollapsed(collapsed !== idx ? idx : null)}
              >
                <h2
                  className={classnames(
                    styles.collapseHeaderTitle,
                    'd-flex align-items-center justify-content-between mb-2'
                  )}
                >
                  {faq.ques}{' '}
                  <i className={idx === collapsed ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
                </h2>
                <Collapse isOpen={idx === collapsed}>{faq.ans}</Collapse>
              </Card>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(MetamorphFAQContainer);
