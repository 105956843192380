import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import classnames from 'classnames';

import styles from './index.module.scss';
import Button from 'ui/common/button';
import { facebookAuth } from 'store/features/auth/actions';
import { loadFacebookSdkLib } from 'store/libs/actions';

import {
  selectIsFacebookAuthAvailable,
  selectIsAuthenticatingWithFacebook,
  selectIsAuthenticatingWithExternalAccount,
} from 'store/features/auth/selectors';
import { logEvent } from 'services/amplitude-helper';

const { request: facebookAuthRequest } = facebookAuth;

class LoginWithFacebookButton extends Component {
  static propTypes = {
    existingValues: PropTypes.object,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  componentDidMount() {
    this.props.loadFacebookSdkLib();
  }

  onClick() {
    const { facebookAuthAvailable, facebookAuthRequest, existingValues, from, label } = this.props;
    logEvent('LP_getstartedmodal_facebook_clicked', { source: from, label });
    localStorage.setItem('ems_social_login_with', 'facebook');
    if (facebookAuthAvailable) {
      facebookAuthRequest({ existingValues, from });
    } else {
      this.setState({ tooltipOpen: true });
    }
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    const { facebookAuthAvailable, authenticatingWithExternalAccount } = this.props;

    return (
      <Button
        className={classnames(styles.facebookBtn, styles.socialBtn, 'px-4 position-relative')}
        isReady={false}
        disabled={!facebookAuthAvailable || authenticatingWithExternalAccount}
        onClick={this.onClick}
      >
        <i
          className="fab fa-facebook-square mr-2"
          style={{ fontSize: '24px', position: 'absolute', left: '25px' }}
        />
        <span style={{ marginLeft: '37px' }}>
          <FormattedMessage
            id="metamorph.auth.facebook-button"
            defaultMessage="Sign up with Facebook"
          />
        </span>
      </Button>
    );
  }
}

function mapStateToProps(state) {
  return {
    facebookAuthAvailable: selectIsFacebookAuthAvailable(state),
    authenticatingWithExternalAccount: selectIsAuthenticatingWithExternalAccount(state),
    authenticatingWithFacebook: selectIsAuthenticatingWithFacebook(state),
  };
}

const mapDispatchToProps = {
  facebookAuthRequest,
  loadFacebookSdkLib: loadFacebookSdkLib.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginWithFacebookButton));
