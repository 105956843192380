import React from 'react';
export const faq_list = [
  {
    ques: 'What is Metamorph?',
    ans: (
      <React.Fragment>
        <p>
          <a href="https://indorse.io/metamorph">Metamorph by Indorse</a> is an online tool which
          gives Engineering Managers and Leaders clear visibility into their software engineering
          workflows.
        </p>
        <p>Metamorph will offer you:</p>
        <ul className="ml-3">
          <li>Standardized and customisable templates focused on your KPIs and OKRs</li>
          <li>
            Actionable analytics aggregated from all the tools your engineers are already using
            (GitHub, Jira, Slack, Azure, Jenkins, etc.)
          </li>
          <li>
            Insightful dashboards for an end-to-end picture of your engineering workflow(s) to take
            data-backed actions
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    ques: 'Why use Metamorph?',
    ans: (
      <React.Fragment>
        <p>
          Metamorph is a tool used by Engineering Leaders to better run their software engineering
          projects and lead their engineering teams.
        </p>
        <p>In a nutshell:</p>
        <ul className="ml-3">
          <li>
            Research-based: 75+ roundtables with more than 250 Engineering Managers over a course of
            one year!
          </li>
          <li>Transparent & understandable engineering workflows</li>
          <li>Ready-to-use industry-standard engineering templates</li>
          <li>Easy-to-understand reports for all stakeholders</li>
          <li>Security-first approach: Metamorph doesn’t access your code</li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    ques: 'What are the main benefits of Metamorph?',
    ans: (
      <React.Fragment>
        <p>
          Metamorph helps Engineering Leaders sustain high-performing teams with deep insights into
          their engineering workflows. Our goal is to make your engineering team perform even
          better!
        </p>
        <ul className="ml-3">
          <li>
            Prioritize efficiently engineering metrics along with business metrics. Get data to back
            up your decisions and intuitions for tech debt, improving build time, test coverage,
            stakeholder management, etc.
          </li>
          <li>
            Build and use clear metrics for your software engineering teams. Communicate with
            confidence with product managers and stakeholders via easy-to-understand reports
          </li>
          <li>
            Understand the crucial aspects of your current engineering workflows to remove
            bottlenecks and deliver faster & better
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    ques: 'How does the onboarding work?',
    ans: (
      <React.Fragment>
        <p>
          It’s as easy as 1-2… that’s it. You will just need to select one of our pre-built
          industry-standard templates and authenticate the relevant tool(s) (GitHub, Jira, Jenkins,
          etc.).
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: 'Do I need to add a credit card to get started?',
    ans: (
      <React.Fragment>
        <p>
          No. You will only need to add your credit card after the end of 30-day your free trial.
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: 'Does Metamorph have an on-premise solution?',
    ans: (
      <React.Fragment>
        <p>
          Yes, we do! Simply click the button under the Enterprise License column{' '}
          <a href="https://indorse.io/metamorph/pricing">here</a>. You can get access to a
          dockerized container of the Metamorph platform which can run on any platform - either on
          your own cloud servers or even your data centre.
        </p>
      </React.Fragment>
    ),
  },
];
