import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styles from './index.module.scss';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import { withRouter } from 'react-router-dom';
import routeTemplates from 'ui/common/routes/templates';
import Button from 'ui/common/button';

const points = [
  'Research-based: 75+ roundtables with more than 250 EMs',
  'Transparent & understandable engineering workflows',
  'Ready-to-use industry-standard engineering templates',
  'Easy-to-understand reports for all stakeholders',
  'Security-first approach: Metamorph doesn’t access your code',
];

export class CorePillars extends Component {
  renderDesktop() {
    return (
      <div className={classnames(styles.blueBox)}>
        <div className="row justify-content-center mb-4">
          <div className="col-12 col-md-6">
            <img
              src={`https://indorse-staging-bucket.s3.amazonaws.com/Why-Metamorph.png`}
              alt="why-mm"
              width="85%"
              className="mr-auto"
            />
          </div>
          <div className="col-12 col-md-6 pt-2">
            <div className={classnames(styles.infobox)}>
              <h2 className={classnames(styles.title)}>Why Metamorph</h2>
              {points.map(point => (
                <h3 className={styles.info} key={point}>
                  <div>
                    <i style={{ color: '#00B2A4' }} className="mr-3 fa fa-check-circle" />
                  </div>
                  <div>{point}</div>
                </h3>
              ))}
              <Button
                to={routeTemplates.emsPricingPage}
                isReady={true}
                className={classnames(styles.demoBtn, 'mt-3')}
              >
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    // const { handleSubmit, intl: { formatMessage }, form: formName } = this.props;
    return (
      <div className="mt-5 pt-5">
        <div className={classnames(styles.infobox)}>
          <h2 className={classnames('text-center', styles.title)}>Why Metamorph</h2>
          <div className="col-12 col-md-6 pt-2">
            <div className={classnames(styles.infobox)}>
              {points.map(point => (
                <h3 className={styles.info} key={point}>
                  <div>
                    <i style={{ color: '#00B2A4' }} className="mr-3 fa fa-check-circle" />
                  </div>
                  <div>{point}</div>
                </h3>
              ))}
              <Button
                to={routeTemplates.emsPricingPage}
                isReady={true}
                className={classnames(styles.demoBtn, 'mt-3')}
              >
                Learn More
              </Button>
            </div>
          </div>
        </div>
        {/* <img
          src={`https://indorse-staging-bucket.s3.amazonaws.com/Why-Metamorph.png`}
          alt="why-mm"
          width="300"
          className="d-block mx-auto"
        /> */}
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className={classnames('page-max-width')}>
          <div className={classnames('container-fluid mt-5 mb-4 pb-3')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(CorePillars));
