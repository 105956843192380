import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from 'ui/common/loading';

import * as storage from 'store/features/auth/storage';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import EmsApps from 'store/graphql/EmsApps/reducer';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { errorsToSingleMessage, resultHasErrors } from '../../../utils/graphql-errors';
import { messageTypes } from '../../../store/features/common/flash/builder';
import { addMessage } from '../../../store/features/common/flash/actions';
import { show as showModal } from 'redux-modal';
import { push } from 'react-router-redux';
import routeGenerators from '../../common/routes/generators';
export const GITHUB_INTEGRATION_COMPANY_OBJ_KEY = 'GITHUB_INTEGRATION_COMPANY_OBJ';
const SETUP = 'setup';
const CUSTOMIZE = 'customize';
const GithubAppIntegration = props => {
  const [isLoading, setLoading] = useState(false);
  // const [isError, setError] = useState(false);
  const [errCount, setErrorCount] = useState(0);

  const setCaEvent = async () => {
    const { client, company } = props;
    try {
      const resultFromGraphQl = await client.mutate({
        mutation: gql`
          mutation ems_set_caEvent(
            $companyId: String!
            $type: EmsCAEventEnum!
            $status: EmsCAEventStatusEnum!
          ) {
            ems_set_caEvent(companyId: $companyId, type: $type, status: $status) {
              type
              status
            }
          }
        `,
        variables: {
          companyId: company && company.id,
          type: 'COMPLETED_INSTALLATION_GITHUB',
          status: 'DONE',
        },
      });
      // console.log('logged CA event : ', caEvent);
      if (resultFromGraphQl.error) {
        console.log(resultFromGraphQl.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchCurrentPlan = async () => {
    try {
      const { client, addMessage, gitCode, gitInstallationId, company, push, from } = props;

      const response = await client.mutate({
        mutation: gql`
          mutation githubApp_postInstallation(
            $code: String!
            $installationId: Int!
            $companyId: String!
          ) {
            githubApp_postInstallation(
              code: $code
              installationId: $installationId
              companyId: $companyId
            ) {
              companyPrettyId
            }
          }
        `,
        variables: {
          code: gitCode,
          installationId: parseInt(gitInstallationId, 10),
          companyId: company && company.id,
        },
      });

      const { data, errors } = response;
      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        if (errCount < 5) {
          setErrorCount(errCount + 1);
          setTimeout(() => fetchCurrentPlan(), 1000);
        }
      } else {
        const { githubApp_postInstallation } = data;
        storage.deleteDataInLocalStorage(GITHUB_INTEGRATION_COMPANY_OBJ_KEY);
        // console.log('githubApp_postInstallation : ', githubApp_postInstallation);
        if (from === SETUP) {
          push(
            routeGenerators.ems.gitAppInstallSuccessSetup({
              prettyId: githubApp_postInstallation.companyPrettyId,
              companyId: company && company.id,
            })
          );
        } else if (from === CUSTOMIZE) {
          push(
            routeGenerators.ems.gitAppInstallSuccessCustomize({
              prettyId: githubApp_postInstallation.companyPrettyId,
              companyId: company && company.id,
            })
          );
        } else {
          push(
            routeGenerators.metamorph.edit({
              prettyId: githubApp_postInstallation.companyPrettyId,
              section: 'dashboard',
            })
          );
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    setCaEvent();
    setTimeout(() => fetchCurrentPlan(), 1000);
  }, []);

  if (errCount >= 5) return <h1 className={'mt-5 text-center'}>Error</h1>;

  return isLoading && <Loading />;
};
// http://localhost:4000/ems/github-app-callback?code=5ff9fdbc092211b1e6c1&installation_id=11476878&setup_action=install
function mapStateToProps(state, ownProps) {
  const query = new URLSearchParams(ownProps.location.search);
  const gitCode = query.get('code');
  const gitInstallationId = query.get('installation_id');
  let gitAppData = storage.getDataInLocalStorage(GITHUB_INTEGRATION_COMPANY_OBJ_KEY);
  gitAppData = JSON.parse(gitAppData);
  const { company, from } = gitAppData;
  return {
    gitCode,
    gitInstallationId,
    company,
    from,
  };
}

const mapDispatchToProps = {
  addMessage,
  showModal,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'EmsApps',
  reducer: EmsApps,
});

export default compose(withReducer, withConnect)(withApollo(GithubAppIntegration));

// http://localhost:4000/ems/github-app-callback?code=d3ca17496d490e3ae05f&installation_id=10872288&setup_action=install
