import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import autoBind from 'react-autobind';

import styles from './index.module.scss';
import Header from './header';
import Footer from './footer';
import Flash from 'ui/common/flash';

import routeTemplates from 'ui/common/routes/templates';
import { createMatchSelector } from 'ui/common/routes/selectors';

import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { resultHasErrors } from 'utils/graphql-errors';
import { isEqual } from 'lodash';
import {
  selectIsFetchingCompanies,
  selectMyCompanies,
} from 'store/graphql/AdminFetchMyCompanies/selectors';
import {
  selectCurrentUser,
  selectCurrentUserInitialized,
  selectIsLoggedIn,
} from 'store/features/auth/selectors';
import {
  selectAIPSkills,
  selectIsFetching as selectIsFetchingAIPSkills,
} from 'store/graphql/FetchAipSkills/selectors';
import { fetchMyCompanies } from 'store/graphql/AdminFetchMyCompanies/actions';
import { fetchAIPSkills } from 'store/graphql/FetchAipSkills/actions';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import AdminFetchMyCompaniesReducer from 'store/graphql/AdminFetchMyCompanies/reducer';
import FetchAIPSkillsReducer from 'store/graphql/FetchAipSkills/reducer';
import { fetchCompanyByPrettyId } from 'store/features/companies/actions';
import { logEvent } from 'services/amplitude-helper';

const {
  start: fetchMyCompaniesStart,
  success: fetchMyCompaniesSuccess,
  failure: fetchMyCompaniesFailure,
} = fetchMyCompanies;

const {
  start: fetchAIPSkillsStart,
  success: fetchAIPSkillsSuccess,
  failure: fetchAIPSkillsFailure,
} = fetchAIPSkills;

const { request: fetchCompanyByPrettyIdRequest } = fetchCompanyByPrettyId;

class Layout extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  static propTypes = {
    intl: intlShape,
  };

  componentDidMount() {
    this.fetchMyCompanies();
    this.getAIPSkills();
    const { prettyId, fetchCompanyByPrettyIdRequest } = this.props;
    if (!!prettyId) {
      fetchCompanyByPrettyIdRequest({ prettyId });
    }

    const ampIns = window.amplitude.getInstance();

    // email redirect
    if (this.props.email) {
      ampIns.setUserId(this.props.email);
      if (!window.localStorage.getItem('email')) {
        window.localStorage.setItem('email', this.props.email);
      }
    }

    if (this.props.emailType) {
      logEvent(`email_link_redirect_${this.props.emailType}`);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentUser &&
      nextProps.currentUserInitialized &&
      !isEqual(this.props.currentUser.myCompanies, nextProps.currentUser.myCompanies)
    ) {
      this.fetchMyCompanies(nextProps);
    }
  }

  async getAIPSkills() {
    const {
      client,
      fetchAIPSkillsStart,
      fetchAIPSkillsSuccess,
      fetchAIPSkillsFailure,
    } = this.props;
    fetchAIPSkillsStart();
    try {
      const response = await client.query({
        query: gql`
          query {
            getAIPSkills {
              name
              iconUrl
              _id
              category
            }
          }
        `,
      });

      const { data, errors } = response;

      if (resultHasErrors({ errors })) {
        fetchAIPSkillsFailure({ errors });
      } else {
        fetchAIPSkillsSuccess({ aipSkills: data.getAIPSkills });
      }
    } catch (error) {
      fetchAIPSkillsFailure({ errors: error });
    }
  }

  async fetchMyCompanies() {
    const {
      client,
      fetchMyCompaniesStart,
      fetchMyCompaniesSuccess,
      fetchMyCompaniesFailure,
      isFetchingCompanies,
    } = this.props;
    if (isFetchingCompanies) return;
    fetchMyCompaniesStart();
    try {
      const result = await client.query({
        query: gql`
          query getMyCompanies {
            getMyCompanies {
              id
              companyName
              prettyId
              features {
                magicLink
                partnerClaims
                ems
              }
              acl {
                user {
                  _id
                  username
                  name
                }
              }
            }
          }
        `,
      });
      if (resultHasErrors(result)) {
        fetchMyCompaniesFailure({ errors: result.errors });
      } else {
        const { getMyCompanies } = result.data;

        fetchMyCompaniesSuccess({
          result: {
            myCompanies: getMyCompanies,
          },
        });
      }
    } catch (error) {
      fetchMyCompaniesFailure({ errors: error.messages });
    }
  }

  render() {
    const { invertedFooter, myCompanies, withBanner, isSpecial } = this.props;
    return (
      <React.Fragment>
        <div className={classnames(styles.layout)}>
          {/* <div className={classnames(styles.bannerContainer)}>
            <div className={classnames(styles.MQImage)}>
              <img src="/metalinq-image.svg" alt="mq-imgs" />
            </div>
            <p className={classnames(styles.bannerText)}>
              Indorse x Metalinq: Interconnecting Metaverses!
            </p>
            <button className={classnames(styles.bannerButton)}>
              <a
                href="https://discord.com/invite/ZUqDBWMsWW"
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Now
              </a>
            </button>
          </div> */}
          <Header
            companies={myCompanies}
            className={classnames(styles.header)}
            withBanner={withBanner}
            isSpecial={isSpecial}
          />
          <div className={styles.content}>
            <div className={styles.flashWrapper}>
              <Flash className="container mt-3" />
            </div>
            {this.props.children}
          </div>
          <Footer inverted={invertedFooter} isSpecial={isSpecial} />
        </div>
      </React.Fragment>
    );
  }
}

const rootMatchSelecter = createMatchSelector({ path: routeTemplates.root, exact: true });
const aboutCompanyMatchSelecter = createMatchSelector({
  path: routeTemplates.aboutCompany,
  exact: true,
});

const claimsNewSelector = createMatchSelector({ path: routeTemplates.claims.new, exact: true });
const isForDevelopersPageSelector = createMatchSelector({
  path: routeTemplates.developersPage,
  exact: true,
});
const isProductsPageSelector = createMatchSelector({ path: routeTemplates.products, exact: true });
function mapStateToProps(state, ownProps) {
  const rootMatch = rootMatchSelecter(state);
  const aboutCompanyMatch = aboutCompanyMatchSelecter(state);
  const claimsNewMatch = claimsNewSelector(state);
  const isForDevelopersPage = isForDevelopersPageSelector(state);
  const isProductsPage = isProductsPageSelector(state);
  const routeParams = ownProps && ownProps.match && ownProps.match.params;
  const prettyId = routeParams && routeParams.prettyId;
  const query = ownProps && ownProps.location && ownProps.location.search;
  const queryParams = new URLSearchParams(query);
  const emailType = queryParams.get('email_type');
  const email = queryParams.get('email');
  return {
    prettyId,
    invertedFooter:
      !!rootMatch ||
      !!aboutCompanyMatch ||
      !!claimsNewMatch ||
      !!isForDevelopersPage ||
      !!isProductsPage,
    isLoggedIn: selectIsLoggedIn(state),
    isFetchingCompanies: selectIsFetchingCompanies(state),
    isFetchingAIPSkills: selectIsFetchingAIPSkills(state),
    aipSkills: selectAIPSkills(state),
    myCompanies: selectMyCompanies(state),
    currentUser: selectCurrentUser(state),
    currentUserInitialized: selectCurrentUserInitialized(state),
    emailType,
    email,
  };
}

const mapDispatchToProps = {
  fetchMyCompaniesStart,
  fetchMyCompaniesSuccess,
  fetchMyCompaniesFailure,
  fetchAIPSkillsStart,
  fetchAIPSkillsSuccess,
  fetchAIPSkillsFailure,
  fetchCompanyByPrettyIdRequest,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withAdminFetchMyCompaniesReducer = injectReducer({
  key: 'AdminFetchMyCompanies',
  reducer: AdminFetchMyCompaniesReducer,
});

const withFetchAIPSkillsReducer = injectReducer({
  key: 'FetchAIPSkills',
  reducer: FetchAIPSkillsReducer,
});

export default withRouter(
  compose(withAdminFetchMyCompaniesReducer, withConnect, withFetchAIPSkillsReducer)(
    injectIntl(withApollo(Layout))
  )
);
