import React from 'react';
import classnames from 'classnames';
import commonStyles from './index.module.scss';
import { withRouter } from 'react-router-dom';
import Hero from './hero';
import TemplatesSection from './templates';
// import BenefitsSection from './benefits';
import CorePillars from './core-pillars';
// import TryMetamorph from './try-metamorph';
import Integrations from './integrations';
import { connect } from 'react-redux';
import ExistingCustomers from 'ui/pages/home-V2/existing-customers/loadable';
// import Specialities from './specialities';
import HowItWorks from './how-it-works';
import routeTemplates from 'ui/common/routes/templates';
import { logEvent, logUserProperties } from 'services/amplitude-helper';
// import addEvent from 'utils/exit-intent';
import { show as showModal } from 'redux-modal';
// import ExitIntentModal, { MODAL_NAME as EXIT_INTENT_MODAL } from './exit-intent-modal';
// import { MODAL_NAME as EXIT_INTENT_MODAL } from './exit-intent-modal';
import MetamorphHelmet from 'ui/pages/ems-indorse/metamorph-helmet';
import styles from './index.module.scss';

class EMS extends React.Component {
  // clearExitIntent() {
  //   localStorage.setItem('exitIntent', 'false');
  // }

  componentDidMount() {
    // this.clearExitIntent();
    // addEvent(evt => {
    //   if (evt.toElement == null && evt.relatedTarget == null) {
    //     this.props.showModal(EXIT_INTENT_MODAL);
    //   }
    // });
    if (this.props.match.path === '/ems') this.props.history.push(routeTemplates.emsLandingPage);
    const query = new URLSearchParams(this.props.location.search);
    const utm_source = query.get('utm_source');
    const utm_content = query.get('utm_content');

    const ampIns = window.amplitude.getInstance();

    if (window.localStorage.getItem('email')) {
      ampIns.setUserId(window.localStorage.getItem('email'));
    } else if (!window.localStorage.getItem('deviceId')) {
      // create random deviceId
      const randomDeviceId = Math.random()
        .toString(36)
        .substr(2, 9);
      window.localStorage.setItem('deviceId', randomDeviceId);
      ampIns.setDeviceId(randomDeviceId);
      if (utm_source) window.localStorage.setItem('utm_source', utm_source);
    } else {
      ampIns.setDeviceId(window.localStorage.getItem('deviceId'));
    }

    // if we have utm_source
    if (utm_source) {
      logUserProperties({ utm_source });
    }

    // if we have utm_content
    if (utm_content) {
      logUserProperties({ utm_content });
    }
    logEvent('landed_on_ems', {
      source: window && window.location.pathname,
    });
  }
  render() {
    return (
      <div className={classnames(commonStyles.home)}>
        <MetamorphHelmet />
        <Hero />
        <ExistingCustomers />
        <TemplatesSection />
        <HowItWorks />
        {/* <BenefitsSection /> */}
        <Integrations />
        <CorePillars />
        {/* <Stats /> */}
        {/* <Extension /> */}
        {/* <TryMetamorph /> */}
        {/* <Cta /> */}
        {/* <ExitIntentModal /> */}
        <div className={classnames(styles.blueGradient)} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(withRouter(EMS));
