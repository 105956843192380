import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import themeConfig from 'ui/theme/config';
import styles from './index.module.scss';
import { hide as hideModal, show as showModal } from 'redux-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import PulseSurveyModal, { MODAL_NAME as SURVEY_MODAL } from './survey-modal';
// import PulseSurveyMobileModal, { MODAL_NAME as SURVEY_MODAL_MOBILE } from './survey-modal-mobile';
// import LineTopSvg from 'resources/page/ems/metamorph/Line-top.svg';
// import LineBottomSvg from 'resources/page/ems/metamorph/Line-bottom.svg';
import LineSvg from 'resources/page/ems/metamorph/Arrow.png';
import { logEvent } from 'services/amplitude-helper';
import { MODAL_NAME as EMS_CONTACT_MODAL } from 'ui/graphql/EmsContactForm';
import { withApollo } from 'react-apollo';
// import { addMessage } from 'store/features/common/flash/actions';
import gql from 'graphql-tag';
import { messageTypes } from 'store/features/common/flash/builder';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import { MODAL_NAME as GET_STARTED_MODAL } from '../hero/get-started-modal';

import { MODAL_NAME as GET_STARTED_MODAL_MOBILE } from '../hero/get-started-modal-mobile';
// import InternalLink from 'ui/common/internal-link';
// import routeTemplates from 'ui/common/routes/templates';
import * as storage from '../../../../store/features/auth/storage';
import { withRouter } from 'react-router';
import routeGenerators from '../../../common/routes/generators';
import ErrorModal, { MODAL_NAME as ERROR_MODAL } from '../hero/error-modal';
import ErrorModalMobile from '../hero/error-modal-mobile';
import VerifyEmailModal, { MODAL_NAME as VERIFY_EMAIL_MODAL } from '../hero/verify-email-modal';
import VerifyEmailModalMobile from '../hero/verify-email-modal-mobile';
import LoginUserModal, { MODAL_NAME as LOGIN_LOGIN_USER_MODAL } from '../hero/login-user-modal';
import LoginUserModalMobile from '../hero/login-user-modal-mobile';
import UserEmployeeModal, { MODAL_NAME as USER_EMPLOY_MODAL } from '../hero/user-employee-modal';
import UserEmployeeModalMobile from '../hero/user-employee-modal-mobile';
import LoggedInUserGetStartedModal, {
  MODAL_NAME as LOGGED_USER_GET_STARTED_MODAL,
} from '../hero/loggedin-user-get-started';
import CompanyExistsEmailSentModal, {
  MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL,
} from '../hero/ems-company-exists-for-domain-modal';
import CompanyExistsEmailSentModalLoggedIn, {
  MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN,
} from '../hero/ems-company-exists-for-domain-modal-loggedin';
import CompanyExistsEmailSentModalMobile from '../hero/ems-company-exists-for-domain-modal-mobile';
import CompanyExistsEmailSentModalLoggedInMobile from '../hero/ems-company-exists-for-domain-modal-loggedin-mobile';
import LoggedInUserGetStartedModalMobile from '../hero/loggedin-user-get-started-mobile';
// import Button from 'ui/common/button';
import { selectCurrentUser, selectIsLoggedIn } from '../../../../store/features/auth/selectors';

// import CirclePng from 'resources/page/ems/metamorph/Circle.png';

class HowItWorks extends Component {
  constructor() {
    super();
    this.state = { activeTab: 0 };
  }

  componentDidMount() {
    const { currentUser, adminEmail, alreadyExists, showModal } = this.props;
    window.fcWidget &&
      window.fcWidget.user.setProperties({
        firstName: currentUser && currentUser.name,
        email: currentUser && currentUser.email,
      });
    if (alreadyExists && adminEmail !== 'null') {
      showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN, { email: adminEmail });
    }
  }

  showContactForm = () => {
    this.props.showModal(EMS_CONTACT_MODAL, { showForm: true });
  };

  showConfirmModalToLoggedInUser() {
    this.props.showModal(LOGGED_USER_GET_STARTED_MODAL, {
      onSubmit: () => this.createCompanyForLoggedInUser(),
    });
  }

  async createCompanyForLoggedInUser() {
    const { email } = this.props.currentUser;
    const { client, addMessage, loginSuccessRequest, showModal } = this.props;

    logEvent('LP_getstartedmodal_getstarted_clicked', {
      label: 'Get Started',
      source: window && window.location.pathname,
      email,
    });

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_create_company($email: EmailAddress) {
            ems_create_company(email: $email) {
              status
              error
              token
              company {
                companyId
                companyPrettyId
                createdAt
              }
              emailDomainCompanies {
                acl {
                  email
                }
              }
            }
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { data, errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        logEvent('create_ems_company_failed', {
          error: errorsToSingleMessage({ errors }),
        });
      } else {
        // const { createCompany: { id } } = data;
        // if (id) {
        //   this.setState({
        //     isCompanyCreated: true,
        //   });
        // }
        // disable exit intent
        localStorage.setItem('popup', 'false');

        if (window.amplitude) {
          const ampInstance = window.amplitude.getInstance();
          window.localStorage.setItem('email', email);
          if (ampInstance) ampInstance.setUserId(email.toLowerCase());
        }
        logEvent('create_ems_company_waitlist_clicked', {
          label: 'Get Started',
          source: window && window.location.pathname,
          email: email,
        });
        this.props.hideModal(LOGGED_USER_GET_STARTED_MODAL);
        const { ems_create_company } = data;
        const error = ems_create_company && ems_create_company.error;
        if (!!error) {
          showModal(ERROR_MODAL, {});
        } else {
          const { status, token, company, emailDomainCompanies } = ems_create_company;

          if (status === 'ACCOUNT_CREATED') {
            const { companyPrettyId } = company;
            storage.setToken(token);
            loginSuccessRequest({
              token,
              redirect: true,
              from: routeGenerators.metamorph.edit({
                prettyId: companyPrettyId,
                section: 'dashboard',
              }),
            });
          } else if (status === 'ACCOUNT_UPSERTED') {
            const { companyPrettyId } = company;
            this.props.history.push(
              routeGenerators.metamorph.edit({
                prettyId: companyPrettyId,
                section: 'dashboard',
              })
            );
          } else if (
            status === 'ACCOUNT_EXISTS_UNVERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_UNVERIFIED'
          ) {
            // Change here to show popup
            showModal(VERIFY_EMAIL_MODAL, { email, onSubmit: () => null });
          } else if (
            status === 'ACCOUNT_EXISTS_VERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_VERIFIED'
          ) {
            // Change here to show popup
            showModal(LOGIN_LOGIN_USER_MODAL, { email, onSubmit: () => null });
          } else if (status === 'USER_IS_EMPLOYEE') {
            // Change here to show popup
            showModal(USER_EMPLOY_MODAL, { email, onSubmit: () => null });
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_EMAIL_SENT') {
            // Change here to show popup
            showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL, {});
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_LOGGED_IN') {
            try {
              const email =
                Array.isArray(emailDomainCompanies) && emailDomainCompanies.length > 0
                  ? emailDomainCompanies[0].acl[0].email
                  : null;
              showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN, { email });
            } catch (err) {
              showModal(ERROR_MODAL, {});
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
      logEvent('create_company_failed', {
        error: error.message,
        label: 'Get Started',
      });
    }
  }

  onSelectActiveTab(id) {
    this.setState({ activeTab: id });
  }

  renderTabDetails({ info, points, alt, img_url }) {
    return (
      <div className={classnames(styles.content, 'row justify-content-center ')}>
        <div className={classnames(styles.contentLeft, 'col-md-6')}>
          <img src={img_url} alt={alt} width="450" />
        </div>
        <div className={classnames(styles.contentRight, 'col-md-6')}>
          <h3 className={classnames(styles.info)}>{info}</h3>
          <div className={classnames(styles.info)}>
            {points.map((point, idx) => (
              <div className="d-flex justify-content-start my-3" key={idx}>
                <i
                  style={{ color: '#00B1A4', fontSize: '1rem' }}
                  className="fa fa-check-circle mr-3 mt-1"
                />
                <p>{point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderMobileTabDetails({ info, points, alt, img_url }) {
    return (
      <div className={classnames(styles.content, 'row justify-content-center')}>
        <div className={classnames('col-md-10 text-center')}>
          <img src={img_url} alt={alt} width="350" className="mx-auto mt-4" />
        </div>
        <div className={classnames('col-md-10')}>
          <h3 className={classnames(styles.info)}>{info}</h3>
          <div className={classnames(styles.info)}>
            {points.map((point, idx) => (
              <div className="d-flex justify-content-start my-3" key={idx}>
                <i
                  style={{ color: '#00B1A4', fontSize: '1rem' }}
                  className="fa fa-check-circle mr-3 mt-1"
                />
                <p>{point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderDesktop() {
    const { isLoggedIn, showModal, invalid } = this.props;
    return (
      <div className="position-relative">
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold mt-4 mb-3')}>
          How Metamorph works
        </h1>
        <h3 className={classnames(styles.mainSubtitle)}>
          Clear visibility into your software engineering workflows with our industry-standard
          templates.
        </h3>

        <div className="row justify-content-center align-items-center">
          <div className="col-4 pt-5">
            <h3 className={classnames(styles.step)}>STEP 1</h3>
          </div>
          <div className={classnames('col-4 pt-5')}>
            <h3 className={classnames(styles.step)}>STEP 2</h3>
          </div>
          <div className={classnames('col-4 pt-5')}>
            <h3 className={classnames(styles.step)}>STEP 3</h3>
          </div>
        </div>

        <div
          className="row justify-content-center align-items-center position-relative"
          style={{ zIndex: '10' }}
        >
          <div className="col-4">
            {/* <img src={CirclePng} width="100%" alt="circle1" className={classnames(styles.circle)} /> */}
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/MMStep-1.png"
              alt="step1"
              width="350"
              className="m-auto"
            />
          </div>
          <div className={classnames('col-4')}>
            {/* <img src={CirclePng} width="100%" alt="circle2" className={classnames(styles.circle)} /> */}
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/MMStep-2.png"
              alt="step2"
              width="350"
              className="m-auto"
            />
          </div>

          <div className={classnames('col-4')}>
            {/* <img src={CirclePng} width="100%" alt="circle3" className={classnames(styles.circle)} /> */}
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/MMStep-3.png"
              alt="step3"
              width="350"
              className="m-auto"
            />
          </div>
        </div>

        <div className="row justify-content-center align-items-center mb-4">
          <div className="col-4">
            <h2 className={styles.heading}>Choose and customize a template</h2>
          </div>
          <div className={classnames('col-4')}>
            <h2 className={styles.heading}>Authorize the tools</h2>
          </div>

          <div className={classnames('col-4')}>
            <h2 className={styles.heading}>Insightful dashboards</h2>
          </div>
        </div>

        <div className="row justify-content-center align-items-start">
          <div className="col-4">
            <p className={classnames(styles.text)}>
              Select templates for the engineering metrics or create your own that caters to your
              KPIs and OKRs
            </p>
          </div>
          <div className={classnames('col-4')}>
            <p className={classnames(styles.text)}>
              Easily connect with all the tools that your engineers are already using (GitHub, Jira,
              Slack, Azure, Jenkins, etc.)
            </p>
          </div>

          <div className={classnames('col-4')}>
            <p className={classnames(styles.text)}>
              Receive an end-to-end picture of your engineering workflow(s) and insights to take
              action
            </p>
          </div>
        </div>
        <div className="row justify-content-center mt-4 col-md-12 mx-auto">
          <button
            className={classnames(styles.getStartedBtn, 'mt-5')}
            isReady
            // className={classnames(styles.submitBtn, 'mx-3 px-5')}
            disabled={invalid}
            onClick={() => {
              logEvent('LP_root_getstarted_clicked', {
                label: 'Get Started',
                source: window && window.location.pathname,
              });
              !isLoggedIn
                ? showModal(GET_STARTED_MODAL, { onSubmit: this.onSubmit, label: 'Get Started' })
                : this.showConfirmModalToLoggedInUser();
            }}
          >
            Get Started
          </button>
        </div>
        <div className={classnames(styles.lineOne)}>
          <img src={LineSvg} width="100%" alt="line1" />
        </div>
        <div className={classnames(styles.lineTwo)}>
          <img src={LineSvg} width="100%" alt="line2" />
        </div>
      </div>
    );
  }

  renderMobile() {
    const { isLoggedIn, showModal, invalid } = this.props;
    return (
      <div className="mt-5 pt-5">
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold')}>How Metamorph works</h1>
        <h3 className={classnames(styles.mainSubtitle)}>
          Clear visibility into your software engineering workflows with our industry-standard
          templates.
        </h3>

        <div className="row justify-content-center align-items-center mt-4">
          <div className="col-12 py-3">
            <h3 className={classnames(styles.step)}>STEP 1</h3>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/MMStep-1.png"
              alt="step1"
              width="340"
              className="m-auto"
            />
          </div>
          <div className={classnames('col-10')}>
            <h2 className={styles.heading}>Choose and customize a template</h2>
            <p className={classnames(styles.text)}>
              Select templates for the engineering metrics or create your own that caters to your
              KPIs and OKRs
            </p>
            {/* <InternalLink to={routeTemplates.emsExtLandingPage} style={{ textDecoration: 'none' }}>
              <p className={classnames(styles.text, 'mt-4')}>
                Check this extension{' '}
                <span>
                  <i className="fa fa-chevron-right pl-2" />
                </span>
              </p>
            </InternalLink> */}
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-4">
          <div className="col-12 py-3">
            <h3 className={classnames(styles.step)}>STEP 2</h3>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/MMStep-2.png"
              alt="step2"
              width="340"
              className="m-auto"
            />
          </div>
          <div className={classnames('col-10')}>
            <h2 className={styles.heading}>Authorize the tools</h2>
            <p className={classnames(styles.text)}>
              Easily connect with all the tools that your engineers are already using (GitHub, Jira,
              Slack, Azure, Jenkins, etc.)
            </p>
            {/* <div onClick={() => showModal(SURVEY_MODAL_MOBILE)}>
              <p className={classnames(styles.text, 'cursor-pointer mt-4')}>
                Check it out{' '}
                <span>
                  <i className="fa fa-chevron-right pl-2" />
                </span>
              </p>
            </div> */}
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-4">
          <div className="col-12 py-3">
            <h3 className={classnames(styles.step)}>STEP 3</h3>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/MMStep-3.png"
              alt="step3"
              width="340"
              className="m-auto"
            />
          </div>
          <div className={classnames('col-10')}>
            <h2 className={styles.heading}>Insightful dashboards</h2>
            <p className={classnames(styles.text)}>
              Receive an end-to-end picture of your engineering workflow(s) and insights to take
              action
            </p>
            {/* <a href="#research" style={{ textDecoration: 'none' }}>
              <p className={classnames(styles.text, 'mt-4')}>
                Check industry standards{' '}
                <span>
                  <i className="fa fa-chevron-right pl-2" />
                </span>
              </p>
            </a> */}
          </div>
        </div>
        <div className="row justify-content-center mt-4 col-md-12 mx-auto">
          <button
            className={classnames(styles.getStartedBtn, 'mt-3')}
            isReady
            // className={classnames(styles.submitBtn, 'mx-3 px-5')}
            disabled={invalid}
            onClick={() => {
              logEvent('LP_root_getstarted_clicked', {
                label: 'Get Started',
                source: window && window.location.pathname,
              });
              !isLoggedIn
                ? showModal(GET_STARTED_MODAL_MOBILE, {
                    onSubmit: this.onSubmit,
                    label: 'Get Started',
                  })
                : this.showConfirmModalToLoggedInUser();
            }}
          >
            Get Started
          </button>
        </div>
        {/* <PulseSurveyMobileModal /> */}
        {/* <GetStartedModalMobile /> */}
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.blueBackground)}>
        <div className={classnames('page-max-width pb-3')}>
          <div className={classnames('container-fluid my-5')}>
            <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
              {this.renderDesktop()}
              <VerifyEmailModal />
              <LoginUserModal />
              <UserEmployeeModal />
              <LoggedInUserGetStartedModal />
              <ErrorModal />
              <CompanyExistsEmailSentModal />
              <CompanyExistsEmailSentModalLoggedIn />
            </MediaQuery>
          </div>
          <div className={classnames('container-fluid')}>
            <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
              {this.renderMobile()}
              <VerifyEmailModalMobile />
              <LoginUserModalMobile />
              <UserEmployeeModalMobile />
              <LoggedInUserGetStartedModalMobile />
              <ErrorModalMobile />
              <CompanyExistsEmailSentModalMobile />
              <CompanyExistsEmailSentModalLoggedInMobile />
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const query = new URLSearchParams(ownProps.location.search);
  const alreadyExists = Boolean(query.get('already_exists'));
  const adminEmail = query.get('admin_email');
  return {
    adminEmail,
    alreadyExists,
    isLoggedIn: selectIsLoggedIn(state),
    currentUser: selectCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withApollo(HowItWorks)))
);
