import { call, fork, put, takeEvery, take, race, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import { startSubmit, stopSubmit } from 'redux-form';
import { hide as hideModal } from 'redux-modal';
import jwtDecode from 'jwt-decode';
import { normalize } from 'normalizr';
import shortid from 'shortid';
import PromiseWindow from 'promise-window';
import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as types from './action-types';
import * as actions from './actions';
import * as selectors from './selectors';
import * as api from 'api/config/auth';
import callApi from 'store/api/saga';
import routeTemplates from 'ui/common/routes/templates';
import routeGenerator from 'ui/common/routes/generators';
import * as storage from './storage';
import * as flashActions from 'store/features/common/flash/actions';
import { messageTypes, messageIds, buildMessage } from 'store/features/common/flash/builder';
import messages from 'ui/auth/messages';
import * as libActionTypes from 'store/libs/action-types';
import * as libSelectors from 'store/libs/selectors';
import { isFile, convertToDataURL } from 'utils/file';

import { githubMethod } from 'resources/github';
// import { slackMethod } from 'resources/slack';
import { wizardNames } from 'ui/wizard/constants';
import { WIZARD_FINISHED } from 'store/features/wizard/action-types';
import { logEvent, logUserProperties } from 'services/amplitude-helper';

// ID-1313 set smart route in place
import * as smartRouteActions from 'store/features/smart-route/actions';
import * as smartRouteSelectors from 'store/features/smart-route/selectors';
// import { createMatchSelector } from 'ui/common/routes/selectors';

//import * as ethUtils from 'ethereumjs-util';
const afterSignupRedirectToStorageKey = 'after_signup_redirect_to';

function unwrapToken(token) {
  const decoded = jwtDecode(token);
  const { iat, exp } = decoded;
  const {
    _id: id,
    username,
    name,
    email,
    verified: emailVerified,
    approved,
    role,
    bio,
    tier,
  } = decoded;

  return {
    token: { value: token, iat, exp },
    user: {
      id,
      username,
      name,
      email,
      emailVerified,
      approved,
      role,
      bio,
      tier,
    },
  };
}

function afterLoginPath(user, { justSetPassword }) {
  const isLoginWithGithub = storage.getDataInLocalStorage(githubMethod.loginWithGithub);

  if (user.role && user.role === 'no_access') return routeTemplates.thankYou;

  if (user && (user.isMagicLinkUser || user.type === 'EMS_USER')) {
    return;
  }

  if (
    user &&
    (!user.jobRoles || user.jobRoles.length === 0) &&
    user.myCompanies.length === 0 &&
    !('isMagicLinkUser' in user) &&
    user.type !== 'EMS_USER' &&
    'isPass' in user // If password is not there, do not redirect ID-1901
  ) {
    return;
  }

  if (isLoginWithGithub) {
    storage.deleteDataInLocalStorage(githubMethod.loginWithGithub);
    return routeGenerator.users.bestProfile({ user });
  }

  if (!('isPass' in user) && user.myCompanies.length === 0) {
    return;
  }

  const firstCompanyPrettyId =
    user.myCompanies &&
    user.myCompanies.length > 0 &&
    user.myCompanies[0] &&
    user.myCompanies[0].prettyId;

  if (!user.isPass) {
    return routeGenerator.companies.onBoard({ prettyId: firstCompanyPrettyId });
  }

  if (user.myCompanies.length > 0) {
    // ID-1969, enforce existing and new SME admins to set password before continuing managing the candidates.
    return routeGenerator.companies.edit({ prettyId: firstCompanyPrettyId });
  }

  return routeGenerator.users.bestProfile({ user }) || routeTemplates.thankYou;
}

const afterChangePasswordPath = afterLoginPath;

// const jobRoleNoRedirectWhitelist = [
//   routeTemplates.users.delete,
//   routeTemplates.magicLinks.assessmentMagicLink,
//   routeTemplates.companies.edit,
//   routeTemplates.admin.companies.accept,
// ].map(t => createMatchSelector({ path: t, exact: true }));

// function belongsToJobRoleNoRedirectWhitelist(state) {
//   for (let i = 0; i < jobRoleNoRedirectWhitelist.length; i++) {
//     const match = jobRoleNoRedirectWhitelist[i](state);
//     if (match) return match;
//   }
//   return false;
// }

export function* loginSuccess({
  token,
  redirect = true,
  from,
  showFlash = false,
  currentPrettyId = '',
  status = false,
  companyAdminEmail = '',
}) {
  const unwrappedToken = unwrapToken(token);

  const expiry = unwrappedToken && unwrappedToken.token && unwrappedToken.token.exp;
  const expired = expiry ? new Date(expiry * 1000) < new Date() : null;
  if (!expiry || expired) {
    yield call(invalidateSession, {
      payload: { messageContent: messages.reauthenticationRequired },
    });
    return;
  }

  const githubCTA = window.localStorage.getItem(githubMethod.githubCallToAction);
  let afterSignupRedirectTo = storage.getDataInLocalStorage(afterSignupRedirectToStorageKey);
  if (typeof afterSignupRedirectTo === 'string' && afterSignupRedirectTo) {
    afterSignupRedirectTo = JSON.parse(afterSignupRedirectTo);
    if (afterSignupRedirectTo && !from) {
      from = afterSignupRedirectTo;
      storage.deleteDataInLocalStorage(afterSignupRedirectToStorageKey);
    }
  }

  // Makes sure user's data is present in the redux store
  yield put(actions.login.success(unwrappedToken));

  if (window.amplitude && unwrappedToken && unwrappedToken.user && unwrappedToken.user.email) {
    const ampInstance = window.amplitude.getInstance();
    if (ampInstance) ampInstance.setUserId(unwrappedToken.user.email);
  }

  // Decide if signUp wizard to trigger
  yield call(fetchCurrentUser);
  let currentUser = yield select(selectors.selectCurrentUser);

  const currentUserLoaded = yield select(selectors.selectCurrentUserFetchedAtleastOnce);

  if (!currentUser || !currentUserLoaded) return;

  if (currentUser && currentUser.isDeleted) {
    yield call(invalidateSession, {
      payload: { messageContent: messages.accountDeleted },
    });
    return;
  }
  // Make following str unique
  // Checking from - /metamorph & /metamorph-indorse
  // Feature - social login + company creation
  if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_LOGGED_IN') {
    yield put(
      push(routeTemplates.emsLandingPage + `?already_exists=true&admin_email=${companyAdminEmail}`)
    );
    return;
  }

  // Fixed for both string & object.
  // Try to keep `from` as a string if you are redirecting from `/metamorph**`
  if (
    from &&
    ((typeof from === 'string' && from.includes('metamorph')) ||
      (typeof from === 'object' && from.pathname.includes('metamorph')))
  ) {
    yield put(push(routeTemplates.metamorphLogin));
    return;
  }

  const firstCompanyPrettyId =
    currentUser.myCompanies &&
    currentUser.myCompanies.length > 0 &&
    currentUser.myCompanies[0] &&
    currentUser.myCompanies[0].prettyId;

  if (window.amplitude && currentUser && currentUser.email) {
    const ampInstance = window.amplitude.getInstance();
    if (ampInstance) ampInstance.setUserId(currentUser.email);
  }

  // const state = yield select(s => s);

  // ID-1313 if user just verify their email or claim, redirect user to validate page
  const isEmailVerify = yield select(smartRouteSelectors.selectIsEmailVerify); // This is only for Github auth, wromg name
  const isClaimVerify = yield select(smartRouteSelectors.selectIsClaimVerify);
  if (isEmailVerify) {
    let routeAfterEmailVerify = from;
    if (!routeAfterEmailVerify)
      routeAfterEmailVerify = yield select(smartRouteSelectors.selectRouteAfterEmailVerify);
    yield put(push(routeAfterEmailVerify));
    yield put(smartRouteActions.setToDefault('isEmailVerify'));
  } else if (isClaimVerify) {
    const routeAfterClaimVerify = yield select(smartRouteSelectors.selectRouteAfterClaimVerify);
    yield put(push(routeAfterClaimVerify));
    yield put(smartRouteActions.setToDefault('isClaimVerify'));
  } else if (githubCTA) {
    // Decide to redirect to claim new page only for github CTA button
    yield put(push(routeTemplates.claims.new));
    window.localStorage.removeItem(githubMethod.githubCallToAction);
  } else if (firstCompanyPrettyId && redirect) {
    if (currentPrettyId) {
      yield put(
        push(
          routeGenerator.metamorph.edit({
            prettyId: currentPrettyId,
            section: 'dashboard',
          })
        )
      );
    } else {
      yield put(
        push(
          routeGenerator.metamorph.edit({
            prettyId: firstCompanyPrettyId,
            section: 'dashboard',
          })
        )
      );
    }
  } else if (redirect) {
    const redirectTo = from || afterLoginPath(currentUser, {});
    yield put(
      push(redirectTo, {
        flash: showFlash
          ? buildMessage({
              id: messageIds.auth.status,
              kind: messageTypes.success,
              content: messages.loggedIn,
            })
          : null,
      })
    );

    if (!showFlash) {
      yield put(flashActions.removeMessage(messageIds.auth.status));
    }
  }
  yield put(actions.markCurrentUserInitialized());
}

function* watchLoginSuccess() {
  yield takeLatest(types.LOGIN_SUCCESS_REQUEST, loginSuccess);
}

export function* signUp({ payload: values, meta }) {
  const form = meta && meta.form;
  const from = meta && meta.from;
  // console.log('signUp', { from, values }, Date.now());
  /*
    ID-1711 - Add Recaptcha to prevent bots. Send user details to backend to detect malicious attacks
  */

  let recaptchaToken = null;
  if (process.env.NODE_ENV !== 'development') {
    const recaptchaStatus = yield select(libSelectors.selectRecaptchaSdkLibLoadState);
    if (recaptchaStatus && recaptchaStatus.loaded && window.grecaptcha) {
      recaptchaToken = yield call(
        window.grecaptcha.execute,
        process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
        { action: 'signup' }
      );
    }
  }

  yield put(actions.signUp.start(values));
  if (form) yield put(startSubmit(form));

  try {
    let apiConfig = null;
    if (values.facebook) apiConfig = api.facebookSignUp(values);
    else if (values.google) apiConfig = api.googleSignUp(values);
    else if (values.linkedIn) apiConfig = api.linkedInSignUp(values);
    else apiConfig = api.signUp(values);

    if (process.env.NODE_ENV !== 'development') {
      apiConfig.headers = {
        'x-recaptcha-token': recaptchaToken,
      };
    }

    // ID-1313 for social sign up, redirect user to validate page after successful sign up
    if (values.facebook || values.google || values.linkedIn) {
      yield put(
        smartRouteActions.setSmartRoute({
          isEmailVerify: true,
        })
      );
    }
    // console.log('still in signup');

    const response = yield call(callApi, apiConfig);
    yield put(actions.signUp.success(response));
    if (form) yield put(stopSubmit(form));

    if (values.facebook) {
      yield call(loginWithFacebookToken, response, values.facebook, from);
    } else if (values.google) {
      // console.log('sining up with google - still in signup');
      yield call(loginWithGoogleToken, response, values.google, from);
    } else if (values.linkedIn) {
      yield call(loginWithLinkedInCode, response, values.linkedInAuth, from);
    } else if (response.token) {
      storage.setToken(response.token);
      yield call(loginSuccess, { token: response.token, from });
    } else if (values.tokenFromClaimVerify) {
      yield put(push(routeTemplates.auth.login, { successWithoutValidation: true }));
    } else {
      if (from) {
        storage.setDataInLocalStorage(afterSignupRedirectToStorageKey, JSON.stringify(from));
      }
      yield put(push(routeTemplates.auth.verificationEmailSent));
    }
  } catch (error) {
    yield put(actions.signUp.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchSignUp() {
  yield takeEvery(types.SIGN_UP.REQUEST, signUp);
}

function* signUpWizardFinished({ payload, meta = {} }) {
  const { afterCompleteRoute } = meta;
  const currentUser = yield select(selectors.selectCurrentUser);
  yield put(push(afterCompleteRoute || afterLoginPath(currentUser, {})));
}

function* watchSignUpWizardFinished() {
  yield takeEvery(
    action =>
      action.type === WIZARD_FINISHED &&
      action.payload &&
      action.payload.name === wizardNames.signUp,
    signUpWizardFinished
  );
}

function* verifyEmail({ payload: values }) {
  yield put(actions.verifyEmail.start(values));

  try {
    const response = yield call(callApi, api.verifyEmail(values));
    yield put(actions.verifyEmail.success(response));
    yield put(
      push(routeTemplates.auth.login, {
        flash: buildMessage({
          id: messageIds.auth.emailVerfied,
          kind: messageTypes.success,
          content: messages.emailVerified,
        }),
      })
    );

    yield put(
      smartRouteActions.setSmartRoute({
        isEmailVerify: true,
      })
    );
  } catch (error) {
    yield put(actions.verifyEmail.failure(error));
  }
}

function* watchVerifyEmail() {
  yield takeEvery(types.VERIFY_EMAIL.REQUEST, verifyEmail);
}

function* resendVerificationEmail({ payload: values, meta }) {
  const form = meta && meta.form;
  yield put(actions.resendVerificationEmail.start(values));
  if (form) yield put(startSubmit(form));

  try {
    /*
      ID-1711 - Add Recaptcha to prevent bots. Send user details to backend to detect malicious attacks
    */

    const recaptchaStatus = yield select(libSelectors.selectRecaptchaSdkLibLoadState);
    let recaptchaToken = null;
    if (recaptchaStatus && recaptchaStatus.loaded && window.grecaptcha) {
      recaptchaToken = yield call(
        window.grecaptcha.execute,
        process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
        { action: 'resendverificationemail' }
      );
    }

    const apiConfig = api.resendVerificationEmail(values);
    apiConfig.headers = {
      'x-recaptcha-token': recaptchaToken,
    };

    const response = yield call(callApi, apiConfig);
    yield put(actions.resendVerificationEmail.success(response));
    if (form) yield put(stopSubmit(form));
    yield put(push(routeTemplates.auth.verificationEmailSent));
  } catch (error) {
    yield put(actions.resendVerificationEmail.failure(error));
    if (form) yield put(stopSubmit(form, {}));
  } finally {
    yield put(
      flashActions.addMessage({
        id: messageIds.auth.status,
        kind: 'success',
        content: messages.resendVerificationEmail,
      })
    );
  }
}

function* watchResendVerificationEmail() {
  yield takeEvery(types.RESEND_VERIFICATION_EMAIL.REQUEST, resendVerificationEmail);
}

function* login({ payload: values, meta }) {
  const { form, from } = meta || {};
  yield put(actions.login.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.login(values));
    const { token } = response;
    storage.setToken(token);
    if (form) yield put(stopSubmit(form));
    yield call(loginSuccess, { token, from });
  } catch (error) {
    storage.deleteToken();
    yield put(actions.login.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchLogin() {
  yield takeEvery(types.LOGIN.REQUEST, login);
}

function* logout() {
  yield put(actions.logout.start());

  try {
    yield call(callApi, api.logout());
    yield put(actions.logout.success());
    yield put(push(routeTemplates.root));
  } catch (error) {
    yield put(actions.logout.failure(error));
  } finally {
    storage.deleteToken();
    storage.deleteDataInLocalStorage(githubMethod.authenticationWithGithub);
    storage.deleteDataInLocalStorage(githubMethod.linkingWithGithub);
    storage.deleteDataInLocalStorage(githubMethod.githubCallToAction);
    storage.deleteDataInLocalStorage('from');
    storage.deleteDataInLocalStorage('claimToken');
  }
}

function* watchLogout() {
  yield takeEvery(types.LOGOUT.REQUEST, logout);
}

function* changePassword({ payload: values, meta }) {
  const form = meta && meta.form;
  yield put(actions.changePassword.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.changePassword(values));
    yield put(actions.changePassword.success(response));
    if (form) yield put(stopSubmit(form));
    const user = yield select(selectors.selectCurrentUser);

    if (user) {
      yield put(actions.logout.success());
    }
    // yield put(
    //   push(afterChangePasswordPath(user, { justSetPassword: true }), {
    //     flash: buildMessage({
    //       id: messageIds.auth.passwordStatus,
    //       kind: messageTypes.success,
    //       content: messages.passwordChanged,
    //     }),
    //   })
    // );
  } catch (error) {
    yield put(actions.changePassword.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchChangePassword() {
  yield takeEvery(types.CHANGE_PASSWORD.REQUEST, changePassword);
}

function* setPassword({ payload }) {
  const { values, form, redirect, redirect_url, is_logout_all_devices } = payload;
  yield put(actions.setPassword.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.setPassword(values, is_logout_all_devices));
    yield put(actions.setPassword.success(response));
    if (form) yield put(stopSubmit(form));
    const user = yield select(selectors.selectCurrentUser);
    yield put(
      push(afterChangePasswordPath(user, { justSetPassword: true }), {
        flash: buildMessage({
          id: messageIds.auth.passwordStatus,
          kind: messageTypes.success,
          content: messages.passwordSet,
        }),
      })
    );
    console.log({ redirect, redirect_url });
    if (redirect) {
      if (redirect_url) {
        yield put(push(redirect_url));
      } else {
        yield put(push(routeTemplates.claims.root));
      }
    }
  } catch (error) {
    yield put(actions.setPassword.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchSetPassword() {
  yield takeEvery(types.SET_PASSWORD.REQUEST, setPassword);
}

function* forgotPassword({ payload: values, meta }) {
  const form = meta && meta.form;
  yield put(actions.forgotPassword.start(values));
  if (form) yield put(startSubmit(form));

  try {
    /*
      ID-1711 - Add Recaptcha to prevent bots. Send user details to backend to detect malicious attacks
    */

    const recaptchaStatus = yield select(libSelectors.selectRecaptchaSdkLibLoadState);
    let recaptchaToken = null;
    if (recaptchaStatus && recaptchaStatus.loaded && window.grecaptcha) {
      recaptchaToken = yield call(
        window.grecaptcha.execute,
        process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
        { action: 'forgotpassword' }
      );
    }

    const apiConfig = api.forgotPassword(values);
    apiConfig.headers = {
      'x-recaptcha-token': recaptchaToken,
    };

    const response = yield call(callApi, apiConfig);
    yield put(actions.forgotPassword.success(response));
    if (form) yield put(stopSubmit(form));
  } catch (error) {
    yield put(actions.forgotPassword.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchForgotPassword() {
  yield takeEvery(types.FORGOT_PASSWORD.REQUEST, forgotPassword);
}

function* resetPassword({ payload: values, meta }) {
  const form = meta && meta.form;
  yield put(actions.resetPassword.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.resetPassword(values));
    yield put(actions.resetPassword.success(response));
    if (form) yield put(stopSubmit(form));
    yield put(
      push(routeTemplates.auth.login, {
        flash: buildMessage({
          id: messageIds.auth.passwordStatus,
          kind: messageTypes.success,
          content: messages.passwordResetSuccess,
        }),
      })
    );
  } catch (error) {
    yield put(actions.resetPassword.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchResetPassword() {
  yield takeEvery(types.RESET_PASSWORD.REQUEST, resetPassword);
}

// This should executed only once per page load or refresh
function* tryRestoreSession() {
  try {
    const token = yield call(storage.getToken);
    if (token) {
      yield call(loginSuccess, { token, redirect: false, flash: false });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  } finally {
    if (window.amplitude) {
      const { origin, pathname } = window.location;
      const ampInstance = window.amplitude.getInstance();
      if (ampInstance) {
        ampInstance.logEvent('user_landed', { origin, pathname });
      }
    }
  }
}

function* invalidateSession({ payload }) {
  yield put(actions.logout.success());
  storage.deleteToken();
  yield put(push(routeTemplates.app.linkNotFound));
}

function* watchInvalidateSession() {
  yield takeEvery(types.INVALIDATE_SESSION, invalidateSession);
}

function* unauthorized() {
  yield put(
    flashActions.addMessage({
      id: messageIds.auth.status,
      kind: 'danger',
      content: messages.unauthorized,
    })
  );
}

function* watchUnauthorized() {
  yield takeEvery(types.UNAUTHORIZED, unauthorized);
}

function* watchStorage() {
  const chan = yield call(storage.createTokenChangeChannel);

  while (true) {
    try {
      const { oldValue, newValue } = yield take(chan);
      if (oldValue && !newValue) {
        yield put(actions.logout.success());
        if (window.amplitude) {
          const ampInstance = window.amplitude.getInstance();
          if (ampInstance) {
            ampInstance.setUserId(null);
            ampInstance.regenerateDeviceId();
          }
        }
        yield put(
          push(routeTemplates.auth.login, {
            flash: buildMessage({
              id: messageIds.auth.status,
              kind: 'danger',
              content: messages.authenticationRequired,
            }),
          })
        );
      } else if (oldValue !== newValue && !!newValue) {
        yield call(loginSuccess, { token: newValue });
      }
    } catch (e) {
      console.log('Error while handling token change', e);
    }
  }
}

function* updateCurrentUser({ payload: values, meta }) {
  const { form, modal } = meta;

  const { img, ...otherValues } = values;
  const finalValues = otherValues;
  if (img && isFile(img)) {
    const imgData = yield call(convertToDataURL, img);
    finalValues.imgData = imgData;
  }

  yield put(actions.updateCurrentUser.start(finalValues));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.updateCurrentUser({ ...finalValues }));
    yield put(actions.updateCurrentUser.success(response));
    if (form) yield put(stopSubmit(form));
    if (modal) {
      yield put(hideModal(modal));
    } else {
      yield put(
        push(routeTemplates.users.myProfile, {
          flash: buildMessage({
            id: messageIds.auth.profileUpdated,
            kind: messageTypes.success,
            content: response.message,
          }),
        })
      );
    }
  } catch (error) {
    yield put(actions.updateCurrentUser.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchUpdateCurrentUser() {
  yield takeEvery(types.UPDATE_CURRENT_USER.REQUEST, updateCurrentUser);
}

function* checkFacebookLibLoadState() {
  let facebookLibLoadState = yield select(libSelectors.selectFacebookSdkLibLoadState);
  if (
    facebookLibLoadState.loading ||
    (!facebookLibLoadState.loaded && !facebookLibLoadState.error)
  ) {
    const { success } = yield race({
      success: take(libActionTypes.LOAD_FACEBOOK_SDK_LIB.SUCCESS),
      failure: take(libActionTypes.LOAD_FACEBOOK_SDK_LIB.FAILURE),
    });
    if (!success) {
      return false;
    }
  } else if (facebookLibLoadState.error) {
    return false;
  }

  return true;
}

function facebookSDKLogin(options) {
  return new Promise((resolve, reject) => {
    window.FB.login(function(response) {
      if (response.authResponse) {
        window.FB.api('/me', { locale: 'en_US', fields: 'name, email' }, function(meResponse) {
          resolve({ auth: response.authResponse, user: meResponse });
        });
      } else {
        reject(response);
      }
    }, options);
  });
}

function* loginWithFacebookToken(signUpApiResponse, authData, from) {
  let token = signUpApiResponse ? signUpApiResponse.token : null;
  if (!token) {
    const authApiResponse = yield call(callApi, api.facebookAuth({ ...authData }));
    token = authApiResponse ? authApiResponse.token : null;
  }
  storage.setToken(token);
  // logUserProperties({ signup_source: 'facebook' });
  // logEvent('LP_getstartedmodal_facebook_success', { source: from });
  yield put(actions.facebookAuth.success());
  yield call(loginSuccess, { token, from });
}

function* facebookAuth({ meta = {} } = {}) {
  const { existingValues, from } = meta;
  yield put(actions.facebookAuth.start());

  try {
    const facebookResponse = yield call(facebookSDKLogin, {
      scope: 'public_profile,email',
      auth_type: 'rerequest',
    });

    try {
      yield call(loginWithFacebookToken, null, facebookResponse.auth, from);
    } catch (apiError) {
      if (apiError && apiError.response) {
        if (apiError.response.status === 404 || apiError.response.status === 422) {
          const { username } = existingValues || {};
          const { name, email } = facebookResponse.user;
          logUserProperties({ name, email, signup_source: 'facebook' });
          logEvent('LP_getstartedmodal_facebook_success', { source: from });

          if (username && name) {
            yield put(
              actions.signUp.request(
                { username, name, facebook: facebookResponse.auth },
                null,
                from
              )
            );
          } else {
            yield put(
              push(routeTemplates.auth.signUp, {
                provider: 'facebook',
                fieldValues: { ...existingValues, name },
                providerValues: facebookResponse.auth,
                from,
                email,
                flash: buildMessage({
                  id: messageIds.auth.status,
                  kind: 'info',
                  content: messages.externalAuthSignUpAdditionalFieldsRequired,
                }),
              })
            );
          }
        } else {
          throw apiError;
        }
      } else {
        throw apiError;
      }
    }
  } catch (error) {
    yield put(actions.facebookAuth.failure(error));
    if (error)
      yield put(
        flashActions.addMessage({
          id: messageIds.auth.status,
          kind: 'danger',
          content: error.message || messages.facebookAuthError,
        })
      );
  }
}

function* watchFacebookAuth() {
  const facebookLibLoaded = yield call(checkFacebookLibLoadState);
  if (facebookLibLoaded) yield takeEvery(types.FACEBOOK_AUTH.REQUEST, facebookAuth);
}

function* linkFacebook() {
  yield put(actions.linkFacebook.start());

  try {
    const facebookResponse = yield call(facebookSDKLogin, {
      scope: 'public_profile,email',
      auth_type: 'rerequest',
    });

    yield call(callApi, api.linkFacebook(facebookResponse.auth));
    yield put(actions.linkFacebook.success());
  } catch (error) {
    yield put(actions.linkFacebook.failure(error));
    if (error)
      yield put(
        flashActions.addMessage({
          id: messageIds.auth.status,
          kind: 'danger',
          content: error.message || messages.facebookAuthError,
        })
      );
  }
}

function* watchLinkFacebook() {
  const facebookLibLoaded = yield call(checkFacebookLibLoadState);
  if (facebookLibLoaded) yield takeEvery(types.LINK_FACEBOOK.REQUEST, linkFacebook);
}

function* checkGoogleLibLoadState() {
  let googleApiLibLoadState = yield select(libSelectors.selectGoogleApiLibLoadState);
  if (
    googleApiLibLoadState.loading ||
    (!googleApiLibLoadState.loaded && !googleApiLibLoadState.error)
  ) {
    const { success } = yield race({
      success: take(libActionTypes.LOAD_GOOGLE_API_LIB.SUCCESS),
      failure: take(libActionTypes.LOAD_GOOGLE_API_LIB.FAILURE),
    });
    if (!success) {
      return false;
    }
  } else if (googleApiLibLoadState.error) {
    return false;
  }

  let googleAuthLibLoadState = yield select(libSelectors.selectGoogleAuthLibLoadState);
  if (
    googleAuthLibLoadState.loading ||
    (!googleAuthLibLoadState.loaded && !googleAuthLibLoadState.error)
  ) {
    const { success } = yield race({
      success: take(libActionTypes.LOAD_GOOGLE_AUTH_LIB.SUCCESS),
      failure: take(libActionTypes.LOAD_GOOGLE_AUTH_LIB.FAILURE),
    });
    if (!success) {
      return false;
    }
  } else if (googleAuthLibLoadState.error) {
    return false;
  }

  return true;
}

function* loginWithGoogleToken(signUpApiResponse, authData, from) {
  // console.log('loginWithGoogleToken', { from, signUpApiResponse, authData });
  let token = signUpApiResponse ? signUpApiResponse.token : null;
  if (!token) {
    const authApiResponse = yield call(callApi, api.googleAuth(authData));
    token = authApiResponse ? authApiResponse.token : null;
  }
  storage.setToken(token);

  // logEvent('LP_getstartedmodal_google_success', { source: from });
  yield put(actions.googleAuth.success({ authData }));
  yield call(loginSuccess, { token, from });
}

function* googleAuth(auth2, { meta = {} } = {}) {
  const { existingValues, from } = meta;
  const { tokenFromClaimVerify } = existingValues || {};
  yield put(actions.googleAuth.start());

  try {
    const user = yield call([auth2, auth2.signIn]);
    const authData = yield call([user, user.getAuthResponse], true);
    const profile = yield call([user, user.getBasicProfile]);
    const deserializedProfile = {
      id: profile.getId(),
      name: profile.getName(),
      email: profile.getEmail(),
      image: profile.getImageUrl(),
    };

    try {
      // console.log('trying to log in - google');
      yield call(loginWithGoogleToken, null, authData, from);
    } catch (apiError) {
      // console.log('trying to signup - google');
      if (apiError && apiError.response) {
        if (apiError.response.status === 404 || apiError.response.status === 422) {
          const { username } = existingValues || {};
          const { name, email } = deserializedProfile;
          // catch error response of 404 or 422
          logUserProperties({ name, email, signup_source: 'google' });
          logEvent('LP_getstartedmodal_google_success', { source: from });
          if (username && name) {
            yield put(
              actions.signUp.request(
                { username, name, google: authData, tokenFromClaimVerify },
                null,
                from
              )
            );
          } else {
            // no username && name push to signup page with, data
            yield put(
              push(routeTemplates.auth.signUp, {
                provider: 'google',
                fieldValues: { ...existingValues, name },
                providerValues: authData,
                tokenFromClaimVerify,
                from,
                email,
                flash: buildMessage({
                  id: messageIds.auth.status,
                  kind: 'info',
                  content: messages.externalAuthSignUpAdditionalFieldsRequired,
                }),
              })
            );
          }
        } else {
          throw apiError;
        }
      } else {
        throw apiError;
      }
    }
  } catch (error) {
    const { error: errorCode, message } = error || {};
    yield put(actions.googleAuth.failure({ errorCode, message }));
    if (errorCode !== 'popup_closed_by_user' || message)
      yield put(
        flashActions.addMessage({
          id: messageIds.auth.status,
          kind: 'danger',
          content: message || messages.googleAuthError,
        })
      );
  }
}

function* watchGoogleAuth() {
  const googleLibLoaded = yield call(checkGoogleLibLoadState);

  if (googleLibLoaded) {
    const auth2 = yield call(window.gapi.auth2.getAuthInstance);
    yield takeEvery(types.GOOGLE_AUTH.REQUEST, googleAuth, auth2);
  }
}

function* linkGoogle(auth2) {
  yield put(actions.linkGoogle.start());

  try {
    const user = yield call([auth2, auth2.signIn]);
    const authData = yield call([user, user.getAuthResponse], true);

    yield call(callApi, api.linkGoogle(authData));
    yield put(actions.linkGoogle.success({ authData }));
  } catch (error) {
    const { error: errorCode, message } = error || {};
    yield put(actions.linkGoogle.failure({ errorCode, message }));
    if (errorCode !== 'popup_closed_by_user' || message)
      yield put(
        flashActions.addMessage({
          id: messageIds.auth.status,
          kind: 'danger',
          content: message || messages.googleAuthError,
        })
      );
  }
}

function* watchLinkGoogle() {
  const googleLibLoaded = yield call(checkGoogleLibLoadState);

  if (googleLibLoaded) {
    const auth2 = yield call(window.gapi.auth2.getAuthInstance);
    yield takeEvery(types.LINK_GOOGLE.REQUEST, linkGoogle, auth2);
  }
}

export function linkedInLogin() {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams();
    urlParams.set('response_type', 'code');
    urlParams.set('client_id', process.env.REACT_APP_LINKED_IN_APP_CLIENT_ID);
    const redirectUri =
      process.env.REACT_APP_LINKED_IN_APP_REDIRECT_URI ||
      `${window.location.origin}/linked-in-callback.html`;
    urlParams.set('redirect_uri', redirectUri);
    const requestState = shortid.generate();
    urlParams.set('state', requestState);
    const url = `https://www.linkedin.com/oauth/v2/authorization?${urlParams}`;
    PromiseWindow.open(url).then(
      data => {
        const result = new URLSearchParams(data.result);
        const code = result.get('code');
        const responseState = result.get('state');

        if (requestState !== responseState) reject({ errorCode: 'invalid_state' });

        resolve({ code, state: responseState, redirectUri });
      },
      error => {
        if (error === 'closed') reject({ errorCode: error });
        else {
          const parsedError = new URLSearchParams(error);
          const errorCode = parsedError.get('error');
          const message = parsedError.get('error_description');
          reject({ errorCode, message });
        }
      }
    );
  });
}

function* loginWithLinkedInCode(signUpApiResponse, existingValues, authData, from) {
  let token = signUpApiResponse ? signUpApiResponse.token : null;
  if (!token) {
    const authApiResponse = yield call(
      callApi,
      api.linkedInAuth({ ...existingValues, linkedIn: authData })
    );
    token = authApiResponse ? authApiResponse.token : null;
  }
  storage.setToken(token);
  yield put(actions.linkedInAuth.success({ authData }));
  yield call(loginSuccess, { token, from });
}

function* linkedInAuth({ meta = {} }) {
  const { existingValues, from } = meta;
  const { tokenFromClaimVerify } = existingValues || {};
  yield put(actions.linkedInAuth.start());

  try {
    const authData = yield call(linkedInLogin);

    try {
      yield call(loginWithLinkedInCode, null, existingValues, authData, from);
    } catch (apiError) {
      if (apiError && apiError.response && apiError.response.status === 404) {
        const { username, email } = existingValues || {};
        const { name, token: signupToken } = apiError;
        if (username && name) {
          yield put(
            actions.signUp.request(
              { username, name, linkedIn: authData, tokenFromClaimVerify },
              null,
              from
            )
          );
        } else {
          yield put(
            push(routeTemplates.auth.signUp, {
              provider: 'linkedIn',
              fieldValues: { ...existingValues, name },
              providerValues: { signupToken },
              from,
              email,
              tokenFromClaimVerify,
              flash: buildMessage({
                id: messageIds.auth.status,
                kind: 'info',
                content: messages.externalAuthSignUpAdditionalFieldsRequired,
              }),
            })
          );
        }
      } else {
        throw apiError;
      }
    }
  } catch (error) {
    const { errorCode, message } = error;
    yield put(actions.linkedInAuth.failure({ errorCode, message }));
    if (errorCode && message)
      yield put(
        flashActions.addMessage({
          id: messageIds.auth.status,
          kind: 'danger',
          content: message || messages.linkedInAuthError,
        })
      );
  }
}

function* watchLinkedInAuth() {
  yield takeEvery(types.LINKED_IN_AUTH.REQUEST, linkedInAuth);
}

function* linkLinkedIn() {
  yield put(actions.linkLinkedIn.start());

  try {
    const authData = yield call(linkedInLogin);

    yield call(callApi, api.linkLinkedIn({ linkedIn: authData }));
    yield put(actions.linkLinkedIn.success());
  } catch (error) {
    const { error: errorCode, message } = error || {};
    yield put(actions.linkLinkedIn.failure({ errorCode, message }));
    if (message)
      yield put(
        flashActions.addMessage({
          id: messageIds.auth.status,
          kind: 'danger',
          content: message || messages.linkedInAuthError,
        })
      );
  }
}

function* watchLinkLinkedIn() {
  yield takeEvery(types.LINK_LINKED_IN.REQUEST, linkLinkedIn);
}

const pollIntervalInSeconds = parseInt(process.env.REACT_APP_POLL_INTERVAL_IN_SEC || 1, 10) || 1;

function* uPortAuth() {
  yield put(actions.uPortAuth.start());

  try {
    const { qr, token } = yield call(callApi, api.uPortAuth());
    yield put(actions.uPortAuth.success({ qr, token }));
    yield put(actions.pollUPort.start());

    while (true) {
      const { status, reason, jwt } = yield call(callApi, api.pollUPort({ token }));
      if (jwt) {
        if (status === 'SUCCESS') {
          yield put(actions.pollUPort.success({ status }));
          storage.setToken(jwt);
          yield call(loginSuccess, { token: jwt });
          return;
        }

        if (status === 'FAILURE') {
          yield put(actions.pollUPort.failure(reason));
          yield put(
            flashActions.addMessage({
              id: messageIds.auth.status,
              kind: 'danger',
              content: 'Error linking uPort!',
              disableTimeout: true,
            })
          );
          return;
        }
      }
      yield delay(pollIntervalInSeconds * 1000);
    }
  } catch (apiError) {
    if (apiError && apiError.response) {
      if (apiError.response.status === 404 || apiError.response.status === 422) {
        yield put(
          push(routeTemplates.auth.loginWithUport, {
            flash: buildMessage({
              id: messageIds.auth.status,
              kind: 'info',
              content: messages.uPortAuthError,
            }),
          })
        );
      } else {
        yield put(actions.uPortAuth.failure(apiError));
        if (apiError) {
          yield put(
            flashActions.addMessage({
              id: messageIds.auth.status,
              kind: 'danger',
              content: apiError.message || 'API error',
            })
          );
        }
      }
    } else {
      yield put(actions.uPortAuth.failure(apiError));
      if (apiError) {
        yield put(
          flashActions.addMessage({
            id: messageIds.auth.status,
            kind: 'danger',
            content: apiError.message || 'API error',
          })
        );
      }
    }
  }
}

function* watchUPortAuth() {
  yield takeLatest(types.UPORT_AUTH.REQUEST, uPortAuth);
}

function* linkUPort({ payload = {} }) {
  yield put(actions.linkUPort.start());

  try {
    const { qr, token } = yield call(callApi, api.linkUPort());
    yield put(actions.linkUPort.success({ qr, token }));
    yield put(actions.pollUPort.start());

    while (true) {
      const { status, reason } = yield call(callApi, api.pollUPort({ token }));
      if (status === 'SUCCESS') {
        yield put(actions.pollUPort.success({ status }));
        yield put(
          flashActions.addMessage({
            id: messageIds.auth.status,
            kind: 'info',
            content: 'uPort has been linked successfully!',
            disableTimeout: true,
          })
        );
        return;
      }

      if (status === 'FAILURE') {
        yield put(actions.pollUPort.failure(reason));
        yield put(
          flashActions.addMessage({
            id: messageIds.auth.status,
            kind: 'danger',
            content: 'Error linking uPort!',
            disableTimeout: true,
          })
        );
        return;
      }

      yield delay(pollIntervalInSeconds * 1000);
    }
  } catch (error) {
    yield put(actions.linkUPort.failure(error));
    if (error)
      yield put(
        flashActions.addMessage({
          id: messageIds.auth.status,
          kind: 'danger',
          content: error.message || 'Linking uPort unsuccessful',
        })
      );
  }
}

function* watchLinkUPort() {
  yield takeLatest(types.LINK_UPORT.REQUEST, linkUPort);
}

// TODO: Remove when ID-1096 is fixed
function generateUserSkillIds(user) {
  if (user && user.id && user.skills && user.skills.length && user.skills.length > 0) {
    const userId = user._id || user.id;
    if (userId) {
      user.skills.forEach((userSkill, index) => {
        if (!userSkill.id && userSkill.skill && userSkill.skill.id)
          userSkill.id = `${userId}_skills_${userSkill.skill.id}`;
      });
    }
  }
}

export function* fetchCurrentUser() {
  yield put(actions.fetchCurrentUser.start());

  try {
    const response = yield call(callApi, api.fetchCurrentUser());
    if (response && response.profile) {
      generateUserSkillIds(response.profile);
    }
    yield put(actions.fetchCurrentUser.success(response));
    const schema = { profile: schemas.user };
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
  } catch (error) {
    yield put(actions.fetchCurrentUser.failure(error));
    if (error instanceof TypeError) return; // Ignore network error
    yield call(invalidateSession, {
      payload: { messageContent: error.message || messages.unableToFindUser },
    });
  }
}

function* watchFetchCurrentUser() {
  yield takeEvery(types.FETCH_CURRENT_USER.REQUEST, fetchCurrentUser);
}

function* acceptCurrentTermsAndPrivacy({ payload: values, meta }) {
  const form = meta && meta.form;
  yield put(actions.acceptCurrentTermsAndPrivacy.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.acceptCurrentTermsAndPrivacy(values));
    yield call(fetchCurrentUser);
    yield put(actions.acceptCurrentTermsAndPrivacy.success(response));
    if (form) yield put(stopSubmit(form));
    // if (window && window.location && window.location.reload) window.location.reload();
  } catch (error) {
    yield put(actions.acceptCurrentTermsAndPrivacy.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchAcceptCurrentTermsAndVersion() {
  yield takeEvery(types.ACCEPT_CURRENT_TERMS_AND_PRIVACY.REQUEST, acceptCurrentTermsAndPrivacy);
}

function* loginAdvisor({ payload }) {
  const { token } = payload;
  if (token) {
    try {
      storage.setToken(payload.token);
      yield call(loginSuccess, { token });
    } catch (error) {
      console.log(error);
    }
  }
}

function* watchLoginAdvisor() {
  yield takeEvery(types.LOGIN_ADVISOR, loginAdvisor);
}

function* loginCompanyAdmin({ payload }) {
  const { token } = payload;
  if (token) {
    try {
      storage.setToken(payload.token);
      yield call(loginSuccess, { token });
    } catch (error) {
      console.log(error);
    }
  }
}

function* watchLoginCompanyAdmin() {
  yield takeEvery(types.LOGIN_COMPANY_ADMIN, loginCompanyAdmin);
}

function* loginThroughClaimsNew({ payload }) {
  const { token } = payload;
  if (token) {
    try {
      storage.setToken(payload.token);
      yield call(loginSuccess, { token });
    } catch (error) {
      console.log(error);
    }
  }
}

function* watchLoginThroughClaimsNew() {
  yield takeEvery(types.LOGIN_CLAIMS_FLOW, loginThroughClaimsNew);
}

function* loginForEmsEmployee({ payload }) {
  const { token } = payload;
  if (token) {
    try {
      storage.setToken(payload.token);
      yield call(loginSuccess, { token });
    } catch (error) {
      console.log(error);
    }
  }
}

function* watchLoginEmsNewEmployee() {
  yield takeEvery(types.LOGIN_EMS_EMPLOYEE, loginForEmsEmployee);
}

////////////////////////////////////////////////////////////////////////////////////

export default function* auth() {
  yield fork(watchInvalidateSession);
  yield call(tryRestoreSession);
  yield fork(watchUnauthorized);
  yield fork(watchSignUp);
  yield fork(watchVerifyEmail);
  yield fork(watchResendVerificationEmail);
  yield fork(watchLogin);
  yield fork(watchLogout);
  yield fork(watchChangePassword);
  yield fork(watchSetPassword);
  yield fork(watchForgotPassword);
  yield fork(watchResetPassword);
  yield fork(watchFetchCurrentUser);
  yield fork(watchUpdateCurrentUser);
  yield fork(watchStorage);
  yield fork(watchFacebookAuth);
  yield fork(watchLinkFacebook);
  yield fork(watchGoogleAuth);
  yield fork(watchLinkGoogle);
  yield fork(watchLinkedInAuth);
  yield fork(watchLinkLinkedIn);
  yield fork(watchUPortAuth);
  yield fork(watchLinkUPort);
  yield fork(watchAcceptCurrentTermsAndVersion);
  yield fork(watchSignUpWizardFinished);
  yield fork(watchLoginAdvisor);
  yield fork(watchLoginSuccess);
  yield fork(watchLoginCompanyAdmin);
  yield fork(watchLoginThroughClaimsNew);
  yield fork(watchLoginEmsNewEmployee);
}
