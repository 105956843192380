import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from 'ui/common/loading';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import EmsApps from 'store/graphql/EmsApps/reducer';
import { withApollo } from 'react-apollo';
import { addMessage } from '../../../store/features/common/flash/actions';
import { show as showModal } from 'redux-modal';
import { push } from 'react-router-redux';
import routeGenerators from '../../common/routes/generators';

const GitInstallSucessRedirectCustomize = props => {
  useEffect(() => {
    props.push(
      `${routeGenerators.metamorph.edit({
        prettyId: props.prettyId,
        section: 'dashboard',
      })}?customize=true`
    );
  }, []);

  return <Loading />;
};

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps && ownProps.match && ownProps.match.params;
  const companyId = routeParams && routeParams.companyId;
  const prettyId = routeParams && routeParams.prettyId;

  return {
    companyId,
    prettyId,
  };
}

const mapDispatchToProps = {
  addMessage,
  showModal,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'EmsApps',
  reducer: EmsApps,
});

export default compose(withReducer, withConnect)(withApollo(GitInstallSucessRedirectCustomize));

// http://localhost:4000/ems/github-app-callback?code=d3ca17496d490e3ae05f&installation_id=10872288&setup_action=install
