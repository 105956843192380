import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import { Collapse } from 'reactstrap';

const HILIGHT_IMGS = [
  'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-hilight-git-repo-overview.png',
  'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-highlight-git-activity.png',
  'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-highlight-commit-freqn.png',
  'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-highlight-all-about-repo.png',
];

const RenderDesktop = props => {
  const [currentOption, setCurrentOption] = useState(0);
  let _slider;
  const onSelectHighlight = id => {
    setCurrentOption(id);
    !!_slider && _slider.slickGoTo(id);
  };
  const sliderSettings = {
    centerMode: false,
    swipeToSlide: true,
    infinite: true,
    lazyLoad: true,
    arrows: false,
    dots: false, // !TODO Looking into how to position the dots the proper way
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 10000,
    afterChange: index => setCurrentOption(index),
    centerPadding: '0px',
  };

  return (
    <div className={classnames(styles.features)}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h1 className={styles.title}>What you’ll discover via Metamorph</h1>
          <h3 className={styles.subTitle}>Uncover the hidden stories of Git repositories</h3>
        </div>
        <div className="d-flex mt-5 justify-content-between align-items-center">
          <div className={classnames(styles.featureList, 'col-4 mx-0 px-0 ml-5')}>
            <div
              className={classnames(
                styles.featureBox,
                0 === currentOption ? styles.selectedBox : ''
              )}
              onClick={() => onSelectHighlight(0)}
            >
              <div className={styles.info}>
                <h3>Git Activity</h3>
                <p>
                  Why only see the activity of developers’ accounts? Discover the activity of all
                  the Git repositories you can access.
                </p>
              </div>
              <i style={{ fontSize: '1.2rem' }} className="fa fa-chevron-right ml-4 pl-3" />
            </div>
            <div
              className={classnames(
                styles.featureBox,
                1 === currentOption ? styles.selectedBox : ''
              )}
              onClick={() => onSelectHighlight(1)}
            >
              <div className={styles.info}>
                <h3>Commit Frequency</h3>
                <p>
                  Is this repo active and the package still of use? Find out about the health of a
                  repo, in the blink of an eye.
                </p>
              </div>
              <i style={{ fontSize: '1.2rem' }} className="fa fa-chevron-right ml-4 pl-3" />
            </div>
            <div
              className={classnames(
                styles.featureBox,
                2 === currentOption ? styles.selectedBox : ''
              )}
              onClick={() => onSelectHighlight(2)}
            >
              <div className={styles.info}>
                <h3>All about PRs</h3>
                <p>
                  How are the PRs handled? Learn more about the rejected and accepted PRs,
                  automatically.
                </p>
              </div>
              <i style={{ fontSize: '1.2rem' }} className="fa fa-chevron-right ml-4 pl-3" />
            </div>
          </div>
          <div className={'col-7 px-0 mx-0'}>
            <div className="row mt-4">
              <div className="col-12 px-0 mx-0">
                <Slider ref={slider => (_slider = slider)} {...sliderSettings}>
                  <div>
                    <img src={HILIGHT_IMGS[1]} width="739px" alt="h-0" />
                  </div>
                  <div>
                    <img src={HILIGHT_IMGS[2]} width="739px" alt="h-0" />
                  </div>
                  <div>
                    <img src={HILIGHT_IMGS[3]} width="754px" alt="h-0" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderMobile = props => {
  const [currentOption, setCurrentOption] = useState(0);
  return (
    <div className={classnames(styles.features)}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h1 className={styles.title}>What you’ll discover via Metamorph</h1>
          <h3 className={styles.subTitle}>Uncover the hidden stories of Git repositories</h3>
        </div>
        <div>
          <div className={classnames(styles.featureList, 'mx-0 px-0')}>
            <div
              className={classnames(
                styles.featureBox,
                0 === currentOption ? styles.selectedBox : ''
              )}
              onClick={() => setCurrentOption(currentOption === 0 ? null : 0)}
            >
              <div className={styles.info}>
                <h3>Git Activity</h3>
                <p>
                  Why only see the activity of developers’ accounts? Discover the activity of all
                  the Git repositories you can access.
                </p>
              </div>
              <i style={{ fontSize: '1.2rem' }} className="fa fa-chevron-right ml-4" />
            </div>
            <Collapse isOpen={currentOption === 0}>
              <img src={HILIGHT_IMGS[1]} alt="h-0" className={styles.collapsableImg} />
            </Collapse>
            <div
              className={classnames(
                styles.featureBox,
                1 === currentOption ? styles.selectedBox : ''
              )}
              onClick={() => setCurrentOption(currentOption === 1 ? null : 1)}
            >
              <div className={styles.info}>
                <h3>Commit Frequency</h3>
                <p>
                  Is this repo active and the package still of use? Find out about the health of a
                  repo, in the blink of an eye.
                </p>
              </div>
              <i style={{ fontSize: '1.2rem' }} className="fa fa-chevron-right ml-4" />
            </div>
            <Collapse isOpen={currentOption === 1}>
              <img src={HILIGHT_IMGS[2]} alt="h-0" className={styles.collapsableImg} />
            </Collapse>
            <div
              className={classnames(
                styles.featureBox,
                2 === currentOption ? styles.selectedBox : ''
              )}
              onClick={() => setCurrentOption(currentOption === 2 ? null : 2)}
            >
              <div className={styles.info}>
                <h3>All about PRs</h3>
                <p>
                  How are the PRs handled? Learn more about the rejected and accepted PRs,
                  automatically.
                </p>
              </div>
              <i style={{ fontSize: '1.2rem' }} className="fa fa-chevron-right ml-4" />
            </div>
            <Collapse isOpen={currentOption === 2}>
              <img src={HILIGHT_IMGS[3]} alt="h-0" className={styles.collapsableImg} />
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

const featuresSection = props => {
  return (
    <div className={classnames(styles.section)}>
      <MediaQuery minWidth={1281}>
        <RenderDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={1280}>
        <RenderMobile />
      </MediaQuery>
    </div>
  );
};

export default featuresSection;
