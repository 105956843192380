import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import classnames from 'classnames';
import styles from './index.module.scss';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';

export class Menu extends Component {
  static propTypes = {
    burger: PropTypes.bool,
    menuOpen: PropTypes.bool,
    subMenuOpen: PropTypes.bool,
    stopToggle: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      menuOpen: props.menuOpen || false,
      subMenuOpen: props.subMenuOpen || false,
    };
  }

  toggle() {
    const { stopToggle } = this.props;
    if (!stopToggle) {
      this.setState({
        menuOpen: !this.state.menuOpen,
        subMenuOpen: this.state.menuOpen,
      });
    }
  }

  toggleSubMenu() {
    const { stopToggle } = this.props;
    if (!stopToggle) {
      this.setState({
        subMenuOpen: !this.state.subMenuOpen,
      });
    }
  }

  render() {
    const { burger, text, children } = this.props;
    const { menuOpen, subMenuOpen } = this.state;

    const subMenuItems = React.Children.map(children, child => {
      return (
        <div
          className={classnames({
            'mt-2': true,
            [styles.subMenu]: child && child.props && child.props['data-subMenu'],
            [styles.showSubMenu]: menuOpen && subMenuOpen,
          })}
        >
          {child && child.props && child.props['data-subMenu']
            ? React.cloneElement(child, { onClick: this.toggleSubMenu })
            : child}
        </div>
      );
    });

    return (
      <Dropdown
        className={classnames(styles.menu)}
        isOpen={this.state.menuOpen}
        toggle={this.toggle}
      >
        {burger ? (
          <DropdownToggle className="my-auto mr-0 ml-3" onClick={this.toggle}>
            <div className={classnames(styles.menuBurger, this.state.menuOpen && styles.transform)}>
              <span />
              <span />
            </div>
          </DropdownToggle>
        ) : (
          <DropdownToggle className="my-auto" onClick={this.toggle}>
            {text}
          </DropdownToggle>
        )}

        <DropdownMenu
          className={classnames(styles.dropdownMenu, this.state.menuOpen && styles.showing)}
        >
          <div className="px-0 text-center">
            <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
              <div className="py-2">{children}</div>
            </MediaQuery>
            <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
              <div className={classnames('py-4', styles.subMenu)}>{subMenuItems}</div>
            </MediaQuery>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default Menu;
