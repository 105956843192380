import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import themeConfig from 'ui/theme/config';
import { hide as hideModal, show as showModal } from 'redux-modal';
import styles from './index.module.scss';
// import Button from "ui/common/button";
// import AnimatedBackground from 'ui/common/animated-background/desktop-version';
// import TypedText from 'ui/common/typed-text';

import { logEvent } from 'services/amplitude-helper';
import { MODAL_NAME as EMS_CONTACT_MODAL } from 'ui/graphql/EmsContactForm';
import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';
import gql from 'graphql-tag';
import { messageTypes } from 'store/features/common/flash/builder';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';

// import AddToSlackButton from 'ui/graphql/AddToSlackButton';

import GetStartedModal, { MODAL_NAME as GET_STARTED_MODAL } from './get-started-modal';

import GetStartedModalMobile, {
  MODAL_NAME as GET_STARTED_MODAL_MOBILE,
} from './get-started-modal-mobile';

import * as messages from './messages';
import blackSquare from 'resources/common/black-square.svg';
import blackCircle from 'resources/common/black-circle.svg';
import blackTriangle from 'resources/common/black-triangle.svg';
import * as storage from '../../../../store/features/auth/storage';
import { withRouter } from 'react-router';
import routeGenerators from '../../../common/routes/generators';
import * as authActionTypes from '../../../../store/features/auth/action-types';
import ErrorModal, { MODAL_NAME as ERROR_MODAL } from './error-modal';
import ErrorModalMobile from './error-modal-mobile';
import VerifyEmailModal, { MODAL_NAME as VERIFY_EMAIL_MODAL } from './verify-email-modal';
import VerifyEmailModalMobile from './verify-email-modal-mobile';
import LoginUserModal, { MODAL_NAME as LOGIN_LOGIN_USER_MODAL } from './login-user-modal';
import LoginUserModalMobile from './login-user-modal-mobile';
import UserEmployeeModal, { MODAL_NAME as USER_EMPLOY_MODAL } from './user-employee-modal';
import UserEmployeeModalMobile from './user-employee-modal-mobile';
import LoggedInUserGetStartedModal, {
  MODAL_NAME as LOGGED_USER_GET_STARTED_MODAL,
} from './loggedin-user-get-started';
import CompanyExistsEmailSentModal, {
  MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL,
} from './ems-company-exists-for-domain-modal';
import CompanyExistsEmailSentModalLoggedIn, {
  MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN,
} from './ems-company-exists-for-domain-modal-loggedin';
import CompanyExistsEmailSentModalMobile from './ems-company-exists-for-domain-modal-mobile';
import CompanyExistsEmailSentModalLoggedInMobile from './ems-company-exists-for-domain-modal-loggedin-mobile';
import LoggedInUserGetStartedModalMobile from './loggedin-user-get-started-mobile';
// import Button from 'ui/common/button';
import { selectCurrentUser, selectIsLoggedIn } from '../../../../store/features/auth/selectors';

class Hero extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { currentUser, adminEmail, alreadyExists, showModal } = this.props;
    window.fcWidget &&
      window.fcWidget.user.setProperties({
        firstName: currentUser && currentUser.name,
        email: currentUser && currentUser.email,
      });
    if (alreadyExists && adminEmail !== 'null') {
      showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN, { email: adminEmail });
    }
  }

  showContactForm = () => {
    this.props.showModal(EMS_CONTACT_MODAL, { showForm: true });
  };

  showConfirmModalToLoggedInUser() {
    this.props.showModal(LOGGED_USER_GET_STARTED_MODAL, {
      onSubmit: () => this.createCompanyForLoggedInUser(),
    });
  }

  async createCompanyForLoggedInUser() {
    const { email } = this.props.currentUser;
    const {
      client,
      addMessage,
      intl: { formatMessage },
      loginSuccessRequest,
      showModal,
    } = this.props;

    logEvent('LP_getstartedmodal_getstarted_clicked', {
      label: formatMessage(messages.buttons.submitButton),
      source: window && window.location.pathname,
      email,
    });

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_create_company($email: EmailAddress) {
            ems_create_company(email: $email) {
              status
              error
              token
              company {
                companyId
                companyPrettyId
                createdAt
              }
              emailDomainCompanies {
                acl {
                  email
                }
              }
            }
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { data, errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        logEvent('create_ems_company_failed', {
          error: errorsToSingleMessage({ errors }),
        });
      } else {
        // const { createCompany: { id } } = data;
        // if (id) {
        //   this.setState({
        //     isCompanyCreated: true,
        //   });
        // }
        // disable exit intent
        localStorage.setItem('popup', 'false');

        if (window.amplitude) {
          const ampInstance = window.amplitude.getInstance();
          window.localStorage.setItem('email', email);
          if (ampInstance) ampInstance.setUserId(email.toLowerCase());
        }
        logEvent('create_ems_company_waitlist_clicked', {
          label: formatMessage(messages.buttons.submitButton),
          source: window && window.location.pathname,
          email: email,
        });
        this.props.hideModal(LOGGED_USER_GET_STARTED_MODAL);
        const { ems_create_company } = data;
        const error = ems_create_company && ems_create_company.error;
        if (!!error) {
          showModal(ERROR_MODAL, {});
        } else {
          const { status, token, company, emailDomainCompanies } = ems_create_company;

          if (status === 'ACCOUNT_CREATED') {
            const { companyPrettyId } = company;
            storage.setToken(token);
            loginSuccessRequest({
              token,
              redirect: true,
              from: routeGenerators.metamorph.edit({
                prettyId: companyPrettyId,
                section: 'dashboard',
              }),
            });
          } else if (status === 'ACCOUNT_UPSERTED') {
            const { companyPrettyId } = company;
            this.props.history.push(
              routeGenerators.metamorph.edit({
                prettyId: companyPrettyId,
                section: 'dashboard',
              })
            );
          } else if (
            status === 'ACCOUNT_EXISTS_UNVERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_UNVERIFIED'
          ) {
            // Change here to show popup
            showModal(VERIFY_EMAIL_MODAL, { email, onSubmit: () => null });
          } else if (
            status === 'ACCOUNT_EXISTS_VERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_VERIFIED'
          ) {
            // Change here to show popup
            showModal(LOGIN_LOGIN_USER_MODAL, { email, onSubmit: () => null });
          } else if (status === 'USER_IS_EMPLOYEE') {
            // Change here to show popup
            showModal(USER_EMPLOY_MODAL, { email, onSubmit: () => null });
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_EMAIL_SENT') {
            // Change here to show popup
            showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL, {});
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_LOGGED_IN') {
            try {
              const email =
                Array.isArray(emailDomainCompanies) && emailDomainCompanies.length > 0
                  ? emailDomainCompanies[0].acl[0].email
                  : null;
              showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN, { email });
            } catch (err) {
              showModal(ERROR_MODAL, {});
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
      logEvent('create_company_failed', {
        error: error.message,
        label: formatMessage(messages.buttons.submitButton),
      });
    }
  }

  // async addToWaitlist(values) {
  //   const { email } = values;
  //   const { client, addMessage, intl: { formatMessage } } = this.props;

  //   try {
  //     let recaptchaToken;
  //     if (process.env.NODE_ENV !== 'development') {
  //       recaptchaToken = await window.grecaptcha.execute(
  //         process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
  //         { action: 'createcompany' }
  //       );
  //     }

  //     const result = await client.mutate({
  //       mutation: gql`
  //         mutation ems_waitlist($email: String!) {
  //           ems_waitlist(email: $email)
  //         }
  //       `,
  //       variables: {
  //         email,
  //       },
  //       context: {
  //         headers: {
  //           'x-recaptcha-token': recaptchaToken,
  //         },
  //       },
  //     });
  //     const { errors } = result;

  //     if (resultHasErrors({ errors })) {
  //       addMessage({
  //         kind: messageTypes.danger,
  //         content: errorsToSingleMessage({ errors }),
  //       });
  //     } else {
  //       logEvent('create_ems_company_waitlist_clicked', {
  //         label: formatMessage(messages.buttons.submitButton),
  //         source: window && window.location.pathname,
  //         email: email,
  //       });
  //       if (window.amplitude) {
  //         const ampInstance = window.amplitude.getInstance();
  //         if (ampInstance) ampInstance.setUserId(email);
  //       }
  //       this.props.showModal(EMS_WAITLIST_MODAL);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     addMessage({
  //       kind: messageTypes.danger,
  //       content: error.message,
  //     });
  //   }
  // }

  renderDesktop() {
    const {
      // handleSubmit,
      intl: { formatMessage },
      // form: formName,
      isLoggedIn,
      showModal,
      invalid,
    } = this.props;
    return (
      <div className={classnames(styles.hero)}>
        <div className="row d-flex page-max-width pt-3">
          <div className="col-6 pb-3">
            <div className={classnames(styles.metamorphTitle)}>METAMORPH</div>
            <h1 className={classnames(styles.heroTitle, 'text-left')}>
              <span className={styles.boldText}>Actionable analytics</span> for
              <br /> engineering leaders
            </h1>

            <h3 className={styles.heroSubTitle}>{formatMessage(messages.hero.subTitle)}</h3>

            <div>
              <button
                className={classnames(styles.getStartedBtn, 'mb-4 mt-3')}
                isReady
                // className={classnames(styles.submitBtn, 'mx-3 px-5')}
                disabled={invalid}
                onClick={() => {
                  logEvent('LP_root_getstarted_clicked', {
                    label: formatMessage(messages.buttons.submitButton),
                    source: window && window.location.pathname,
                  });
                  localStorage.setItem('popup', 'false');
                  !isLoggedIn
                    ? showModal(GET_STARTED_MODAL, {
                        onSubmit: this.onSubmit,
                        label: 'Start Free Trial',
                      })
                    : this.showConfirmModalToLoggedInUser();
                }}
              >
                {formatMessage(messages.buttons.submitButton)}
              </button>
              {/* <AddToSlackButton /> */}
            </div>
            <div className={classnames(styles.policy, 'text-center row position-relative ml-1')}>
              <span className="mb-2 d-flex justify-content-center mr-2">
                <img src={blackCircle} alt="square" className={classnames(styles.svg, 'mr-2')} />
                Setup in 20 seconds
              </span>
              <span className="mb-2 d-flex justify-content-center mx-2">
                <img src={blackSquare} alt="square" className={classnames(styles.svg, 'mr-2')} />
                No card required
              </span>
              <span className="mb-2 d-flex justify-content-center mx-2">
                <img src={blackTriangle} alt="square" className={classnames(styles.svg, 'mr-2')} />
                Secure and Private
              </span>
            </div>
          </div>
          <div className="col-6 pl-0">
            {/* <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/metamorph-hero.png"
              width="630"
              className="ml-auto"
              alt="hero"
            /> */}
            <video
              muted
              autoplay="autoplay"
              loop="true"
              playsInline
              width="100%"
              className="m-auto"
            >
              <source
                src="https://indorse-staging-bucket.s3.amazonaws.com/Hero-Section-MV2-Final-withBG-optimized.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        {/* <div className="row d-flex page-max-width mt-5">
          <video muted autoplay="autoplay" loop="true" playsInline width="1000" className="m-auto">
            <source
              src="https://indorse-staging-bucket.s3.amazonaws.com/Hero-Section-MV2-Final-withBG-optimized.mp4"
              type="video/mp4"
            />
          </video>
        </div> */}
        <GetStartedModal />
      </div>
    );
  }

  renderMobile = () => {
    const {
      // handleSubmit,
      intl: { formatMessage },
      // form: formName,
      isLoggedIn,
      showModal,
      invalid,
    } = this.props;

    return (
      <div className={classnames(styles.heroContentCompany, 'container-fluid mt-5')}>
        <div className="row justify-content-center position-relative">
          <div className={classnames('col-10 col-sm-10 col-md-8 col-lg-8 col-xl-6 pt-md-5')}>
            <div className={classnames(styles.metamorphTitle)}>METAMORPH</div>
            <h1 className={classnames(styles.heroTitle, 'mt-4 mx-auto text-center')}>
              <span className={styles.boldText}>Actionable analytics</span> for engineering leaders
            </h1>

            <h3 className={styles.heroSubTitle}>{formatMessage(messages.hero.subTitle)}</h3>

            <div className="row justify-content-center mt-4 col-md-12 mx-auto">
              <button
                className={classnames(styles.getStartedBtn, 'mb-4 mt-3')}
                isReady
                // className={classnames(styles.submitBtn, 'mx-3 px-5')}
                disabled={invalid}
                onClick={() =>
                  !isLoggedIn
                    ? showModal(GET_STARTED_MODAL_MOBILE, {
                        onSubmit: this.onSubmit,
                        label: 'Start Free Trial',
                      })
                    : this.showConfirmModalToLoggedInUser()}
              >
                {formatMessage(messages.buttons.submitButton)}
              </button>
            </div>
            {/* <div className="row justify-content-center mt-4 col-md-10 offset-sm-1">
                <AddToSlackButton />
              </div> */}
            <div
              className={classnames(
                styles.policy,
                'text-center row justify-content-center position-relative mt-3'
              )}
            >
              <span className="mb-2 d-flex justify-content-center mx-2">
                <img src={blackCircle} alt="square" className={classnames(styles.svg, 'mr-2')} />
                Setup in 20 seconds
              </span>
              <span className="mb-2 d-flex justify-content-center mx-2">
                <img src={blackSquare} alt="square" className={classnames(styles.svg, 'mr-2')} />
                No card required
              </span>
              <span className="mb-2 d-flex justify-content-center mx-2">
                <img src={blackTriangle} alt="square" className={classnames(styles.svg, 'mr-2')} />
                Secure and Private
              </span>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center mt-5">
          <img
            src="https://indorse-staging-bucket.s3.amazonaws.com/metamorph-hero.png"
            width="350"
            height="100%"
            className="m-auto"
            alt="hero"
          />
        </div> */}
        <div className="row justify-content-center">
          <video muted autoplay="autoplay" loop="true" playsInline width="450" height="300">
            <source
              src="https://indorse-staging-bucket.s3.amazonaws.com/Hero-Section-MV2-Final-withBG-optimized.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        {/* <WaitlistModal /> */}
        <GetStartedModalMobile />
      </div>
    );
  };

  render() {
    return (
      <span>
        <div className={classnames(styles.heroCompany, 'page-max-width')}>
          <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
            {/* <AnimatedBackground /> */}
            {this.renderDesktop()}
            <VerifyEmailModal />
            <LoginUserModal />
            <UserEmployeeModal />
            <LoggedInUserGetStartedModal />
            <ErrorModal />
            <CompanyExistsEmailSentModal />
            <CompanyExistsEmailSentModalLoggedIn />
          </MediaQuery>
          <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
            {/* <MobileSvgBackground /> */}
            {this.renderMobile()}
            <VerifyEmailModalMobile />
            <LoginUserModalMobile />
            <UserEmployeeModalMobile />
            <LoggedInUserGetStartedModalMobile />
            <ErrorModalMobile />
            <CompanyExistsEmailSentModalMobile />
            <CompanyExistsEmailSentModalLoggedInMobile />
          </MediaQuery>
        </div>
      </span>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const query = new URLSearchParams(ownProps.location.search);
  const alreadyExists = Boolean(query.get('already_exists'));
  const adminEmail = query.get('admin_email');
  return {
    adminEmail,
    alreadyExists,
    isLoggedIn: selectIsLoggedIn(state),
    currentUser: selectCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
    addMessage: bindActionCreators(addMessage, dispatch),
    loginSuccessRequest: ({ token, redirect, from }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
      }),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withApollo(Hero)))
);
