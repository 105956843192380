import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Link from '../Link';
import Button from '../Button';
import Logo from '../Logo';
import Form from '../Form';
import TextInput from '../TextInput';
import './styles.scss';

const Footer = ({
  inverted,
  className,
  children,
  infoNavItems,
  socialNavItems,
  footerBottomNavItems,
  copyrightText,
  newsLetterText,
  isSpecial,
  ...other
}) => {
  const classNames = classnames({
    footer: true,
    className: true,
    inverted: inverted,
    'bg-white': true,
  });

  const bottom = (
    <div className="d-flex justify-content-between footer-bottom">
      <div>
        <p className="footer-bottom-copyright">{copyrightText}</p>
        <small className="text-muted">
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>
      </div>
      <div className="mr-4">
        <nav>
          <ul className="d-flex flex-row" style={{ listStyle: 'none' }}>
            {footerBottomNavItems.map((footerBottomNavItem, index) => (
              <li className="mr-3" key={index}>
                <Link
                  key={footerBottomNavItem.title}
                  href={footerBottomNavItem.link}
                  target={footerBottomNavItem.isTargetBlank ? '_blank' : '_self'}
                >
                  {footerBottomNavItem.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );

  if (!!isSpecial) {
    return (
      <div className={classnames(classNames, 'px-5 py-3 mx-auto')} style={{ position: 'relative' }}>
        {bottom}
      </div>
    );
  }

  const footer = children ? (
    <div {...other} className={classNames} style={{ position: 'relative' }}>
      {children}
    </div>
  ) : (
    <div className={classNames} style={{ position: 'relative' }}>
      <div className="pt-md-5 pb-3 footer-container page-max-width">
        <div className="ooter-main">
          <div className="container">
            <div className="row">
              <div className="pt-3 pt-md-2 mb-5 col-12 col-md-6 col-lg-3">
                <Logo />
              </div>
              {infoNavItems &&
                infoNavItems.length > 0 && (
                  <div className="col-md-6 col-lg-6">
                    <div className="row">
                      <nav className="pt-2 mb-5 col-6 col-md-6">
                        <ul style={{ listStyle: 'none' }}>
                          {infoNavItems.map((infoNavItem, index) => (
                            <li className="" key={index}>
                              <Link
                                key={infoNavItem.title}
                                href={
                                  infoNavItem.title === 'Email'
                                    ? `mailto:${infoNavItem.link}`
                                    : infoNavItem.link
                                }
                                target={infoNavItem.isTargetBlank ? '_blank' : '_self'}
                              >
                                {infoNavItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                      <nav className="pt-2 mb-5 col-6 col-md-6">
                        <ul style={{ listStyle: 'none' }}>
                          {socialNavItems.map((socialNavItem, index) => (
                            <li className="mr-3" key={index}>
                              <Link
                                key={socialNavItem.title}
                                href={socialNavItem.link}
                                target={socialNavItem.isTargetBlank ? '_blank' : '_self'}
                              >
                                {socialNavItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              <div className="col-lg-3 offset-lg-0 offset-md-6 pt-2 mb-5 d-flex justify-content-center justify-content-lg-end">
                <div style={{ minWidth: '309px' }}>
                  <p>{newsLetterText}</p>
                  <Form
                    action="//indorse.us15.list-manage.com/subscribe/post?u=93f666ec18288ad384d11f649&amp;id=7f298cf380"
                    method="post"
                    name="mc-embedded-subscribe-form"
                    noValidate=""
                    target="_blank"
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <TextInput type="email" labelText="Email" />
                      </div>
                      <Button isReady={false} type="submit" className="ml-2 mt-2">
                        Send
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            {bottom}
          </div>
        </div>
      </div>
    </div>
  );

  return footer;
};

Footer.propTypes = {
  inverted: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  labelOne: PropTypes.string,
  linkTextOne: PropTypes.string,
  linkHrefOne: PropTypes.string,
  labelTwo: PropTypes.string,
  linkTextTwo: PropTypes.string,
  linkHrefTwo: PropTypes.string,
  buttonText: PropTypes.string,
};

Footer.defaultProps = {
  labelOne: 'Need Help?',
  linkTextOne: 'Contact Bluemix Sales',
  linkHrefOne: '#',
  labelTwo: 'Estimate Monthly Cost',
  linkTextTwo: 'Cost Calculator',
  linkHrefTwo: '#',
  buttonText: 'Create',
};

export default Footer;
