/*
  ConnectToSlack
*/

import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import routeGenerators from 'ui/common/routes/generators';
import indorseLogo from 'resources/brand/logo-icon.svg';
import styles from './index.module.scss';
import Button from 'ui/common/button';
import ConnectToSlackButton from 'ui/graphql/ConnectToSlackButton';

import { selectCompanyByPrettyId } from 'store/features/companies/selectors';
import SlackPNG from 'ui/graphql/ConnectToSlackButton/slack.png';
import { logEvent } from 'services/amplitude-helper';
// import gql from "graphql-tag";

class ConnectToSlack extends Component {
  render() {
    const { prettyId, setEvent } = this.props;

    return (
      <div className={classnames('container')}>
        <div className={classnames('row justify-content-center align-items-center')}>
          <div className="col-12">
            <div className={'d-flex align-items-center justify-content-center'}>
              <img
                src={indorseLogo}
                className="d-block mx-5 pt-3 mt-3 mb-5"
                width="60px"
                alt="arrow-down"
              />
              <h1
                className={classnames('indorse-blue text-center mx-3')}
                style={{ fontSize: '40px' }}
              >
                +
              </h1>
              <img
                src={SlackPNG}
                className="d-block mx-5 pt-3 mt-3 mb-5"
                width="60px"
                alt="slack"
              />
            </div>
            <h1
              className={classnames(styles.pageTitle, 'indorse-blue text-center')}
              style={{ fontSize: '40px' }}
            >
              Slackbot
            </h1>
            <h3 className={classnames(styles.pageSubTitle, 'text-center pb-5')}>
              If you are using Slack, we recommend installing our Slackbot.
            </h3>

            <div className="my-4 row justify-content-center">
              <ConnectToSlackButton prettyId={prettyId} />
            </div>

            <div className={'d-flex justify-content-center align-items-center my-5'}>
              <div
                style={{
                  width: '10rem',
                  height: '0',
                  borderTop: '2px solid #e3e3e3',
                }}
              />
              <h5 className="text-muted mx-4">OR</h5>
              <div
                style={{
                  width: '10rem',
                  height: '0',
                  borderTop: '2px solid #e3e3e3',
                }}
              />
            </div>

            <div className="my-4 text-center">
              <Button
                className={classnames(styles.getStartedNew)}
                tag={Link}
                to={routeGenerators.metamorph.edit({
                  prettyId,
                  section: 'dashboard',
                })}
                onClick={() => {
                  setEvent();
                  logEvent('go_to_my_dashboard');
                }}
              >
                Go to My Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps && ownProps.match && ownProps.match.params;

  const prettyId = routeParams && routeParams.prettyId;
  const company = selectCompanyByPrettyId(state, { prettyId }) || {};
  return {
    prettyId,
    company,
  };
}

export default withRouter(
  connect(mapStateToProps)(injectIntl(withApollo(withRouter(ConnectToSlack))))
);
