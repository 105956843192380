export function getWeb3Async() {
  return new Promise((resolve, reject) => {
    if (window.Web3) resolve(window.Web3);
    else {
      import('web3')
        .then(resolve)
        .catch(reject);
    }
  });
}

export function getInjectedWeb3Provider() {
  if (window && window.ethereum) {
    return window.ethereum;
  }

  return undefined;
}
