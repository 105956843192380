import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import Helmet from 'react-helmet';
// import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import SubmitButton from 'ui/common/form/submit-button';
import RequestedDelete from 'ui/users/details/requested-delete';
import styles from './index.module.scss';
import validator from 'ui/common/form/validator';
import model from './model';
import * as messages from './messages';
// import routeTemplates from 'ui/common/routes/templates';
import { acceptCurrentTermsAndPrivacy } from 'store/features/auth/actions';
import { selectCurrentUserDeletionRequestedAt } from 'store/features/auth/selectors';
import TADA_IMG from 'resources/ems/images/submission/tada.svg';

const { request: acceptCurrentTermsAndPrivacyRequest } = acceptCurrentTermsAndPrivacy;

const FORM_NAME = 'consent';

const validate = validator(model);

const CLIENT_LOGO_IMAGES = [
  {
    imgUrl:
      'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/home-credit.png',
    widthOnDesktop: '40%',
    widthOnMobile: '50%',
    widthOnIpad: '50%',
  },

  {
    imgUrl: 'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/grab.svg',
    widthOnDesktop: '320%',
    widthOnMobile: '50%',
    widthOnIpad: '40%',
  },
  {
    imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/times-internet-logo.png',
    widthOnDesktop: '40%',
    widthOnMobile: '50%',
    widthOnIpad: '50%',
  },
  {
    imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/Adobe-Logo.svg',
    widthOnDesktop: '20%',
    widthOnMobile: '50%',
    widthOnIpad: '30%',
  },
];

class Consent extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onSubmit() {
    const { acceptCurrentTermsAndPrivacyRequest, form } = this.props;
    acceptCurrentTermsAndPrivacyRequest({ acceptTermsAndConditions: true }, form);
  }

  render() {
    const { currentUserDeletionRequestedAt, handleSubmit, intl: { formatMessage } } = this.props;

    if (currentUserDeletionRequestedAt) {
      return <RequestedDelete />;
    }

    return (
      <div className={classnames('py-4 container', styles.page)}>
        <Helmet>
          <title>{formatMessage(messages.header.title)}</title>
        </Helmet>
        <div className={classnames('mt-4')}>
          <img className={styles.tadaImg} src={TADA_IMG} alt={'tada!'} />
        </div>
        <h1 className={classnames(styles.heading, 'text-center mx-3 mb-3')}>
          {formatMessage(messages.content.pageTitle)}
        </h1>
        <div className="row text-center justify-content-center">
          <h3 className={classnames(styles.subTitle, 'col-12 col-md-7 m-0')}>
            {formatMessage(messages.content.emsPageSubTitle)}
          </h3>
        </div>
        <div className="mt-4 mb-5">
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="text-center px-3">
              <SubmitButton formName={FORM_NAME} className={classnames(styles.submitBtn)}>
                {formatMessage(messages.buttons.submit)}
              </SubmitButton>
            </div>
          </form>
        </div>
        <div className="my-5 text-center">
          <h3 style={{ fontSize: '20px' }} className="my-0">
            {formatMessage(messages.content.pageCompanies)}
          </h3>
          <div className="d-flex justify-content-between align-items-center col-md-6 offset-sm-3">
            <img src={CLIENT_LOGO_IMAGES[0].imgUrl} alt="home credit" style={{ width: '20%' }} />
            <img src={CLIENT_LOGO_IMAGES[1].imgUrl} alt="grab" style={{ width: '20%' }} />
            <img
              src={CLIENT_LOGO_IMAGES[2].imgUrl}
              alt="internet times"
              style={{ width: '20%', height: '40%' }}
            />
            <img src={CLIENT_LOGO_IMAGES[3].imgUrl} alt="singsaver" style={{ width: '15%' }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUserDeletionRequestedAt: selectCurrentUserDeletionRequestedAt(state),
  };
}

const mapDispatchToProps = { acceptCurrentTermsAndPrivacyRequest };

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    validate,
  })(injectIntl(Consent))
);
