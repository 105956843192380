import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { withRouter } from 'react-router';
import StripePNG from './stripe.png';

import classnames from 'classnames';
import styles from './index.module.scss';
import Button from 'ui/common/button';

const useField = initVal => {
  const [value, setValue] = useState(initVal);
  const onChange = e => setValue(e.target.value);
  return {
    value,
    onChange,
  };
};

const ErrorResult = ({ children }) => <div className={styles.error}>{children}</div>;

const StripeCheckoutForm = ({
  toPreviousStep,
  onSubscribeToPlan,
  onSuccess,
  special,
  onNextStep,
}) => {
  const cardHolderName = useField(null);
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    setLoading(true); // show payment button loader

    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (payload.error) {
      setErrorMessage(payload.error.message);
      setLoading(false);
    } else {
      setErrorMessage(null);
      await onSubscribeToPlan(payload);
      !!special ? onSuccess() : onNextStep();
    }
  };

  return (
    <div>
      <React.Fragment className="pt-2 text-left">
        <div className={classnames(styles.cardHolderName, 'mb-3')}>
          <h3 className="text-left">Name</h3>
          <div className={'mt-2'}>
            <input
              type="text"
              name="cardHolderName"
              placeholder="Name on the card"
              required
              {...cardHolderName}
            />
          </div>
        </div>
        <h3 className="text-left">Card Information</h3>
        <CardElement
          hidePostalCode={true}
          className={classnames(styles.cardInput, 'border rounded')}
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />

        {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}

        <div className="row mx-0 justify-content-center pt-1 mt-4 pb-5">
          <Button
            textCenter
            isReady
            type="submit"
            onClick={event => handleSubmit(event)}
            disabled={!stripe}
            className={classnames(styles.stepBtn, 'px-4')}
            loading={isLoading}
            isBlock
            // color="primary-rect"
          >
            {!!special ? 'Save' : 'Checkout'}
          </Button>
          {!special && (
            <Button
              textCenter
              isReady
              onClick={toPreviousStep}
              className={classnames(styles.stepBtn, 'px-4 mt-3')}
              isBlock
              color="border-black-rect"
              style={{ padding: '0.2rem 2.5rem 0.3rem !important' }}
            >
              <h3 className="m-0">
                <i className="fa fa-arrow-left fa-lg mr-3" />Back
              </h3>
            </Button>
          )}
        </div>
        <div className="row justify-content-center text-center pt-5">
          <img src={StripePNG} alt={'stripe'} />
        </div>
      </React.Fragment>
    </div>
  );
};

export default withRouter(StripeCheckoutForm);
