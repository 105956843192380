import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const BillingMethodSwitch = props => {
  const { isActive, onToggle, className } = props;
  const inputId = `billing-yearly-status-toggle`;

  return (
    <span>
      <div className={classnames('switch switch-sm', className)}>
        <label
          htmlFor={inputId}
          className={classnames('mr-3', styles.switchLabel, !isActive && styles.activeTxt)}
        >
          Monthly
        </label>
        <input
          className="switch mr-2"
          id={inputId}
          type="checkbox"
          checked={isActive}
          onChange={onToggle}
        />
        <label
          htmlFor={inputId}
          className={classnames('ml-2 pl-3', styles.switchLabel, isActive && styles.activeTxt)}
        >
          Yearly
        </label>
      </div>
    </span>
  );
};

export default BillingMethodSwitch;
