import React from 'react';
import { NavItem } from 'reactstrap';
import Menu from '../../../common/new-menu';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import styles from './index.module.scss';
import { withRouter } from 'react-router';
// import routeTemplates from '../../../common/routes/templates';
// import METAMORPH_IMG from './metamorph-icon.svg';
// import UPSKILLING_IMG from './upskilling.svg';
// import HACATHONS_IMG from './hackathons.svg';
// import CODING_TESTS_IMG from './coding-tests.svg';
import BLOCKBOTS_IMG from './BlockBots.png';
import NFT_PROJECTS_IMG from './NFT_Projects.svg';
import MARKETPLACE_IMG from './Powerups_Marketplace.png';
import { Link } from 'react-router-dom';

export const PRODUCT_LIST = [
  {
    title: 'BlockBots',
    img: BLOCKBOTS_IMG,
    desc: 'Join our unique play-to-earn NFT project.',
    link: 'https://www.blockbots.gg',
  },
  {
    title: 'Powerups Marketplace',
    img: MARKETPLACE_IMG,
    desc: 'Explore powerups used in the BlockBout game',
    link: 'https://marketplace.blockbots.gg/',
  },
  {
    title: 'Top NFT Projects',
    img: NFT_PROJECTS_IMG,
    desc: 'Rediscover top NFT projects based on their tech and vulnerabilities.',
    link: 'https://ecosystem.indorse.io/nft-projects',
  },
];

const ProductItem = ({ title, img, desc, link, push, onSelect }) => {
  return (
    <Link to={{ pathname: link }} target="_blank">
      <div
        className={classnames(styles.productItem, 'mx-3')}
        // onClick={() => {
        //   onSelect(null);
        //   push(link);
        // }}
      >
        <img src={img} alt={title} width="40px" />
        <h3 className="mt-3">{title}</h3>
        <p>{desc}</p>
      </div>
    </Link>
  );
};

const ProductsList = props => {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggleMenu = () => setIsOpen(!isOpen);
  return (
    <NavItem className="my-auto">
      <Menu
        text={
          <p onClick={() => props.onClick('products')}>
            <FormattedMessage id="app.layout.header.indorse-product-list" defaultMessage="NFT" />
            <i
              className={classnames(
                props.opened === 'products' ? 'fa fa-chevron-up' : 'fa fa-chevron-down',
                'ml-2 mt-1'
              )}
              style={{ fontSize: '10px', color: '#767676' }}
            />
          </p>
        }
        menuOpen={props.opened === 'products'}
        toggle={() =>
          props.opened === 'products' ? props.onClick(null) : props.onClick('products')}
      >
        <div
          className="container py-3 d-flex align-items-center justify-content-start"
          style={{ height: '180px' }}
        >
          {PRODUCT_LIST.map((productItem, idx) => (
            <ProductItem
              key={idx}
              {...productItem}
              push={props.history.push}
              onSelect={props.onClick}
            />
          ))}
        </div>
      </Menu>
    </NavItem>
  );
};

export default withRouter(ProductsList);
