import templates from './templates';

export function generatePath(template, params = {}) {
  if (!template) return template;
  let result = template || '';
  for (var key in params) {
    result = result.replace(`:${key}?`, params[key]);
    result = result.replace(`:${key}`, params[key]);
  }
  return result;
}

const profile = ({ id } = {}) => generatePath(templates.users.profile, { id });
const vanityProfile = ({ username } = {}) =>
  generatePath(templates.users.vanityProfile, { username });
const bestProfile = ({ user, isCurrentUser = false }) => {
  return (
    (user &&
      (user.username
        ? vanityProfile({ username: user.username })
        : user.id ? profile({ id: user.id }) : isCurrentUser ? templates.users.myProfile : null)) ||
    undefined
  );
};

// TODO: Convert this to babel plugin to autoGenerate
export default {
  users: {
    profile,
    vanityProfile,
    bestProfile,
  },
  admin: {
    ems: {
      edit: ({ id }) => generatePath(templates.admin.ems.edit, { id }),
    },
    caseStudy: {
      edit: ({ id }) => generatePath(templates.admin.caseStudy.edit, { id }),
    },
    badges: {
      edit: ({ prettyId }) => generatePath(templates.admin.badges.edit, { prettyId }),
    },
    jobs: {
      edit: ({ id }) => generatePath(templates.admin.jobs.edit, { id }),
      applicants: ({ id }) => generatePath(templates.admin.jobs.applicants, { id }),
      referrals: ({ id }) => generatePath(templates.admin.jobs.referrals, { id }),
    },
    assignments: {
      edit: ({ id }) => generatePath(templates.admin.assignments.edit, { id }),
    },
    skills: {
      edit: ({ name }) => generatePath(templates.admin.skills.edit, { name }),
    },
    validators: {
      details: ({ validator_id }) =>
        generatePath(templates.admin.validators.details, { validator_id }),
      update: ({ validator_id }) =>
        generatePath(templates.admin.validators.update, { validator_id }),
    },
    claims: {
      root: ({ search, source, stage, page, perPage, sort }) => {
        const params = new URLSearchParams();
        if (search) params.set('search', search);
        if (source) params.set('source', source);
        if (stage) params.set('stage', stage);
        if (page) params.set('page', page);
        if (perPage) params.set('per-page', perPage);
        if (sort) params.set('sort', sort);
        return `${templates.admin.claims.root}?${params.toString()}`;
      },
    },
    features: {
      edit: ({ id }) => generatePath(templates.admin.features.edit, { id }),
    },
  },
  claims: {
    details: ({ id }) => generatePath(templates.claims.details, { id }),
    detailsViewPdf: ({ id }) => generatePath(templates.claims.detailsViewPdf, { id }),
  },
  claimReports: {
    details: ({ reportId }) => generatePath(templates.claimReports.details, { reportId }),
  },
  votes: {
    cast: ({ claimId, voteId }) => generatePath(templates.votes.cast, { claimId }),
  },
  ems: {
    gitAppInstallSuccessSetup: ({ prettyId, companyId }) =>
      generatePath(templates.ems.gitAppInstallSuccessSetup, { prettyId, companyId }),
    gitAppInstallSuccessCustomize: ({ prettyId, companyId }) =>
      generatePath(templates.ems.gitAppInstallSuccessCustomize, { prettyId, companyId }),
    employee: {
      blanketTest: ({ id }) => generatePath(templates.ems.employee.blanketTest, { id }),
    },
    assessmentMagicLink: ({ publicId }) =>
      generatePath(templates.ems.assessmentMagicLink, { publicId }),
  },
  hack: {
    event: ({ event }) => generatePath(templates.hack.event, { event }),
  },
  metamorph: {
    edit: ({ prettyId, section = '' }) =>
      generatePath(templates.metamorph.edit, { prettyId, section }),
    setupWizard: ({ prettyId, stepId = '' }) =>
      generatePath(templates.metamorph.emsSetup, { prettyId, stepId }),
  },
  companies: {
    vanityProfile: ({ prettyId }) => generatePath(templates.companies.vanityProfile, { prettyId }),
    acceptAsAdvisor: ({ prettyId, advisoryId }) =>
      generatePath(templates.companies.acceptAsAdvisor, {
        prettyId,
        advisoryId,
      }),
    revokeAsAdvisor: ({ prettyId, advisoryId }) =>
      generatePath(templates.companies.revokeAsAdvisor, {
        prettyId,
        advisoryId,
      }),
    acceptAsTeamMember: ({ prettyId, teamMembershipId }) =>
      generatePath(templates.companies.acceptAsTeamMember, {
        prettyId,
        teamMembershipId,
      }),
    revokeAsTeamMember: ({ prettyId, teamMembershipId }) =>
      generatePath(templates.companies.revokeAsTeamMember, {
        prettyId,
        teamMembershipId,
      }),
    softConnectionInviteAcceptRejectLink: ({ prettyId, id }) =>
      generatePath(templates.companies.acceptAdvisorConnection, {
        prettyId,
        id,
      }),
    edit: ({ prettyId, section = '' }) =>
      generatePath(templates.companies.edit, { prettyId, section }),
    emsSetup: ({ prettyId, stepId = '' }) =>
      generatePath(templates.companies.emsSetup, { prettyId, stepId }),
    employeeDetails: ({ prettyId, empId = '' }) =>
      generatePath(templates.companies.employeeDetails, { prettyId, empId }),
    organization: ({ prettyId, subMenuId = '' }) =>
      generatePath(templates.companies.organization, { prettyId, subMenuId }),
    report: ({ reportId }) => generatePath(templates.companies.report, { reportId }),
    analysis: ({ prettyId, designationId = '' }) =>
      generatePath(templates.companies.analysis, { prettyId, designationId }),
    engagement: ({ prettyId }) => generatePath(templates.companies.engagement, { prettyId }),
    skillMatrix: ({ prettyId, designationId = '' }) =>
      generatePath(templates.companies.skillMatrix, {
        prettyId,
        designationId,
      }),
    editCareerPath: ({ prettyId, departmentId = '' }) =>
      generatePath(templates.companies.editCareerPath, {
        prettyId,
        departmentId,
      }),
    departmentDetails: ({ prettyId, departmentId = '' }) =>
      generatePath(templates.companies.departmentDetails, {
        prettyId,
        departmentId,
      }),
    magicLinkCandidates: ({ prettyId, publicId }) =>
      generatePath(templates.companies.magicLinkCandidates, {
        prettyId,
        publicId,
      }),
    onBoard: ({ prettyId }) => generatePath(templates.companies.onBoarding, { prettyId }),
    getStarted: ({ prettyId }) => generatePath(templates.companies.getStarted, { prettyId }),
    emsGetStarted: ({ prettyId }) => generatePath(templates.companies.emsGetStarted, { prettyId }),
    testDetails: ({ prettyId, assignmentId }) =>
      generatePath(templates.companies.testDetails, { prettyId, assignmentId }),
  },
  badges: {
    details: ({ prettyId }) => generatePath(templates.badges.details, { prettyId }),
  },
  assignments: {
    details: ({ assignmentId }) => generatePath(templates.assignments.details, { assignmentId }),
  },
  magicLinks: {
    assessmentMagicLink: ({ publicId }) =>
      generatePath(templates.magicLinks.assessmentMagicLink, { publicId }),
  },
  validatorFeatures: {
    details: ({ id }) => generatePath(templates.validatorFeatures.details, { id }),
  },
  caseStudyChatbotReports: {
    details: ({ magicLinkId, csaId, userId }) =>
      generatePath(templates.caseStudyChatbotReports.details, {
        magicLinkId,
        csaId,
        userId,
      }),
  },
};
