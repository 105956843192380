import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import autoBind from 'react-autobind';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';

import styles from './index.module.scss';
import themeConfig from 'ui/theme/config';

class EmsPricingDashboard extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderDesktop() {
    return (
      <div className={classnames(styles.hero)}>
        <div>
          <div className="row justify-content-center position-relative">
            <div className={classnames('col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-md-4')}>
              <h1 className={classnames(styles.title, 'mt-4 text-center mb-0')}>
                Gather and aggregate <br /> data that matters
              </h1>
              <h1 className={classnames(styles.subtitle)}>
                Designed to provide you with greater visibility into your software engineering team,
                <br /> Metamorph will easily integrate with platforms you already use!
              </h1>
            </div>
            <img
              src={'https://indorse-staging-bucket.s3.amazonaws.com/Indorse-Integrations.svg'}
              alt="integrations"
              width="1011px"
            />
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        <div className={classnames('container', styles.topSection)}>
          <div className="row">
            <div className={classnames('col-12')}>
              <div className="row justify-content-center">
                <h1 className={classnames(styles.title, 'mt-2 text-center')}>
                  Gather and aggregate <br /> data that matters
                </h1>
              </div>
              <div className={classnames(styles.subtitle)}>
                Designed to provide you with greater visibility into your software engineering team,
                <br /> Metamorph will easily integrate with platforms you already use!
              </div>
            </div>
            <div className={classnames('col-12 py-5')}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/Indorse-Integrations.svg'}
                alt="integrations"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames('container')}>
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
          {this.renderDesktop()}
        </MediaQuery>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
          {this.renderMobile()}
        </MediaQuery>
      </div>
    );
  }
}

export default withRouter(injectIntl(EmsPricingDashboard));
