// import TemplatePlaceholderPng from './template-placeholder.png';

export const templateList = [
  {
    name: '"Accelerate" Book Metrics',
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/Accelerate-Metrics-Template-Thumbnail.png',
    subtitle: `<span>This template is based on the popular book <a href="https://itrevolution.com/book/accelerate/">“Accelerate”</a> which is itself based on years of research and surveys of Engineering Leaders across the world. The authors also publish a “State of Devops” report every year.</span>`,
    fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Accelerate-Metrics-Template.png`,
  },
  // {
  //   name: 'Cross Functional Teams',
  //   imgUrl:
  //     'https://indorse-staging-bucket.s3.amazonaws.com/Cross-Functional-Teams-Template-Thumbnail.png',
  //   subtitle: `<span>This template is based on the popular book <a href="https://itrevolution.com/book/accelerate/">“Accelerate”</a> which is itself based on years of research and surveys of Engineering Leaders across the world. The authors also publish a “State of Devops” report every year.</span>`,
  //   fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Cross-Functional-Teams-Template.png`,
  // },
  {
    name: 'Customer Centric Development',
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/Customer-centric-development-Template-Thumbnail.png',
    subtitle: `<span>This template is for those Engineering managers who want to instil a habit of customer-centric development within their engineering team and lead by focusing on those metrics which enable this development paradigm.</span>`,
    fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Customer-centric-development-Template.png`,
  },
  {
    name: 'Delivery Managers',
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/Delivery-Managers-Template-Thumbnail.png',
    subtitle: `<span>This template is aimed at delivery managers who are always in a crunch to finish the sprint on time and who are constantly trying to optimize the quality of the software delivered against the speed of development!</span>`,
    fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Delivery-Managers-Template.png`,
  },
  // {
  //   name: 'Developer Experience Teams',
  //   imgUrl:
  //     'https://indorse-staging-bucket.s3.amazonaws.com/Developer-Experience-Teams-Template-Thumbnail.png',
  //   subtitle: `<span>This template is based on the popular book <a href="https://itrevolution.com/book/accelerate/">“Accelerate”</a> which is itself based on years of research and surveys of Engineering Leaders across the world. The authors also publish a “State of Devops” report every year.</span>`,
  //   fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Developer-Experience-Teams-Template.png`,
  // },
  // {
  //   name: 'Mobile Teams',
  //   imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/Mobile-Teams-Template-Thumbnail.png',
  //   subtitle: `<span>This template is based on the popular book <a href="https://itrevolution.com/book/accelerate/">“Accelerate”</a> which is itself based on years of research and surveys of Engineering Leaders across the world. The authors also publish a “State of Devops” report every year.</span>`,
  //   fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Mobile-Teams-Template.png`,
  // },
  // {
  //   name: 'Seed Stage Startup',
  //   imgUrl:
  //     'https://indorse-staging-bucket.s3.amazonaws.com/Seed-Stage-Startup-Template-Thumbnail.png',
  //   subtitle: `<span>This template is based on the popular book <a href="https://itrevolution.com/book/accelerate/">“Accelerate”</a> which is itself based on years of research and surveys of Engineering Leaders across the world. The authors also publish a “State of Devops” report every year.</span>`,
  //   fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Seed-Stage-Startup-Template.png`,
  // },
  // {
  //   name: 'Series A Startup',
  //   imgUrl:
  //     'https://indorse-staging-bucket.s3.amazonaws.com/Series-A-Startup-Template-Thumbnail.png',
  //   subtitle: `<span>This template is based on the popular book <a href="https://itrevolution.com/book/accelerate/">“Accelerate”</a> which is itself based on years of research and surveys of Engineering Leaders across the world. The authors also publish a “State of Devops” report every year.</span>`,
  //   fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Series-A-Startup-Template.png`,
  // },
  {
    name: 'Stakeholder Management',
    desc: 'Dollar-Cost',
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/Stakeholder-Management(dollar-cost)-Thumbnail.png',
    subtitle: `<span>This template can help the Engineering Leaders present the “Dollar-cost” of certain activities being performed by the Engineering team and help start conversations with the business stakeholders in a language that they speak! (Note:- The values in this template are indicative only and are based on the median salary of a mid-level software developer from San Francisco, USA)</span>`,
    fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Stakeholder-Management(dollar-cost).png`,
  },
  {
    name: 'Stakeholder Management',
    desc: 'Product Features vs Engg Metrics',
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/Stakeholder-Management(prodfeatvsenggmetrics)-Thumbnail.png',
    subtitle: `<span>Are you an Engineering Manager who is constantly trying to get the Engineering features prioritized alongside the product features in every sprint? Then this template is for you to convince your peers why it is also important to devote time to the Engineering Metrics as well!</span>`,
    fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Stakeholder-Management(prodfeatvsenggmetrics).png`,
  },
  {
    name: 'Stakeholder Management',
    desc: 'Tech-Debt',
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/Stakeholder-Management(tech-debt)-Thumbnail.png',
    subtitle: `<span>This template will help Engineering Leaders to convenience their peers and the business stakeholders to understand the hidden cost of tech debt in their organization and start a conversation.</span>`,
    fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Stakeholder-Management(tech-debt).png`,
  },
  // {
  //   name: 'Team Managers',
  //   imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/Team-Managers-Template-Thumbnail.png',
  //   subtitle: `<span>This template is based on the popular book <a href="https://itrevolution.com/book/accelerate/">“Accelerate”</a> which is itself based on years of research and surveys of Engineering Leaders across the world. The authors also publish a “State of Devops” report every year.</span>`,
  //   fullImg: `https://indorse-staging-bucket.s3.amazonaws.com/Team-Managers-Template.png`,
  // },
];
