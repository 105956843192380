import React, { Component } from 'react';
import autobind from 'react-autobind';
import { Navbar, Nav, NavItem } from 'reactstrap';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link, NavLink as RouterLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { animateScroll } from 'react-scroll';
import Menu from 'ui/common/menu';
import UserIndToken from './userIndorseToken';
import ExternalLink from 'ui/common/external-link';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import classnames from 'classnames';
import styles from './index.module.scss';
import routeGenerator from 'ui/common/routes/generators';
import { logEvent } from 'services/amplitude-helper';
import Avatar from 'ui/users/avatar';
import Logo from 'ui/common/rebranding/components/Logo/Logo';
import AdminNavItems from './admin';
import Icon from 'ui/common/icon';
import Companies from './companies';
import AdminPendingApprovalCompanyClaimsNavItem from 'ui/graphql/AdminPendingApprovalCompanyClaimsNavItem/loadable';

import Button from 'ui/common/button';
import OverflowMenu from 'ui/common/rebranding/components/OverflowMenu/OverflowMenu';
import OverflowMenuItem from 'ui/common/rebranding/components/OverflowMenuItem/OverflowMenuItem';
import OverflowMenuHeader from 'ui/common/rebranding/components/OverflowMenu/OverflowMenuHeader';
import OverflowMenuBottom from 'ui/common/rebranding/components/OverflowMenu/OverflowMenuBottom';
// import AddToSlackButton from 'ui/graphql/AddToSlackButton';
import ProductsList, { PRODUCT_LIST } from './products';
// import CommunityList, { PRODUCT_LIST as COMMUNITY_LIST } from './community';

import {
  selectIsLoggedIn,
  selectCurrentUser,
  selectCurrentUserFetchedAtleastOnce,
  selectCurrentUserHasPassword,
  selectIsCurrentUserAdmin,
  selectIsCurrentUserFullAccess,
  selectIsCurrentUserNoAccess,
  selectCurrentUserEthereumAddress,
  selectIsCurrentUserEthereumAddressVerified,
  selectIsCurrentUserTier3,
  selectIsCurrentUserPanelReviewer,
} from 'store/features/auth/selectors';
import {
  selectIsCurrentUsersClaimById,
  selectCanCurrentUserVoteOnClaimById,
} from 'store/features/claims/selectors';
import { logout } from 'store/features/auth/actions';

import routeTemplates from 'ui/common/routes/templates';
import { createMatchSelector } from 'ui/common/routes/selectors';

import { AIPValidationEnabled, ImpressAIValidationEnabled } from 'resources/validations';
import { hide as hideModal, show as showModal } from 'redux-modal';
import InternalLink from 'ui/common/internal-link';
const scroll = animateScroll;
const EMS_USER = 'EMS_USER';

const navLinkOptions = {
  activeClass: 'active',
  delay: 100,
  spy: true,
  smooth: true,
  duration: 1500,
  offset: -100,
};

const aboutCompanyPageEnabled = process.env.REACT_APP_ENABLE_ABOUT_COMPANY_PAGE === 'true';

class Header extends Component {
  static propTypes = {
    intl: intlShape,
  };

  constructor(props) {
    super(props);

    autobind(this);

    this.navbar = null;
    this.state = {
      navLinkOptions,
      openSubMenu: false,
      openId: null,
    };
  }

  openCollapseMenu(id) {
    this.setState({ openId: id === this.state.openId ? null : id });
  }

  toggleSubMenu() {
    this.setState({
      openSubMenu: !this.state.openSubMenu,
    });
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  logout() {
    this.props.logout.request();
  }

  renderAdminNavItem() {
    const { currentUserIsAdmin } = this.props;

    return currentUserIsAdmin && <AdminNavItems />;
  }

  renderUserNav() {
    const {
      currentUser,
      // currentUserIsAdmin,
      // currentUserIsFullAccess,
      loggedIn,
      companies,
      isEmsUser,
    } = this.props;

    return (
      <Nav className="ml-3 mr-auto">
        {!isEmsUser &&
          companies &&
          companies.length === 0 && (
            <NavItem className="my-auto pr-4">
              <RouterLink
                exact
                activeClassName={classnames(styles.active)}
                to={routeGenerator.users.bestProfile({
                  user: currentUser,
                  isCurrentUser: true,
                })}
              >
                <FormattedMessage id="app.layout.header.profile" defaultMessage="My Profile" />
              </RouterLink>
            </NavItem>
          )}
        {companies.length > 0 && <Companies companies={companies} />}
        {/* <NavItem className="my-auto pr-4">
          <RouterLink
            exact
            activeClassName={classnames(styles.active)}
            to={routeTemplates.assignments.myEvaluations}
          >
            <FormattedMessage id="app.layout.header.evaluations" defaultMessage="My Evaluations" />
          </RouterLink>
        </NavItem> */}
        {!loggedIn &&
          aboutCompanyPageEnabled && (
            <NavItem className={classnames('my-auto pr-4', styles.navItem)}>
              <RouterLink
                exact
                className={classnames('my-auto')}
                activeClassName={classnames(styles.active)}
                to={{
                  pathname: routeTemplates.aboutCompany,
                  state: { ref: 'navbar' },
                }}
              >
                <FormattedMessage id="app.layout.header.about" defaultMessage="About Us" />
              </RouterLink>
            </NavItem>
          )}

        {!isEmsUser &&
          companies &&
          companies.length === 0 && (
            <NavItem className="my-auto pr-4">
              <RouterLink
                exact
                activeClassName={classnames(styles.active)}
                to={routeTemplates.validatorsLeaderboard}
              >
                <FormattedMessage
                  id="app.layout.header.validatorsLeaderboard"
                  defaultMessage="Leaderboard"
                />
              </RouterLink>
            </NavItem>
          )}
      </Nav>
    );
  }

  renderAnonymousNav() {
    return (
      <Nav className={classnames('ml-4 text-center', styles.nav)}>
        <ProductsList onClick={this.openCollapseMenu} opened={this.state.openId} />
        {/* <CommunityList onClick={this.openCollapseMenu} opened={this.state.openId} /> */}

        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-pricing-page')}
        >
          <Link
            className={classnames('my-auto')}
            activeClassName={classnames(styles.active)}
            to={{
              pathname:
                'https://v2.info.uniswap.org/pair/0xd5b1523b48d41c46e5d1091a9b068159353ff3d7',
            }}
            target="_blank"
          >
            <FormattedMessage id="app.layout.header.uniswap" defaultMessage="Uniswap" />
          </Link>
        </NavItem>

        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-resources-page')}
        >
          <ExternalLink openInNewTab href="https://app.sushi.com/en/swap">
            <FormattedMessage id="app.layout.header.sushiswap" defaultMessage="SushiSwap" />
          </ExternalLink>
        </NavItem>
        {/* <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-resources-page')}
        >
          <ExternalLink openInNewTab href="https://ecosystem.indorse.com/stake">
            <FormattedMessage id="app.layout.header.staking" defaultMessage="Staking" />
          </ExternalLink>
        </NavItem> */}
      </Nav>
    );
  }

  renderNavbarCta() {
    return (
      <Nav>
        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-login-v4')}
        >
          <RouterLink
            exact
            className={classnames('my-auto')}
            activeClassName={classnames(styles.active)}
            to={{ pathname: routeTemplates.auth.login }}
          >
            <FormattedMessage
              id="app.layout.header.company-offering.login"
              defaultMessage="Sign In"
            />
          </RouterLink>
        </NavItem>
        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-login-v4')}
        >
          <Button href={'https://www.blockbots.gg'} color="primary-rect">
            What's New
          </Button>
        </NavItem>
      </Nav>
    );
  }

  renderDropdownProfile() {
    const {
      currentUser,
      history,
      currentUserIsAdmin,
      currentUserIsFullAccess,
      currentUserIsNoAccess,
      currentUserHasPassword,
      isCurrentUserPanelReviewer,
      companies,
      isEmsUser,
    } = this.props;

    return (
      <Nav className="ml-auto">
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
          {this.renderAdminNavItem()}
        </MediaQuery>
        {currentUserIsAdmin && <AdminPendingApprovalCompanyClaimsNavItem className="pr-3" />}
        {!isEmsUser &&
          currentUser &&
          companies.length === 0 && (
            <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
              <NavItem className={classnames(styles.navBtn, 'pr-3')}>
                <Button
                  to={{
                    pathname: routeTemplates.validate,
                    state: { ref: 'navbar' },
                  }}
                  color="primary"
                  isReady={false}
                >
                  <FormattedMessage
                    id="app.layout.header.validate"
                    defaultMessage="Validate a Skill"
                  />
                </Button>
              </NavItem>
            </MediaQuery>
          )}
        <NavItem className="pr-3">{this.renderUserIndToken()}</NavItem>
        <NavItem>
          <OverflowMenu
            renderIcon={iconProps => (
              <Avatar user={currentUser} size={33} className={classnames('text-center')} />
            )}
          >
            <OverflowMenuHeader
              className="py-3"
              onClick={() =>
                companies && companies.length > 0 ? null : history.push(`/${currentUser.username}`)}
            >
              {currentUser.name || currentUser.email}
            </OverflowMenuHeader>

            {/* <OverflowMenuItem
              className="py-3"
              onClick={() => history.push(routeTemplates.assignments.myEvaluations)}
            >
              <FormattedMessage
                id="app.layout.header.evaluations"
                defaultMessage="My Evaluations"
              />
            </OverflowMenuItem> */}
            {!isEmsUser &&
              companies &&
              companies.length === 0 && (
                <OverflowMenuItem
                  className="py-3"
                  onClick={() => history.push(routeTemplates.claims.root)}
                >
                  <FormattedMessage id="app.layout.header.claims" defaultMessage="My Claims" />
                </OverflowMenuItem>
              )}
            {(currentUserIsAdmin || currentUserIsFullAccess) && (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.votes.root)}
              >
                <FormattedMessage id="app.layout.header.votes" defaultMessage="My Votes" />
              </OverflowMenuItem>
            )}
            {isCurrentUserPanelReviewer && (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.panelReviews)}
              >
                <FormattedMessage
                  id="app.layout.header.panel-reviews"
                  defaultMessage="My Panel Reviews"
                />
              </OverflowMenuItem>
            )}

            {!isEmsUser &&
              !currentUserIsNoAccess &&
              companies &&
              companies.length === 0 && (
                <OverflowMenuItem
                  className="py-3"
                  onClick={() => history.push(routeTemplates.auth.linkedAccounts)}
                >
                  <FormattedMessage
                    id="app.layout.header.linked-accounts"
                    defaultMessage="Linked Accounts"
                  />
                </OverflowMenuItem>
              )}

            {/*currentUser && (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.users.myJobApplications)}
              >
                <FormattedMessage
                  id="app.layout.header.myJobApplications"
                  defaultMessage="My Job Applications"
                />
              </OverflowMenuItem>
            )*/}

            {currentUserHasPassword ? (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.auth.changePassword)}
              >
                <FormattedMessage
                  id="app.layout.header.change-password"
                  defaultMessage="Change Password"
                />
              </OverflowMenuItem>
            ) : (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.auth.setPassword)}
              >
                <FormattedMessage
                  id="app.layout.header.set-password"
                  defaultMessage="Set Password"
                />
              </OverflowMenuItem>
            )}

            <OverflowMenuBottom>
              <Button className="mt-2" isReady={false} onClick={this.logout}>
                <FormattedMessage id="app.layout.header.log-out" defaultMessage="Logout" />
              </Button>
            </OverflowMenuBottom>
          </OverflowMenu>
        </NavItem>
      </Nav>
    );
  }

  renderUserBurgerMenu() {
    const {
      currentUserIsAdmin,
      currentUser,
      companies,
      isCurrentUserPanelReviewer,
      isEmsUser,
    } = this.props;

    return (
      <Nav>
        <NavItem className="text-left">
          <Menu burger isOpenSubMenu={this.state.openSubMenu}>
            {currentUserIsAdmin && (
              <div className={'text-left mx-5'}>
                {/*<FormattedMessage id="app.layout.header.admin" defaultMessage="Admin" />*/}
                <ul className={'text-left'}>
                  <li className="text-left pl-4 py-4">
                    <Icon
                      background
                      name="icon--arrow-left"
                      tooltip="Previous"
                      description="previous"
                    />
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.users.index}>
                      <FormattedMessage id="app.layout.header.admin.users" defaultMessage="Users" />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.companies.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.companies"
                        defaultMessage="Companies"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.skills.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.skills"
                        defaultMessage="Skills"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.badges.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.badges"
                        defaultMessage="Badges"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.claims.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.claims"
                        defaultMessage="Claims"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.assignments.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.assignments"
                        defaultMessage="Assignments"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.stats.root}>
                      <FormattedMessage id="app.layout.header.admin.stats" defaultMessage="Stats" />
                    </DropdownItem>
                  </li>
                </ul>
              </div>
            )}
            {isCurrentUserPanelReviewer && (
              <DropdownItem tag={RouterLink} to={routeTemplates.panelReviews}>
                <h3 className="text-left px-0 my-0 mx-5">
                  <FormattedMessage
                    id="app.layout.header.panel-reviews"
                    defaultMessage="My Panel Reviews"
                  />
                </h3>
              </DropdownItem>
            )}
            {companies.length === 0 && (
              <DropdownItem
                className="text-left mt-2"
                tag={RouterLink}
                to={routeGenerator.users.bestProfile({ user: currentUser })}
              >
                <h3 className="text-left mx-5">
                  <FormattedMessage id="app.layout.header.profile" defaultMessage="My Profile" />
                </h3>
              </DropdownItem>
            )}

            {!isEmsUser &&
              companies.length === 0 && (
                <DropdownItem
                  tag={RouterLink}
                  to={{
                    pathname: routeTemplates.validatorsLeaderboard,
                    state: { ref: 'navbar' },
                  }}
                >
                  <h3 className="text-left mx-5">
                    <FormattedMessage
                      id="app.layout.header.validatorsLeaderboard"
                      defaultMessage="Leaderboard"
                    />
                  </h3>
                </DropdownItem>
              )}
            {!isEmsUser &&
              currentUser &&
              companies.length === 0 && (
                <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm}>
                  <DropdownItem
                    tag={RouterLink}
                    to={{
                      pathname: routeTemplates.validate,
                      state: { ref: 'navbar' },
                    }}
                    color="primary"
                    className="text-left"
                  >
                    <h3 className="text-left mx-5">
                      <FormattedMessage
                        id="app.layout.header.validate"
                        defaultMessage="Validate a Skill"
                      />
                    </h3>
                  </DropdownItem>
                </MediaQuery>
              )}
            {/*{!isEmsUser && (*/}
            {/*  <DropdownItem tag={RouterLink} to={routeTemplates.validatorFeatures.root}>*/}
            {/*    <FormattedMessage*/}
            {/*      id="app.layout.header.validatorFeatures"*/}
            {/*      defaultMessage="Features"*/}
            {/*    />*/}
            {/*  </DropdownItem>*/}
            {/*)}*/}
            <DropdownItem className="text-left mx-5 px-0">
              <Companies companies={companies} />
            </DropdownItem>
            <div className="text-left mx-5">
              <Button className="mt-2" color="primary-rect" onClick={this.logout}>
                <h3 className="m-0">
                  <FormattedMessage id="app.layout.header.log-out" defaultMessage="Logout" />
                </h3>
              </Button>
            </div>
          </Menu>
        </NavItem>
      </Nav>
    );
  }

  renderAnonymousBurgerMenu() {
    return (
      <Nav>
        <Menu burger>
          {/*<DropdownItem*/}
          {/*  tag={RouterLink}*/}
          {/*  to={routeTemplates.developersPage}*/}
          {/*  onClick={() => logEvent('navbar-to-dev-page-v4')}*/}
          {/*>*/}
          {/*  <FormattedMessage*/}
          {/*    id="app.layout.header.company-offering-developers"*/}
          {/*    defaultMessage="For Developers"*/}
          {/*  />*/}
          {/*</DropdownItem>*/}

          <div className={classnames(styles.mobileNavContainer)}>
            <div
              className={classnames(
                'row justify-content-between mx-auto',
                this.state.openId === 'products' ? 'mb-3' : 'mb-4'
              )}
              onClick={() => {
                this.openCollapseMenu('products');
                logEvent('navbar-to-product-page-v4');
              }}
            >
              <div>
                <FormattedMessage
                  id="app.layout.header.company-offering-products"
                  defaultMessage="NFT"
                />
              </div>

              <div>
                <i
                  className={classnames(
                    this.state.openId === 'products' ? 'fas fa-angle-up' : 'fas fa-angle-down'
                  )}
                  style={{ color: '#767676' }}
                />
              </div>
            </div>

            {this.state.openId === 'products' && (
              <div className="p-0">
                <hr />
                {PRODUCT_LIST.map((productItem, idx) => (
                  <div className="mb-3">
                    <DropdownItem tag={RouterLink} to={productItem.link} key={idx}>
                      <b>{productItem.title}</b>
                    </DropdownItem>
                  </div>
                ))}
                <hr />
              </div>
            )}

            {/* <div
              className={classnames(
                'row justify-content-between mx-auto',
                this.state.openId === 'community' ? 'mb-3' : 'mb-4'
              )}
              onClick={() => {
                this.openCollapseMenu('community');
              }}
            >
              <div>
                <FormattedMessage
                  id="app.layout.header.company-offering-community"
                  defaultMessage="Community"
                />
              </div>

              <div>
                <i
                  className={classnames(
                    this.state.openId === 'community' ? 'fas fa-angle-up' : 'fas fa-angle-down'
                  )}
                  style={{ color: '#767676' }}
                />
              </div>
            </div>

            {this.state.openId === 'community' && (
              <div className="p-0">
                <hr />
                {COMMUNITY_LIST.map((productItem, idx) => (
                  <div className="mb-3">
                    <DropdownItem tag={RouterLink} to={productItem.link} key={idx}>
                      <b>{productItem.title}</b>
                    </DropdownItem>
                  </div>
                ))}
                <hr />
              </div>
            )} */}

            <div className="mb-4">
              <ExternalLink
                openInNewTab
                href="https://v2.info.uniswap.org/pair/0xd5b1523b48d41c46e5d1091a9b068159353ff3d7"
              >
                <FormattedMessage id="app.layout.header.uniswap" defaultMessage="Uniswap" />
              </ExternalLink>
            </div>

            <div className="mb-4">
              <p>
                <ExternalLink openInNewTab href="https://app.sushi.com/en/swap">
                  <FormattedMessage id="app.layout.header.sushiswap" defaultMessage="SushiSwap" />
                </ExternalLink>
              </p>
            </div>
            {/* <div className="mb-4">
              <p>
                <ExternalLink openInNewTab href="https://ecosystem.indorse.io/stake">
                  <FormattedMessage id="app.layout.header.staking" defaultMessage="Staking" />
                </ExternalLink>
              </p>
            </div> */}
            <div className="mb-4">
              <DropdownItem
                tag={RouterLink}
                to={routeTemplates.auth.login}
                onClick={() => logEvent('navbar-to-login-v4')}
              >
                <FormattedMessage
                  id="app.layout.header.company-offering.login"
                  defaultMessage="Sign In"
                />
              </DropdownItem>
            </div>
          </div>
        </Menu>
      </Nav>
    );
  }

  renderUserIndToken() {
    const {
      currentUser,
      currentUserEthereumAddress,
      currentUserHasEthereumAddress,
      isCurrentUserTier3,
    } = this.props;

    if (
      !currentUser ||
      !currentUserEthereumAddress ||
      !currentUserHasEthereumAddress ||
      isCurrentUserTier3
    )
      return null;

    return <UserIndToken userEthereumAddress={currentUserEthereumAddress} />;
  }

  renderBanner() {
    return (
      <section className={classnames(styles.banner)}>
        <div className={classnames(styles.bannerContainer)}>
          <h3 className={classnames(styles.bannerTitle)}>
            Diwali <span>Giveaway</span>
          </h3>
          <div>
            <InternalLink to={routeTemplates.claims.special}>
              <div className={classnames(styles.bannerButton)}>Participate Now</div>
            </InternalLink>
          </div>
        </div>
        <div className={classnames(styles.lamp1Stick)} />
        <div className={classnames(styles.lamp2Stick)} />
        <div className={classnames(styles.lamp3Stick)} />
        <div className={classnames(styles.lamp4Stick)} />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/Lamp-short.svg"
          alt="large-lamp1"
          className={classnames(styles.largeLamp1)}
        />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/Lamp-short.svg"
          alt="large-lamp2"
          className={classnames(styles.largeLamp2)}
        />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/Lamp-short.svg"
          alt="large-lamp3"
          className={classnames(styles.largeLamp3)}
        />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/Lamp-short.svg"
          alt="large-lamp4"
          className={classnames(styles.largeLamp4)}
        />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/firework-medium.svg"
          alt="large-firework1"
          className={classnames(styles.largeFirework1)}
        />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/firework-medium.svg"
          alt="large-firework2"
          className={classnames(styles.largeFirework2)}
        />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/firework-medium.svg"
          alt="large-firework3"
          className={classnames(styles.largeFirework3)}
        />
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/diwali-event/firework-medium.svg"
          alt="large-firework4"
          className={classnames(styles.largeFirework4)}
        />
      </section>
    );
  }

  render() {
    const { loggedIn, className, withBanner, history, isSpecial } = this.props;

    return (
      <React.Fragment>
        {withBanner && this.renderBanner()}
        <Navbar
          id="main-header"
          tag="header"
          // fixed="top"
          className={classnames(className, styles.navbar, isSpecial && styles.special)}
          style={{ top: withBanner ? '85px' : '0' }}
        >
          <section
            ref={el => (this.navbar = el && el.parentElement)}
            className={classnames(
              'w-100 px-0 d-flex align-items-center justify-content-between px-3',
              styles.navbarContainer,
              isSpecial && styles.specialContainer,
              isSpecial && 'px-4'
            )}
          >
            <div className="d-flex align-items-center">
              <div
                className="pr-3 mb-2"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(routeTemplates.root)}
              >
                <Logo isSpecial={isSpecial} />
              </div>

              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
                {loggedIn ? this.renderUserNav() : this.renderAnonymousNav()}
              </MediaQuery>
            </div>
            <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
              {!loggedIn && this.renderNavbarCta()}
            </MediaQuery>

            {loggedIn && this.renderDropdownProfile()}
            <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm}>
              {loggedIn ? this.renderUserBurgerMenu() : this.renderAnonymousBurgerMenu()}
            </MediaQuery>
          </section>
        </Navbar>
      </React.Fragment>
    );
  }
}

const myClaimsMatchSelector = createMatchSelector({
  path: routeTemplates.claims.my,
  exact: true,
});
const claimsDetailsMatchSelector = createMatchSelector({
  path: routeTemplates.claims.details,
  exact: true,
});
const myVotesMatchSelector = createMatchSelector({
  path: routeTemplates.votes.my,
  exact: true,
});

function mapStateToProps(state) {
  const myProfileRouteMatch = !!createMatchSelector({
    path: routeTemplates.users.myProfile,
    exact: true,
  })(state);
  const otherProfileRouteMatch = !!createMatchSelector({
    path: routeTemplates.users.vanityProfile,
    exact: true,
  })(state);
  const myClaimsMatch = myClaimsMatchSelector(state);
  const myVotesMatch = myVotesMatchSelector(state);
  const claimsDetailsMatch = claimsDetailsMatchSelector(state);
  const shouldMyClaimsBeActive =
    !!myClaimsMatch ||
    (claimsDetailsMatch &&
      claimsDetailsMatch.params &&
      claimsDetailsMatch.params.id &&
      selectIsCurrentUsersClaimById(state, {
        id: claimsDetailsMatch.params.id,
      }));
  const shouldMyVotesBeActive =
    !shouldMyClaimsBeActive &&
    (!!myVotesMatch ||
      (claimsDetailsMatch &&
        claimsDetailsMatch.params &&
        claimsDetailsMatch.params.id &&
        selectCanCurrentUserVoteOnClaimById(state, {
          id: claimsDetailsMatch.params.id,
        })));

  const canVisitNewClaimPage =
    AIPValidationEnabled &&
    (selectIsCurrentUserFullAccess(state) || selectIsCurrentUserAdmin(state));

  const canVisitValidationChoicePage = ImpressAIValidationEnabled;

  const showValidationButton = canVisitValidationChoicePage || canVisitNewClaimPage;
  const currentUserEthereumAddress = selectCurrentUserEthereumAddress(state);
  const currentUser = selectCurrentUser(state);
  const isEmsUser = currentUser && currentUser.type === EMS_USER;
  // console.log('currentUser : ', currentUser);
  return {
    isCurrentUserTier3: selectIsCurrentUserTier3(state),
    isCurrentUserPanelReviewer: selectIsCurrentUserPanelReviewer(state),
    loggedIn: selectIsLoggedIn(state),
    currentUser,
    isEmsUser,
    currentUserFetchedAtleastOnce: selectCurrentUserFetchedAtleastOnce(state),
    currentUserHasPassword: selectCurrentUserHasPassword(state),
    currentUserEthereumAddress,
    currentUserHasEthereumAddress: !!selectCurrentUserEthereumAddress(state),
    currentUserHasVerifiedEthereumAddress: selectIsCurrentUserEthereumAddressVerified(state),
    currentUserIsAdmin: selectIsCurrentUserAdmin(state),
    currentUserIsFullAccess: selectIsCurrentUserFullAccess(state),
    shouldMyClaimsBeActive,
    shouldMyVotesBeActive,
    currentUserIsNoAccess: selectIsCurrentUserNoAccess(state),
    canVisitValidationChoicePage,
    canVisitNewClaimPage,
    showValidationButton,
    myProfileRouteMatch,
    otherProfileRouteMatch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: {
      request: bindActionCreators(logout.request, dispatch),
    },
    showModal: bindActionCreators(showModal, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false /* This is required for link 'active' feature to work when route is changed. It works on refresh without this */,
})(injectIntl(withRouter(Header)));
