import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { connectModal as reduxModal, hide as hideModal, show as showModal } from 'redux-modal';
import { Modal, ModalHeader, ModalBody } from 'ui/common/modal';
// import Button from 'ui/common/button';
import classnames from 'classnames';
import styles from './index.module.scss';
import { logEvent } from 'services/amplitude-helper';
import { MODAL_NAME as GET_STARTED_MODAL } from '../hero/get-started-modal';

export const MODAL_NAME = 'expanded-template-modal';

class ExpandedTemplateModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { show, handleHide, template, showModal } = this.props;

    return (
      <Modal isOpen={show} size="lg" style={{ minWidth: '1200px' }}>
        <ModalHeader toggle={handleHide} className={classnames(styles.macroContainer)}>
          <div className={classnames(styles.macro, 'mx-auto mb-3')}>Template</div>
        </ModalHeader>
        <ModalBody className="pt-0">
          <div className={classnames(styles.templateTitle)}>
            {template.name}{' '}
            {template.desc && (
              <span className={classnames(styles.templateSubtitle)}>({template.desc})</span>
            )}
          </div>

          <div
            className={classnames(styles.templateSubtitle)}
            dangerouslySetInnerHTML={{ __html: template.subtitle }}
          />
          <button
            className={classnames(styles.learnMoreBtn)}
            isReady
            onClick={() => {
              logEvent('LP_root_template_learnMore_clicked', {
                label: `Learn More`,
                source: window && window.location.pathname,
                templateName: template.name,
                templateDesc: template.desc || '',
              });
              handleHide();
              showModal(GET_STARTED_MODAL, { label: 'Learn More' });
            }}
          >
            Learn More
          </button>
          <img
            src={template.fullImg}
            alt={template.name}
            width="1000px"
            className={classnames(styles.fullImg, 'mx-auto')}
          />
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  hideModal,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(injectIntl(ExpandedTemplateModal))
);
