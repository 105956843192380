import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './styles.scss';

import InternalLink from 'ui/common/internal-link';
import ExternalLink from 'ui/common/external-link';

const Button = ({
  children,
  className,
  isReady,
  kind,
  to,
  href,
  tabIndex,
  type,
  isBlock,
  ...other
}) => {
  const buttonClasses = classNames(className, 'btn', {
    'is-ready': isReady,
    'btn-primary': kind === 'primary',
    'btn-secondary': kind === 'secondary',
    'btn-tertiary': kind === 'tertiary',
    'btn-danger': kind === 'danger',
    'btn-company-white': kind === 'company-white',
    'btn-company-blue': kind === 'company-blue',
    'btn-metamorph-blue': kind === 'metamorph-blue',
    'btn-company-blue-secondary': kind === 'company-blue-secondary',
    'btn-company-dark-blue': kind === 'company-dark-blue',
    'btn-company-light-blue': kind === 'company-light-blue',
    'btn-company-green': kind === 'company-green',
    'btn-company-purple': kind === 'company-purple',
    'btn-company-purple-secondary': kind === 'company-purple-secondary',
    'btn-border-black': kind === 'border-black',
    'btn-border-black-rect': kind === 'border-black-rect',
    'btn-border-red': kind === 'border-red',
    'btn-border-green-special': kind === 'green-special',
    'btn-primary-rect': kind === 'primary-rect',
    disabled: !!other.disabled,
    'is-block': !!isBlock,
  });

  const commonProps = {
    tabIndex,
    className: buttonClasses,
  };

  if (to) {
    return (
      <InternalLink {...other} {...commonProps} to={to} role="button">
        {children}
      </InternalLink>
    );
  } else if (href) {
    return (
      <ExternalLink {...other} {...commonProps} href={href} role="button">
        {children}
      </ExternalLink>
    );
  }

  const button = (
    <button {...other} {...commonProps} type={type}>
      {children}
    </button>
  );

  return button;
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  kind: PropTypes.oneOf([
    'primary',
    'secondary',
    'ternary',
    'disabled',
    'danger',
    'company-blue',
    'company-blue-secondary',
    'company-dark-blue',
    'company-light-blue',
    'company-green',
    'company-purple',
    'company-purple-secondary',
    'border-black',
    'border-red',
    'green-special',
    'company-white',
    'primary-rect',
    'border-black-rect',
  ]).isRequired,
  href: PropTypes.string,
  tabIndex: PropTypes.number,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  role: PropTypes.string,
  isReady: PropTypes.bool,
  isBlock: PropTypes.bool,
};

Button.defaultProps = {
  tabIndex: 0,
  type: 'button',
  disabled: false,
  isReady: false,
  kind: 'primary',
  isBlock: false,
};

export default Button;
