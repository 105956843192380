/*
  ConnectToSlackButton
*/
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import shortid from 'shortid';
import { bindActionCreators } from 'redux';
import { buildMessage } from 'store/features/common/flash/builder';
import * as storage from 'store/features/auth/storage';
import * as flashActions from 'store/features/common/flash/actions';
import jwtDecode from 'jwt-decode';
import Button from 'ui/common/button';
import { slackMethod } from 'resources/slack';
import routeTemplates from 'ui/common/routes/templates';
import messages from './messages';
import { withRouter } from 'react-router-dom';
import routeGenerators from 'ui/common/routes/generators';
import { selectCompanyByPrettyId } from 'store/features/companies/selectors';
import * as authCallbackActionTypes from 'store/graphql/SlackAuthCallback/action-types';
import * as actionTypes from 'store/graphql/SlackAuthButton/action-types';
import * as authActionTypes from 'store/features/auth/action-types';
import {
  selectFetchingStart,
  selectAuthenticatingWithSlack,
  selectSlackAuthCallbackState,
} from 'store/graphql/SlackAuthCallback/selectors';
import {
  selectLoginWithSlack,
  selectError,
  selectSlackAuthButtonState,
} from 'store/graphql/SlackAuthButton/selectors';

import SlackPNG from './slack.png';

import Loading from 'ui/common/loading';
import * as smartRouteActions from 'store/features/smart-route/actions';
import * as libSelectors from 'store/libs/selectors';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import SlackAuthButtonReducer from 'store/graphql/SlackAuthButton/reducer';
import SlackAuthCallbackReducer from 'store/graphql/SlackAuthCallback/reducer';

import styles from './index.module.scss';
import { logEvent } from 'services/amplitude-helper';

class ConnectToSlackButton extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { connectToSlackCodeStart } = this.props;

    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );

    const location = window.location;
    const authData = new URLSearchParams(location.search);
    const code = authData.get('code');
    const state = authData.get('state');

    if (location && location.search && authenticationWithSlack && code && state) {
      // Move this here so that the loading spinner can load immediate.
      connectToSlackCodeStart();
    }
  }

  async ultimateConnectToSlack() {
    const {
      isFetchingStart,
      slackConnectSuccess,
      slackConnectFailure,
      push,
      from,
      prettyId,
    } = this.props;
    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );
    const fromLocation = from;

    if (!isFetchingStart) {
      const location = window.location;
      const authData = new URLSearchParams(location.search);
      const code = authData.get('code');
      const state = authData.get('state');
      const error = authData.get('error');

      if (location && location.search && authenticationWithSlack && code && state && !error) {
        slackConnectSuccess({ code, state });

        this.connectToSlackCode(code, fromLocation, prettyId);
      } else if (error) {
        slackConnectFailure({ error });
        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);

        push(
          routeGenerators.companies.edit({
            prettyId: prettyId,
            section: 'analysis',
          })
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const previousRecaptchaStatus = !!(
      prevProps.recaptchaStatus && prevProps.recaptchaStatus.loaded
    );
    const currentRecaptchaStatus = !!(
      this.props.recaptchaStatus && this.props.recaptchaStatus.loaded
    );
    if (previousRecaptchaStatus !== currentRecaptchaStatus) {
      this.ultimateConnectToSlack();
    }
  }

  async connectToSlackCode(code, fromLocation, prettyId) {
    const {
      client,
      existingValues,
      loginSuccessRequest,
      redirectUri,
      connectToSlackCodeSuccess,
      connectToSlackCodeFailure,
      push,
      setSmartRoute,
    } = this.props;

    try {
      // this.setCaEvent('COMPLETED_INSTALLATION_SLACK');
      // const recaptchaToken = await window.grecaptcha.execute(
      //   process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
      //   { action: "ConnectToslack" }
      // );

      const response = await client.mutate({
        mutation: gql`
          mutation connectToSlack(
            $companyPrettyId: String!
            $code: String!
            $redirectUrl: String!
          ) {
            connectToSlack(
              companyPrettyId: $companyPrettyId
              code: $code
              redirectUrl: $redirectUrl
            ) {
              ok
              token
              company {
                prettyId
              }
            }
          }
        `,
        variables: {
          companyPrettyId: prettyId,
          code: code,
          redirectUrl: redirectUri,
        },
        // context: {
        //   headers: {
        //     "x-recaptcha-token": recaptchaToken
        //   }
        // }
      });

      const { data, errors } = response;
      if (errors && errors[0].message) {
        push(fromLocation, {
          flash: buildMessage({
            id: 'login-with-slack.error',
            kind: 'danger',
            content: errors[0].message,
          }),
        });

        connectToSlackCodeFailure({ error: errors[0].message });
      } else if (data && data.connectToSlack && data.connectToSlack.token) {
        storage.setToken(data.connectToSlack.token);
        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);

        loginSuccessRequest({
          token: data.connectToSlack.token,
          redirect: true,
          from: fromLocation,
          currentPrettyId: data.connectToSlack.company.prettyId,
        });
        connectToSlackCodeSuccess({
          ok: data.connectToSlack.ok,
          token: data.connectToSlack.token,
        });

        if (fromLocation) {
          storage.deleteDataInLocalStorage('slack-from');
        }
      } else if (data && data.connectToSlack && data.connectToSlack.ok === false) {
        const decoded = jwtDecode(data.connectToSlack.token);
        const { name } = decoded;

        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);

        push(routeTemplates.auth.signUp, {
          fromLocation,
          provider: 'slack',
          fieldValues: { ...existingValues, name },
          providerValues: { token: data.connectToSlack.token },
          flash: buildMessage({
            id: 'slack-add-button.additional-fields-required',
            kind: 'info',
            content: messages.additionalFieldsRequired,
          }),
        });
        connectToSlackCodeFailure({
          error: messages.additionalFieldsRequired,
        });

        setSmartRoute({
          isEmailVerify: true,
        });

        if (fromLocation) {
          setSmartRoute({
            routeAfterEmailVerify: fromLocation,
          });

          storage.deleteDataInLocalStorage('slack-from');
        }
      }
    } catch (error) {
      connectToSlackCodeFailure({ error });
    }
  }

  setCaEvent = async caEvent => {
    const { client, company } = this.props;
    try {
      const resultFromGraphQl = await client.mutate({
        mutation: gql`
          mutation ems_set_caEvent(
            $companyId: String!
            $type: EmsCAEventEnum!
            $status: EmsCAEventStatusEnum!
          ) {
            ems_set_caEvent(companyId: $companyId, type: $type, status: $status) {
              type
              status
            }
          }
        `,
        variables: {
          companyId: company && company.id,
          type: caEvent,
          status: 'DONE',
        },
      });
      // console.log('logged CA event : ', caEvent);
      if (resultFromGraphQl.error) {
        console.log(resultFromGraphQl.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  async slackAuthCallback() {
    const { slackConnectStart, slackConnectFailure, redirectUri, prettyId, from } = this.props;
    this.setCaEvent('INITIATED_INSTALLATION_SLACK');
    slackConnectStart();

    if (from === 'setupWizard') {
      logEvent('SETUPWIZARD_step2_connecttoslackbutton_clicked');
    }

    logEvent('connect_to_slack');
    try {
      const urlParams = new URLSearchParams();
      const requestState = shortid.generate();

      urlParams.set('client_id', process.env.REACT_APP_SLACK_CLIENT_ID);
      urlParams.set('redirect_uri', redirectUri);
      urlParams.set(
        'scope',
        'channels:read,chat:write,commands,users.profile:read,users:read,users:read.email,team:read,im:history'
      );
      urlParams.set('state', requestState);
      urlParams.set('prettyId', prettyId);
      urlParams.set('allow_signup', 'true');
      window.location = `${process.env.REACT_APP_SLACK_API_URL}?${urlParams}`;

      storage.setDataInLocalStorage(slackMethod.authenticationWithSlack, true);
    } catch (apiError) {
      flashActions.addMessage({
        id: 'slack.add.status',
        kind: 'danger',
        content: apiError,
      });
      slackConnectFailure({ error: apiError });
    }
  }

  render() {
    const { isLoginWithSlack, isAuthenticatingWithSlack, isFetchingStart, fromBanner } = this.props;
    if (isLoginWithSlack) {
      return <Loading />;
    }

    return (
      <Button
        className={classnames(
          !!fromBanner ? styles.slackBtnSmall : styles.slackBtn,
          'd-flex',
          !fromBanner && 'px-5'
        )}
        style={{
          backgroundColor: '#fff',
          color: '#000',
          border: '2px solid black',
        }}
        disabled={isAuthenticatingWithSlack}
        onClick={this.slackAuthCallback}
        loading={isAuthenticatingWithSlack || isFetchingStart || isLoginWithSlack}
      >
        <img src={SlackPNG} width={!!fromBanner ? '16' : '22'} alt="slack" />
        <span className={classnames(styles.btnText, 'ml-2 font-weight-bold')}>
          <FormattedMessage id="auth.connect-to-slack-button" defaultMessage={`Connect to Slack`} />
        </span>
      </Button>
    );
  }
}

function mapStateToProps(state, props) {
  const urlParams = props.match && props.match.params;
  const prettyId = urlParams.prettyId;
  const from = props.from
    ? props.from
    : (state && state.router && state.router.location && state.router.location.pathname) || '';
  const redirectUri = `${window.location.origin}/slack-connect/${prettyId}?from=${from}`;
  // const redirectUri = `https://ecs-integration-frontend.indorse.io/slack-connect/${prettyId}?from=${from}`;
  const slackAuthButtonState = selectSlackAuthButtonState(state);
  const slackAuthCallback = selectSlackAuthCallbackState(state);
  const isFetchingStart = slackAuthCallback && selectFetchingStart(state);
  const isAuthenticatingWithSlack = slackAuthCallback && selectAuthenticatingWithSlack(state);
  const isLoginWithSlack = slackAuthButtonState && selectLoginWithSlack(state);
  const recaptchaStatus = libSelectors.selectRecaptchaSdkLibLoadState(state);
  const company = selectCompanyByPrettyId(state, { prettyId });
  return {
    errorMessage: slackAuthButtonState && selectError(state),
    redirectUri,
    isFetchingStart,
    isLoginWithSlack,
    isAuthenticatingWithSlack,
    recaptchaStatus,
    from,
    prettyId,
    company,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    slackConnectStart: () => dispatch({ type: authCallbackActionTypes.SLACK_AUTH.START }),
    slackConnectSuccess: ({ code, state }) =>
      dispatch({
        type: authCallbackActionTypes.SLACK_AUTH.SUCCESS,
        payload: { code, state },
      }),
    slackConnectFailure: error =>
      dispatch({
        type: authCallbackActionTypes.SLACK_AUTH.FAILURE,
        payload: { error },
      }),

    connectToSlackCodeStart: () => dispatch({ type: actionTypes.LOGIN_WITH_SLACK_CODE.START }),
    connectToSlackCodeSuccess: ({ ok, token }) =>
      dispatch({
        type: actionTypes.LOGIN_WITH_SLACK_CODE.SUCCESS,
        payload: { ok, token },
      }),
    connectToSlackCodeFailure: ({ error }) =>
      dispatch({
        type: actionTypes.LOGIN_WITH_SLACK_CODE.FAILURE,
        payload: { error },
      }),
    loginSuccessRequest: ({ token, redirect, from, currentPrettyId }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
        currentPrettyId,
      }),
    push: (routeName, locationState) => dispatch(push(routeName, locationState)),
    setSmartRoute: bindActionCreators(smartRouteActions.setSmartRoute, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSlackAuthButtonReducer = injectReducer({
  key: 'SlackAuthButton',
  reducer: SlackAuthButtonReducer,
});
const withSlackAuthCallbackReducer = injectReducer({
  key: 'SlackAuthCallback',
  reducer: SlackAuthCallbackReducer,
});

export default withRouter(
  compose(withSlackAuthButtonReducer, withSlackAuthCallbackReducer, withConnect)(
    injectIntl(withApollo(ConnectToSlackButton))
  )
);
