import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import Slider from 'react-slick';
import { show as showModal } from 'redux-modal';
import { bindActionCreators } from 'redux';

import themeConfig from 'ui/theme/config';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { templateList } from 'resources/page/ems/metamorph/templateList';
// import { logEvent } from 'services/amplitude-helper';
import ExpandedModal, { MODAL_NAME as EXPANDED_MODAL } from './expanded-modal';

class Templates extends Component {
  constructor() {
    super();
    this.state = { isHovering: [] };
  }

  handleExpandClicked = index => {
    const { showModal } = this.props;

    showModal(EXPANDED_MODAL, { template: templateList[index] });
  };

  renderOneTemplate({ imgUrl, name, desc }, index) {
    return (
      <div className={classnames(styles.templateCard)}>
        <div className="d-flex justify-content-center">
          <img src={imgUrl} alt={name} className={classnames(styles.templateImg)} />
        </div>
        <div className={classnames(styles.templateName)}>{name}</div>
        {desc && <div className={classnames(styles.templateDesc)}>{desc}</div>}
        <div
          className={classnames(styles.expandBtn)}
          onClick={() => this.handleExpandClicked(index)}
        >
          <i className={classnames('fas fa-expand-arrows-alt', styles.expandIcon)} />
        </div>
      </div>
    );
  }

  renderDesktop() {
    const sliderDesktopSettings = {
      infinite: true,
      speed: 3000,
      slidesToShow: 2.7,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      dots: false,
      isResponsive: true,
      autoplaySpeed: 3500,
      cssEase: 'ease',
      pauseOnHover: true,
    };
    return (
      <div className="position-relative">
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold mt-4 mb-3 pt-3')}>
          Templates for Engineering Leaders
        </h1>
        <h3 className={classnames(styles.mainSubtitle)}>
          Get started with our standardized templates and customize them to focus on your KPIs and
          OKRs.
        </h3>

        <Slider {...sliderDesktopSettings} className={classnames(styles.customSlider, 'mt-4')}>
          {templateList.map((template, index) => (
            <div className="px-5 py-5" key={index}>
              {this.renderOneTemplate(template, index)}
            </div>
          ))}
        </Slider>

        {/* <div className="row justify-content-center mt-4 col-md-12 mx-auto">
          <button
            className={classnames(styles.getStartedBtn)}
            isReady
            onClick={() => {
              logEvent('LP_root_getstarted_clicked', {
                label: 'Get Started',
                source: window && window.location.pathname,
              });
            }}
          >
            Explore All Templates
          </button>
        </div> */}
      </div>
    );
  }

  renderMobile() {
    const sliderMobileSettings = {
      centerMode: true,
      swipeToSlide: true,
      infinite: true,
      lazyLoad: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      isResponsive: true,
      autoplaySpeed: 4500,
      speed: 1500,
    };
    return (
      <div>
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold mb-3')}>
          Templates for Engineering Leaders
        </h1>
        <h3 className={classnames(styles.mainSubtitle, 'mb-3')}>
          Get started with our standardized templates and customize them to focus on your KPIs and
          OKRs.
        </h3>
        <Slider {...sliderMobileSettings}>
          {templateList.map((template, index) => (
            <div className="px-3" key={index}>
              {this.renderOneTemplate(template, index)}
            </div>
          ))}
        </Slider>

        {/* <div className="row justify-content-center mt-4 col-md-12 mx-auto">
          <button
            className={classnames(styles.getStartedBtn, 'mb-4 mt-3')}
            isReady
            // className={classnames(styles.submitBtn, 'mx-3 px-5')}
            onClick={() => {
              logEvent('LP_root_getstarted_clicked', {
                label: 'Get Started',
                source: window && window.location.pathname,
              });
            }}
          >
            Explore All Templates
          </button>
        </div> */}
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.blueBackground)}>
        <div className={classnames('page-max-width pt-4 pb-3')}>
          <div className={classnames('container-fluid my-5')}>
            <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
              {this.renderDesktop()}
            </MediaQuery>
          </div>
          <div className={classnames('container-fluid')}>
            <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
              {this.renderMobile()}
            </MediaQuery>
          </div>
        </div>
        <ExpandedModal />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Templates));
