import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styles from './index.module.scss';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import { withRouter } from 'react-router-dom';

export class Integrations extends Component {
  renderDesktop() {
    return (
      <div>
        <h2 className={classnames('text-center mb-3', styles.title)}>
          One tool to connect them all
        </h2>
        <h2 className={classnames('text-center', styles.subtitle)}>
          Gather and aggregate data from all the tools used by your
          <br /> software engineers on a daily basis.
        </h2>
        <div className={classnames(styles.indorseCircle)}>
          <img
            src={'https://indorse-staging-bucket.s3.amazonaws.com/indorse-circle.png'}
            alt="indorse-circle"
            width="100%"
          />
        </div>
        <div className={classnames(styles.cardsContainer)}>
          <div className={classnames(styles.card)}>
            <div className={classnames(styles.cardTitle)}>Version Control</div>
            <div className={classnames(styles.cardSubtitle)}>
              Receive all necessary codebase metrics like PRs, commit frequency.
            </div>
            <div className={classnames(styles.logos)}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/github-icon.svg'}
                alt="github"
                width="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/gitlab-icon.svg'}
                alt="gitlab"
                width="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/bitbucket-icon.svg'}
                alt="bitbucket"
                width="40"
              />
            </div>
          </div>
          <div className={classnames(styles.card)} style={{ justifySelf: 'end' }}>
            <div className={classnames(styles.cardTitle)}>DevOps</div>
            <div className={classnames(styles.cardSubtitle)}>
              Keep track of build pipelines and cover the entire application lifecycle.
            </div>
            <div className={classnames(styles.logos)}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/circleci-icon.svg'}
                alt="circleci"
                width="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/jenkins-icon.svg'}
                alt="jenkins"
                width="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/azure-icon.svg'}
                alt="azure"
                width="40"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/travis-CI_logo.svg'}
                alt="travisci"
                width="40"
                className="mr-3"
              />
            </div>
          </div>
        </div>
        <div className={classnames(styles.issueManagement)}>
          <div className={classnames(styles.card)}>
            <div className={classnames(styles.cardTitle)}>Issue Management</div>
            <div className={classnames(styles.cardSubtitle)}>
              Extract all relevant data for your features like backlog health, issue resolution,
              sprint velocity.
            </div>
            <div className={classnames(styles.logos)}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/jira-icon.svg'}
                alt="jira"
                width="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/jira-3.svg'}
                alt="atlassian"
                width="40"
                height="40"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    // const { handleSubmit, intl: { formatMessage }, form: formName } = this.props;
    return (
      <div className="mt-5 pt-5">
        <h2 className={classnames('text-center', styles.title)}>One tool to connect them all</h2>
        <h2 className={classnames('text-center', styles.subtitle)}>
          Gather and aggregate data from all the tools used by your software engineers on a daily
          basis.
        </h2>
        <div className={classnames(styles.cardsContainer)}>
          <div className={classnames(styles.card)}>
            <div className={classnames(styles.cardTitle)}>Version Control</div>
            <div className={classnames(styles.cardSubtitle)}>
              Receive all necessary codebase metrics like PRs, commit frequency.
            </div>
            <div className={classnames(styles.logos)}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/github-icon.svg'}
                alt="github"
                width="40"
                height="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/gitlab-icon.svg'}
                alt="gitlab"
                width="40"
                height="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/bitbucket-icon.svg'}
                alt="bitbucket"
                width="40"
                height="40"
              />
            </div>
          </div>
          <div className={classnames(styles.card)}>
            <div className={classnames(styles.cardTitle)}>DevOps</div>
            <div className={classnames(styles.cardSubtitle)}>
              Keep track of build pipelines and cover the entire application lifecycle.
            </div>
            <div className={classnames(styles.logos)}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/circleci-icon.svg'}
                alt="circleci"
                width="40"
                height="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/jenkins-icon.svg'}
                alt="jenkins"
                width="40"
                height="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/azure-icon.svg'}
                alt="azure"
                width="40"
                height="40"
              />
            </div>
          </div>
          <div className={classnames(styles.card)}>
            <div className={classnames(styles.cardTitle)}>Issue Management</div>
            <div className={classnames(styles.cardSubtitle)}>
              Extract all relevant data for your features like backlog health, issue resolution,
              sprint velocity.
            </div>
            <div className={classnames(styles.logos)}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/jira-icon.svg'}
                alt="jira"
                width="40"
                height="40"
                className="mr-3"
              />
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/jira-3.svg'}
                alt="atlassian"
                width="40"
                height="40"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.main)}>
        <div className={classnames('page-max-width')}>
          <div className={classnames('container-fluid')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(Integrations));
