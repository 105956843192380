export const data = {
  featureList: [
    'Free Trial',
    'Number of admins',
    'JIRA Boards',
    'Code Repos',
    'Slack App',
    'On-premise | Docker',
    'Customized Metrics',
    'Customized Features',
    'Customized Reports',
  ],
  growthFeatureList: [
    '30 Days Free Trial',
    '5 Admins',
    '1 JIRA Board',
    '2 Code Repos',
    'Slack App (coming soon)',
  ],
  premiumFeatureList: [
    '30 Days Free Trial',
    'Unlimited Number of Admins',
    '4 JIRA Board',
    '10 Code Repos',
    'Slack App (coming soon)',
  ],
  enterpriseFeatureList: [
    'Unlimited Number of Admins',
    'Unlimited JIRA Boards',
    'Unlimited Code Repos',
    'Slack App (coming soon)',
    'On-premise | Docker',
    'Customized Metrics',
    'Customized Features',
    'Customized Reports',
  ],
  standard: {
    price: '2',
    isPromo: true,
    promoPrice: '1',
    name: 'Standard',
    color: '#2A2498',
    features: [true, true, 'Coming Soon', true, true, true, 'Priority', false, false],
  },
  enterprise: {
    price: '2',
    isPromo: true,
    promoPrice: '1',
    name: 'Standard',
    color: '#000000',
    features: ['-', 'Unlimited', 'Unlimited', 'Unlimited', 'Coming Soon', true, true, true, true],
  },
  growth: {
    price: '2',
    isPromo: true,
    promoPrice: '1',
    name: 'Standard',
    color: '#000000',
    features: ['30 Days', '5', '1', '2', 'Coming Soon', false, false, false, false],
  },
  premium: {
    price: '2',
    isPromo: true,
    promoPrice: '1',
    name: 'Premium',
    color: '#2A2498',
    features: ['30 Days', 'Unlimited', '4', '10', 'Coming Soon', false, false, false, false],
  },
};
