import React from 'react';
import classnames from 'classnames';
import commonStyles from './index.module.scss';
import { withRouter } from 'react-router-dom';
import Hero from './hero';
import SecondaryCTA from './cta';
import Usage from './usage';
import Features from './features';
import HowTo from './how-to';
import { connect } from 'react-redux';
import ExistingCustomers from 'ui/pages/home-V2/existing-customers/loadable';
import routeTemplates from 'ui/common/routes/templates';
import { logEvent, logUserProperties } from 'services/amplitude-helper';

import { show as showModal } from 'redux-modal';
import MetamorphHelmet from 'ui/pages/ems-indorse/metamorph-helmet';

class EMS extends React.Component {
  componentDidMount() {
    if (this.props.match.path === '/ext') this.props.history.push(routeTemplates.emsExtLandingPage);
    const query = new URLSearchParams(this.props.location.search);
    const utm_source = query.get('utm_source');
    const utm_content = query.get('utm_content');

    const ampIns = window.amplitude.getInstance();

    if (window.localStorage.getItem('email')) {
      ampIns.setUserId(window.localStorage.getItem('email'));
    } else if (!window.localStorage.getItem('deviceId')) {
      // create random deviceId
      const randomDeviceId = Math.random()
        .toString(36)
        .substr(2, 9);
      window.localStorage.setItem('deviceId', randomDeviceId);
      ampIns.setDeviceId(randomDeviceId);
      if (utm_source) window.localStorage.setItem('utm_source', utm_source);
    } else {
      ampIns.setDeviceId(window.localStorage.getItem('deviceId'));
    }

    // if we have utm_source
    if (utm_source) {
      logUserProperties({ utm_source });
    }

    // if we have utm_content
    if (utm_content) {
      logUserProperties({ utm_content });
    }
    logEvent('landed_on_ems_ext', {
      source: window && window.location.pathname,
    });
  }
  render() {
    return (
      <div className={classnames(commonStyles.home)}>
        <MetamorphHelmet />
        <Hero />
        <div
          style={{ backgroundColor: '#EFF3FE' }}
          className="pt-4 pt-xl-5 pb-xl-3 pt-lg-5 pb-lg-3 py-md-5 py-sm-5"
        >
          <h2 className={classnames(commonStyles.trust, 'm-0')}>The best teams trust us</h2>
          <ExistingCustomers autoplay={false} dots={false} />
        </div>
        <HowTo />
        <Features />
        <Usage />
        <SecondaryCTA />
      </div>
    );
  }
}

const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(withRouter(EMS));
