import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import themeConfig from 'ui/theme/config';
import { show as showModal } from 'redux-modal';
import styles from './index.module.scss';
import Button from 'ui/common/button';
import { logEvent } from 'services/amplitude-helper';
import gql from 'graphql-tag';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import { messageTypes } from 'store/features/common/flash/builder';
import { Modal, ModalBody } from 'ui/common/modal';
import routeTemplates from 'ui/common/routes/templates';
// import Toggle from 'ui/common/rebranding/components/Toggle';

import { reduxForm } from 'redux-form';
import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';
import validator from 'ui/common/form/validator';
import fields from './model';
import * as messages from './messages';
import { tableRowData } from 'resources/page/ems-pricing';
import * as flashActions from 'store/features/common/flash/actions';
import LoggedInUserGetStartedModal from '../../ems/hero/loggedin-user-get-started';
import ErrorModal, { MODAL_NAME as ERROR_MODAL } from '../../ems/hero/error-modal';
import * as storage from '../../../../store/features/auth/storage';
import routeGenerators from '../../../common/routes/generators';
import VerifyEmailModal, {
  MODAL_NAME as VERIFY_EMAIL_MODAL,
} from '../../ems/hero/verify-email-modal';
import LoginUserModal, {
  MODAL_NAME as LOGIN_LOGIN_USER_MODAL,
} from '../../ems/hero/login-user-modal';
import UserEmployeeModal, {
  MODAL_NAME as USER_EMPLOY_MODAL,
} from '../../ems/hero/user-employee-modal';
import CompanyExistsEmailSentModal, {
  MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL,
} from '../../ems/hero/ems-company-exists-for-domain-modal';
import CompanyExistsEmailSentModalLoggedIn, {
  MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN,
} from '../../ems/hero/ems-company-exists-for-domain-modal-loggedin';
import * as authActionTypes from '../../../../store/features/auth/action-types';
import VerifyEmailModalMobile from '../../ems/hero/verify-email-modal-mobile';
import LoginUserModalMobile from '../../ems/hero/login-user-modal-mobile';
import UserEmployeeModalMobile from '../../ems/hero/user-employee-modal-mobile';
import LoggedInUserGetStartedModalMobile from '../../ems/hero/loggedin-user-get-started-mobile';
import ErrorModalMobile from '../../ems/hero/error-modal-mobile';
import CompanyExistsEmailSentModalMobile from '../../ems/hero/ems-company-exists-for-domain-modal-mobile';
import CompanyExistsEmailSentModalLoggedInMobile from '../../ems/hero/ems-company-exists-for-domain-modal-loggedin-mobile';
import GetStartedModalMobile, {
  MODAL_NAME as GET_STARTED_MODAL_MOBILE,
} from 'ui/pages/ems/hero/get-started-modal-mobile';
import GetStartedModal, {
  MODAL_NAME as GET_STARTED_MODAL,
} from 'ui/pages/ems/hero/get-started-modal';
import { data } from './data';
import BillingMethodSwitch from 'ui/metamorph/edit/billing-details/BillingMethodSwitch';
// import WaitlistModal, {
//   MODAL_NAME as EMS_WAITLIST_MODAL,
// } from 'ui/pages/ems/hero/waitlist-modal.js';

const validate = validator(fields);
const FORM_NAME = 'ems-pricing-form-beta';

const CLIENT_LOGO_IMAGES = [
  {
    imgUrl:
      'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/home-credit.png',
  },

  {
    imgUrl: 'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/grab.svg',
  },
  {
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/static-images/existing-customers/capita-sg.png',
  },
  {
    imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/Adobe-Logo.svg',
  },
];

class PricingTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      growthMonthly: '',
      growthAnnually: '',
      premiumMonthly: '',
      premiumAnnually: '',
      isToggled: true,
      isCompanyCreated: false,
    };
  }

  async componentDidMount() {
    const { client, currentUser } = this.props;

    window.fcWidget &&
      window.fcWidget.user.setProperties({
        firstName: currentUser && currentUser.name,
        email: currentUser && currentUser.email,
      });

    // signUpGrahpqlStart();

    try {
      const response = await client.query({
        query: gql`
          query getEMSPlans {
            getEMSPlans {
              planType
              subscriptionType
              price {
                currency
                amountInCents
              }
            }
          }
        `,
      });

      const { data, errors } = response;

      if (errors && errors[0].message) {
        flashActions.addMessage({
          id: 'ems-plans.error',
          kind: 'danger',
          content: errors[0].message,
        });
      } else if (data && data.getEMSPlans) {
        const growthMonthlyObj = data.getEMSPlans.find(
          plan => plan.planType === 'GROWTH' && plan.subscriptionType === 'MONTHLY'
        );
        const growthAnnuallyObj = data.getEMSPlans.find(
          plan => plan.planType === 'GROWTH' && plan.subscriptionType === 'YEARLY'
        );
        const premiumMonthlyObj = data.getEMSPlans.find(
          plan => plan.planType === 'PREMIUM' && plan.subscriptionType === 'MONTHLY'
        );
        const premiumAnnuallyObj = data.getEMSPlans.find(
          plan => plan.planType === 'PREMIUM' && plan.subscriptionType === 'YEARLY'
        );
        this.setState({
          growthMonthly: growthMonthlyObj.price.amountInCents / 100,
          growthAnnually: growthAnnuallyObj.price.amountInCents / (100 * 12),
          premiumMonthly: premiumMonthlyObj.price.amountInCents / 100,
          premiumAnnually: premiumAnnuallyObj.price.amountInCents / (100 * 12),
        });
      }
    } catch (error) {
      flashActions.addMessage({
        id: 'ems-plans-get.error',
        kind: 'danger',
        content: error.message,
      });
    }
  }

  async onSubmit(values) {
    const { email } = values;
    const {
      client,
      addMessage,
      intl: { formatMessage },
      showModal,
      loginSuccessRequest,
    } = this.props;

    logEvent('create_ems_company_pricing_clicked', {
      label: formatMessage(messages.buttons.submitButton),
    });

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_create_company($email: EmailAddress) {
            ems_create_company(email: $email) {
              status
              error
              token
              company {
                companyId
                companyPrettyId
                createdAt
              }
              emailDomainCompanies {
                acl {
                  email
                }
              }
            }
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { data, errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        logEvent('create_ems_pricing_company_failed', {
          error: errorsToSingleMessage({ errors }),
        });
      } else {
        // const { createCompany: { id } } = data;
        // if (id) {
        //   this.setState({
        //     isCompanyCreated: true,
        //   });
        // }
        // disable exit intent
        localStorage.setItem('popup', 'false');

        if (window.amplitude) {
          const ampInstance = window.amplitude.getInstance();
          window.localStorage.setItem('email', email);
          if (ampInstance) ampInstance.setUserId(email.toLowerCase());
        }
        logEvent('create_ems_pricing_company_success', {
          label: formatMessage(messages.buttons.submitButton),
        });
        const { ems_create_company } = data;
        const error = ems_create_company && ems_create_company.error;
        if (!!error) {
          showModal(ERROR_MODAL, {});
        } else {
          const { status, token, company, emailDomainCompanies } = ems_create_company;

          if (status === 'ACCOUNT_CREATED') {
            const { companyPrettyId } = company;
            storage.setToken(token);
            loginSuccessRequest({
              token,
              redirect: true,
              from: routeGenerators.metamorph.edit({
                prettyId: companyPrettyId,
                section: 'dashboard',
              }),
            });
          } else if (status === 'ACCOUNT_UPSERTED') {
            const { companyPrettyId } = company;
            this.props.history.push(
              routeGenerators.metamorph.edit({
                prettyId: companyPrettyId,
                section: 'dashboard',
              })
            );
          } else if (
            status === 'ACCOUNT_EXISTS_UNVERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_UNVERIFIED'
          ) {
            // Change here to show popup
            showModal(VERIFY_EMAIL_MODAL, { email, onSubmit: () => null });
          } else if (
            status === 'ACCOUNT_EXISTS_VERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_VERIFIED'
          ) {
            // Change here to show popup
            showModal(LOGIN_LOGIN_USER_MODAL, { email, onSubmit: () => null });
          } else if (status === 'USER_IS_EMPLOYEE') {
            // Change here to show popup
            showModal(USER_EMPLOY_MODAL, { email, onSubmit: () => null });
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_EMAIL_SENT') {
            // Change here to show popup
            showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL, { onSubmit: () => null });
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_LOGGED_IN') {
            try {
              const email =
                Array.isArray(emailDomainCompanies) && emailDomainCompanies.length > 0
                  ? emailDomainCompanies[0].acl[0].email
                  : null;
              showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN, { email });
            } catch (err) {
              showModal(ERROR_MODAL, {});
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
      logEvent('create_company_failed', {
        error: error.message,
        label: formatMessage(messages.buttons.submitButton),
      });
    }
  }

  async addToWaitlist(values) {
    const { email } = values;
    const { client, addMessage, intl: { formatMessage } } = this.props;

    logEvent('create_ems_company_pricing_waitlist_clicked', {
      label: formatMessage(messages.buttons.submitButton),
    });

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_waitlist($email: String!) {
            ems_waitlist(email: $email)
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
      } else {
        // this.props.showModal(EMS_WAITLIST_MODAL);
      }
    } catch (error) {
      console.log(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
    }
  }

  renderCompanyCreatedThanksModal() {
    const { intl: { formatMessage } } = this.props;
    const { isCompanyCreated } = this.state;

    return (
      <Modal isOpen={isCompanyCreated} className="modal-lg">
        <ModalBody className="col-md-10 mt-5 offset-sm-1">
          <div className="pb-3 text-center">
            <h1
              className="text-center mx-3 indorse-blue mb-5"
              style={{ fontSize: '60px', fontWeight: '900' }}
            >
              {formatMessage(messages.content.pageTitle)}
            </h1>
            <div className="mt-4 text-center">
              <ul style={{ maxWidth: '32em' }} className="m-auto">
                <li className="ml-2">
                  <h3 style={{ fontSize: '20px', lineHeight: '30px' }} className="text-left">
                    To verify your email, please check your inbox.
                  </h3>
                </li>
                <li className="ml-2">
                  <h3 style={{ fontSize: '20px', lineHeight: '30px' }} className="text-left">
                    Do not forget to check your spam folder and whitelist our email -
                    info@indorse.io
                  </h3>
                </li>
              </ul>
            </div>
          </div>
          <hr className={classnames(styles.divider)} />
          <div className="my-5 text-center">
            <h3 style={{ fontSize: '20px' }}>{formatMessage(messages.content.pageCompanies)}</h3>
            <div className="d-flex justify-content-between align-items-center py-4 col-md-10 offset-sm-1">
              <img src={CLIENT_LOGO_IMAGES[0].imgUrl} alt="home credit" style={{ width: '20%' }} />
              <img src={CLIENT_LOGO_IMAGES[1].imgUrl} alt="grab" style={{ width: '20%' }} />
              <img
                className="mt-2"
                src={CLIENT_LOGO_IMAGES[2].imgUrl}
                alt="capita"
                style={{ width: '20%', height: '60%' }}
              />
              <img
                className="mt-2"
                src={CLIENT_LOGO_IMAGES[3].imgUrl}
                alt="singsaver"
                style={{ width: '15%', height: '60%' }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderIfYesOrNo(value) {
    if (value === 'No')
      return <i style={{ color: '#ED6630', fontSize: '20px' }} className="fa fa-times-circle" />;
    if (value === 'Yes')
      return <i style={{ color: '#00B1A4', fontSize: '20px' }} className="fa fa-check-circle" />;

    if (value.count) {
      return (
        <div>
          {value.count}
          <br />{' '}
          {value.contactSales && (
            <p
              className={classnames(styles.contactSales)}
              onMouseOver={() => window.fcWidget && window.fcWidget.setTags(null)}
              onClick={() => {
                window.fcWidget &&
                  window.fcWidget.open({
                    replyText:
                      'Hi there, I would like some assistance with the pricing plans for Metamorph!',
                  });
              }}
            >
              Need more than {value.count.replace(/\D/g, '')}? <br /> Contact sales
            </p>
          )}
        </div>
      );
    }

    return value;
  }

  getGrowthPrice() {
    const { isToggled, growthMonthly, growthAnnually } = this.state;
    return isToggled ? growthAnnually : growthMonthly;
  }

  getPremiumPrice() {
    const { isToggled, premiumAnnually, premiumMonthly } = this.state;
    return isToggled ? premiumAnnually : premiumMonthly;
  }

  handleRedirect(planType, isAnnually) {
    logEvent('ems_pricing_plan_clicked', {
      planType,
      isAnnually,
    });
    const params = new URLSearchParams(this.props.location.search);
    params.set('plan', planType);
    params.set('isAnnually', isAnnually);
    this.props.history.push(`${routeTemplates.emsPricingWizard}?${params}`);
  }

  renderRows() {
    return Object.keys(tableRowData).map(key => (
      <tr key={key}>
        <td>{key}</td>
        {tableRowData[key].map((value, index) => (
          <td key={index}>{this.renderIfYesOrNo(value)}</td>
        ))}
      </tr>
    ));
  }

  getAvailableFeatureStatusMobile(index, type) {
    switch (type) {
      case 'Premium':
        if (!data.premium.features[index]) return styles.lineThrough;
        else return;
      case 'Growth':
        if (!data.growth.features[index]) return styles.lineThrough;
        else return;
      default:
        return;
    }
  }

  renderMobile() {
    const { intl: { formatMessage }, showModal } = this.props;
    const SLACK_APP = 'Slack App (coming soon)';
    return (
      <div className={classnames('container pt-4')}>
        <div className="row justify-content-center position-relative">
          <div className={classnames('col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-md-5')}>
            <h1 className={classnames(styles.metamorphPricingHeading, 'mt-5 text-center')}>
              Metamorph by Indorse
            </h1>
            <h1 className={classnames(styles.pricingTableTitle, 'mt-3 text-center px-4')}>
              <span className={classnames(styles.bold)}>Actionable analytics</span> for engineering
              leaders
            </h1>
            <div className="px-1">
              <div className={classnames('d-flex align-items-center')}>
                <h3 className={classnames(styles.pricingTableSubTitle)}>
                  {formatMessage(messages.pricingTable.subTitleOne)}
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={'d-flex justify-content-center align-items-center mt-3'}>
          <div>Monthly</div>
          <div>
            <Toggle
              onToggle={() => this.setState({ isToggled: !this.state.isToggled })}
              className="mx-3"
              id={'plan-toggle'}
              toggled={this.state.isToggled}
            />
          </div>
          <div>Annually</div>
        </div> */}
        <BillingMethodSwitch
          onToggle={() => this.setState({ isToggled: !this.state.isToggled })}
          id={'plan-toggle'}
          className={classnames(styles.toggleSwitch, 'mt-4')}
          isActive={this.state.isToggled}
        />
        <div className={classnames('col-12 mx-auto', styles.pricingTableMobile)}>
          <div className={'col-11 px-0 mx-auto mt-3'}>
            <div className={styles.growthBoxMobile}>
              <div className={styles.growthPriceCellMobile}>
                <div className={'d-flex justify-content-center align-items-top pt-4'}>
                  <div className={'d-flex align-items-top'}>
                    <span className={styles.superScript}>$</span>
                    <span className={styles.priceText}>{this.getGrowthPrice()}</span>
                  </div>
                </div>
                <div className={classnames(styles.perMonthBlack, 'my-2 text-center')}>/ month</div>
                <h2 className={classnames(styles.growthTitle, 'mb-4')}>Growth</h2>
                <Button
                  color={'border-black'}
                  className={classnames(styles.pricingTableButtonContactSales, 'px-4 py-2 mt-1')}
                  onMouseOver={() => window.fcWidget && window.fcWidget.setTags(['growth'])}
                  onClick={() => {
                    logEvent('LP_root_getstarted_clicked', {
                      label: 'Start Free Trial - Growth',
                      source: window && window.location.pathname,
                    });
                    showModal(GET_STARTED_MODAL_MOBILE, {});
                  }}
                >
                  Start Free Trial
                </Button>
                <div className={classnames(styles.noCard)}>(No card required)</div>
              </div>
              {data.growthFeatureList.map((item, idx) => (
                <div
                  className={classnames(
                    styles.standardCell,
                    styles.standardCellMobile,
                    styles.textCenter,
                    styles.blackText
                  )}
                  key={idx}
                >
                  <h3
                    className={classnames(
                      'm-0',
                      item === SLACK_APP ? 'text-muted' : '',
                      this.getAvailableFeatureStatusMobile(idx, 'Growth')
                    )}
                  >
                    {item}
                  </h3>
                </div>
              ))}
            </div>
          </div>
          <div className={'col-11 px-0 mx-auto mt-5'}>
            <div
              style={{
                border: '3px solid #2a2498',
                borderRadius: '5px',
                backgroundColor: '#ffffff',
                boxShadow: '0 0 30px 0 rgba(0,82,204,0.15)',
                paddingBottom: '2em',
              }}
            >
              <div className={classnames(styles.standardPriceCell, styles.standardPriceCellMobile)}>
                <div className={'d-flex justify-content-center align-items-top pt-4'}>
                  <div className={'d-flex align-items-top pt-3'}>
                    <span className={styles.superScript}>$</span>
                    <span className={styles.priceText}>{this.getPremiumPrice()}</span>
                  </div>
                </div>
                <div className={classnames(styles.perMonth, 'text-center')}>/ month</div>
                <h2 className={classnames(styles.bold, 'mt-3 mb-4 text-center')}>
                  {data.premium.name}
                </h2>
                {this.renderGetStarted(true)}
                <div className={classnames(styles.noCard)}>(No card required)</div>
              </div>
              {data.premiumFeatureList.map((item, idx) => (
                <div
                  className={classnames(
                    styles.standardCell,
                    styles.standardCellMobile,
                    styles.textCenter
                  )}
                  key={idx}
                >
                  <h3
                    className={classnames(
                      'm-0',
                      item === SLACK_APP ? 'text-muted' : '',
                      this.getAvailableFeatureStatusMobile(idx, 'Premium')
                    )}
                  >
                    {item}
                  </h3>
                </div>
              ))}
            </div>
          </div>
          <div className={'col-11 px-0 mx-auto mt-5'}>
            <div className={styles.enterpriseBoxMobile}>
              <div className={styles.enterprisePriceCellMobile}>
                <h2 className={classnames(styles.enterpriseTitle, 'mb-4')}>Enterprise</h2>
                <Button
                  color={'border-black'}
                  className={classnames(styles.pricingTableButtonContactSales, 'px-4 py-2 mt-1')}
                  onMouseOver={() => window.fcWidget && window.fcWidget.setTags(['enterprise'])}
                  onClick={() => {
                    window.fcWidget && window.fcWidget.open({});
                    logEvent('LP_root_getstarted_clicked', {
                      label: 'Contact Sales',
                      source: window && window.location.pathname,
                    });
                  }}
                >
                  Contact Sales
                </Button>
              </div>
              {data.enterpriseFeatureList.map((item, idx) => (
                <div
                  className={classnames(
                    styles.standardCell,
                    styles.standardCellMobile,
                    styles.textCenter,
                    styles.blackText
                  )}
                  key={idx}
                >
                  <h3 className={classnames('m-0', item === SLACK_APP ? 'text-muted' : '')}>
                    {item}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGetStarted(isMobile = false) {
    const { showModal } = this.props;
    return (
      <div className={'text-center mt-4 pt-2'}>
        <Button
          color={'company-dark-blue'}
          textCenter={true}
          className={classnames(styles.pricingTableButtonGetStarted, 'px-4 py-2')}
          onClick={() => {
            logEvent('LP_root_getstarted_clicked', {
              label: 'Get Started - Premium',
              source: window && window.location.pathname,
            });
            showModal(!!isMobile ? GET_STARTED_MODAL_MOBILE : GET_STARTED_MODAL, {});
          }}
        >
          {!isMobile ? 'Get Started' : <span style={{ fontSize: '16px' }}>Get Started</span>}
        </Button>
      </div>
    );
  }

  getAvailableFeatureStatus(item) {
    if (item === true)
      return <i className={classnames(styles.checkIcon, 'fa fa-check-circle mr-2 mt-1')} />;
    if (!item)
      return <i className={classnames(styles.timesIcon, 'fa fa-times-circle mr-2 mt-1')} />;
    else return item;
  }

  renderDesktop() {
    const { intl: { formatMessage }, showModal } = this.props;
    return (
      <div className={classnames('container-fluid')}>
        <div className="row justify-content-center position-relative">
          <div className={classnames('col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-md-4')}>
            <h1 className={classnames(styles.metamorphPricingHeading, 'mt-5 text-center')}>
              Metamorph by Indorse
            </h1>
            <h1 className={classnames(styles.pricingTableTitle, 'mt-3 mb-0 text-center')}>
              <span className={classnames(styles.bold)}>Actionable analytics</span> for engineering
              leaders
            </h1>
            <h3 className={classnames(styles.pricingTableSubTitle, 'mt-2')}>
              {formatMessage(messages.pricingTable.subTitleOne)}
            </h3>
          </div>
        </div>
        <div className={classnames('col-12 row mx-auto mt-5 pl-0', styles.pricingTableDesktop)}>
          <div className={'col-3 px-0 mx-0'}>
            <div className={styles.abandonedCell} />
            <div className={styles.abandonedCell} />
            <div className={classnames(styles.toggleCell, styles.padded)}>
              <BillingMethodSwitch
                onToggle={() => this.setState({ isToggled: !this.state.isToggled })}
                id={'plan-toggle'}
                className={classnames(styles.toggleSwitch)}
                isActive={this.state.isToggled}
              />
            </div>

            {data.featureList.map((item, idx) => (
              <div
                className={classnames(
                  styles.featureCell,
                  styles.padded,
                  idx % 2 === 0 ? styles.coloredCell : ''
                )}
                key={idx}
              >
                <h3 style={{ fontSize: '14px' }} className={'m-0'}>
                  {item}
                </h3>
              </div>
            ))}
            <div className={styles.borderBottom} />
          </div>
          <div className={'col-3 px-0 mx-0'}>
            <div className={styles.quarterAbandonedCell} />
            <div className={styles.growthBox}>
              <div className={styles.growthPriceCell}>
                <h2 className={classnames(styles.boldBlack)}>Growth</h2>
                <div className={'d-flex justify-content-center align-items-top'}>
                  <div className={'d-flex align-items-top'}>
                    <span className={styles.superScript}>$</span>
                    <span className={styles.priceText}>{this.getGrowthPrice()}</span>
                  </div>
                </div>
                <div className={classnames(styles.perMonthBlack, 'text-center mt-1')}>/ month</div>
                <div className="mt-4 pt-2">
                  <Button
                    color={'border-black'}
                    className={classnames(styles.pricingTableButtonContactSales, 'px-4 mt-1')}
                    onMouseOver={() => window.fcWidget && window.fcWidget.setTags(['growth'])}
                    onClick={() => {
                      logEvent('LP_root_getstarted_clicked', {
                        label: 'Start Free Trial - Growth',
                        source: window && window.location.pathname,
                      });
                      showModal(GET_STARTED_MODAL, {});
                    }}
                  >
                    Start Free Trial
                  </Button>
                </div>
                <div className={classnames(styles.noCard)}>(No card required)</div>
              </div>
              {data.growth.features.map((item, idx) => (
                <div
                  className={classnames(
                    styles.featureCell,
                    styles.textCenter,
                    idx % 2 === 0 ? styles.coloredCell : ''
                  )}
                  key={idx}
                >
                  <h3 style={{ fontSize: '14px' }} className={'m-0'}>
                    {this.getAvailableFeatureStatus(item)}
                  </h3>
                </div>
              ))}
            </div>
          </div>
          <div className={'col-3 px-0 mx-0'}>
            <div
              style={{
                border: '3px solid #2a2498',
                borderRadius: '5px',
                backgroundColor: '#ffffff',
                boxShadow: '0 0 30px 0 rgba(0,82,204,0.15)',
              }}
            >
              <div className={styles.standardPriceCell}>
                <h2 className={classnames(styles.bold, styles.premium, 'text-center')}>
                  {data.premium.name}
                </h2>

                <div className={'d-flex justify-content-center align-items-top'}>
                  <div className={'d-flex align-items-top pt-3'}>
                    <span className={styles.superScript}>$</span>
                    <span className={styles.priceText}>{this.getPremiumPrice()}</span>
                  </div>
                </div>
                <div className={classnames(styles.perMonth, 'text-center')}>/ month</div>
                {this.renderGetStarted()}
                <div className={classnames(styles.noCard)}>(No card required)</div>
              </div>
              {data.premium.features.map((item, idx) => (
                <div className={classnames(styles.standardCell, styles.textCenter)} key={idx}>
                  <h3 style={{ fontSize: '14px' }} className={'m-0'}>
                    {this.getAvailableFeatureStatus(item)}
                  </h3>
                </div>
              ))}
              <div className={styles.emptyCell} />
            </div>
          </div>
          <div className={'col-3 px-0 mx-0'}>
            <div className={styles.quarterAbandonedCell} />
            <div className={styles.enterpriseBox}>
              <div className={styles.enterprisePriceCell}>
                <h2 className={classnames(styles.boldBlack)}>Enterprise</h2>
                <div className="d-flex justify-content-center">
                  <img
                    src="https://indorse-staging-bucket.s3.amazonaws.com/enterprise-check.svg"
                    alt="enterprise"
                    width="70"
                  />
                </div>
                <div className="mt-4 pt-3">
                  <Button
                    color={'border-black'}
                    className={classnames(styles.pricingTableButtonContactSales, 'px-4 py-2 mt-1')}
                    onMouseOver={() => window.fcWidget && window.fcWidget.setTags(['enterprise'])}
                    onClick={() => {
                      window.fcWidget &&
                        window.fcWidget.open({
                          replyText:
                            'Hey there, I would like to purchase a tailor-made enterprise plan!',
                        });
                      logEvent('LP_root_getstarted_clicked', {
                        label: 'Contact Sales',
                        source: window && window.location.pathname,
                      });
                    }}
                  >
                    Contact Sales
                  </Button>
                </div>
              </div>
              {data.enterprise.features.map((item, idx) => (
                <div
                  className={classnames(
                    styles.featureCell,
                    styles.textCenter,
                    idx % 2 === 0 ? styles.coloredCell : ''
                  )}
                  key={idx}
                >
                  <h3 style={{ fontSize: '14px' }} className={'m-0'}>
                    {this.getAvailableFeatureStatus(item)}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isCompanyCreated } = this.state;
    return (
      <div>
        {isCompanyCreated && this.renderCompanyCreatedThanksModal()}
        <div className={classnames(styles.pricingTableCompany, 'page-max-width')}>
          <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
            {this.renderDesktop()}
            <VerifyEmailModal />
            <LoginUserModal />
            <UserEmployeeModal />
            <LoggedInUserGetStartedModal />
            <ErrorModal />
            <CompanyExistsEmailSentModal />
            <CompanyExistsEmailSentModalLoggedIn />
            <GetStartedModal />
          </MediaQuery>
          <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
            {this.renderMobile()}
            <VerifyEmailModalMobile />
            <LoginUserModalMobile />
            <UserEmployeeModalMobile />
            <LoggedInUserGetStartedModalMobile />
            <ErrorModalMobile />
            <CompanyExistsEmailSentModalMobile />
            <CompanyExistsEmailSentModalLoggedInMobile />
            <GetStartedModalMobile />
          </MediaQuery>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
    addMessage: bindActionCreators(addMessage, dispatch),
    loginSuccessRequest: ({ token, redirect, from }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
      }),
  };
}

export default withRouter(
  connect(null, mapDispatchToProps)(
    reduxForm({
      form: FORM_NAME,
      validate,
    })(injectIntl(withApollo(PricingTable)))
  )
);
