import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';

import ButtonV2 from 'ui/common/rebranding/components/Button';
import LoadingSpinner from 'ui/common/rebranding/components/LoadingSpinner';

import classnames from 'classnames';
import styles from './index.module.scss';

const iconPositions = {
  prefix: 'prefix',
  suffix: 'suffix',
};

const { kind, ...ButtonV2PropTypesPassThrough } = ButtonV2.propTypes;

const propTypes = {
  ...ButtonV2PropTypesPassThrough,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  svgIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  iconPosition: PropTypes.oneOf([iconPositions.prefix, iconPositions.suffix]),
  loading: PropTypes.bool,
  disabledWhenLoading: PropTypes.bool,
  isReady: PropTypes.bool,
  textCenter: PropTypes.bool,
  printFriendly: PropTypes.bool,
};

const defaultProps = {
  iconPosition: iconPositions.prefix,
  disabledWhenLoading: true,
  isReady: true,
};

class Button extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  renderIcon(icon) {
    if (!icon) return null;
    if (typeof icon === 'string') return <i className={icon} />;
    return icon;
  }

  renderSvgIcon(svgIcon) {
    const { iconPosition } = this.props;

    if (!svgIcon) return null;
    if (typeof svgIcon === 'string' && iconPosition) {
      return (
        <img
          className={iconPosition === iconPositions.prefix ? 'mr-1' : 'ml-1'}
          height="16"
          width="16"
          src={svgIcon}
          alt="svgIcon"
        />
      );
    }

    return svgIcon;
  }

  render() {
    const {
      className,
      color,
      children,
      svgIcon,
      icon,
      iconPosition,
      loading,
      loadingIcon,
      disabled,
      disabledWhenLoading,
      isReady,
      textCenter,
      printFriendly,
      ...passThrough
    } = this.props;

    const disable = disabled || (loading && disabledWhenLoading);

    const loadingIconStyle = { width: '1.5rem', height: '1rem' };

    let kind = 'primary';
    if (
      [
        'primary',
        'secondary',
        'ternary',
        'disabled',
        'danger',
        'company-blue',
        'metamorph-blue',
        'company-blue-secondary',
        'company-dark-blue',
        'company-light-blue',
        'company-green',
        'company-purple',
        'company-purple-secondary',
        'border-black',
        'border-red',
        'green-special',
        'company-white',
        'primary-rect',
        'border-black-rect',
      ].indexOf(color) >= 0
    )
      kind = color;
    // if (disable) kind = 'disabled';

    return (
      <ButtonV2
        className={className}
        {...passThrough}
        kind={kind}
        isReady={isReady}
        disabled={disable}
      >
        <span
          className={classnames({
            'd-flex align-items-center': !textCenter && !printFriendly,
            'd-flex justify-content-center': textCenter && !printFriendly,
          })}
        >
          {iconPosition === iconPositions.prefix &&
            (loading ? (
              <LoadingSpinner
                className={classnames('mr-2', styles.loadingIcon)}
                style={loadingIconStyle}
              />
            ) : svgIcon ? (
              this.renderSvgIcon(svgIcon)
            ) : (
              this.renderIcon(icon)
            ))}
          {children}
          {iconPosition === iconPositions.suffix &&
            (loading ? (
              <LoadingSpinner
                className={classnames('ml-2', styles.loadingIcon)}
                style={loadingIconStyle}
              />
            ) : svgIcon ? (
              this.renderSvgIcon(svgIcon)
            ) : (
              this.renderIcon(icon)
            ))}
        </span>
      </ButtonV2>
    );
  }
}

Button.propTypes = propTypes;

Button.defaultProps = defaultProps;

export default Button;
