import { startCaseHandleSpecialChars } from 'utils/string';

const skillNameExceptions = {
  // key should always be lower case
  php: 'PHP',
  html: 'HTML',
  javascript: 'JavaScript',
};

export function getSkillDisplayName({ skill: { name } = {} } = {}, isReport = false) {
  if (!name) return null;
  if (isReport && name.toLowerCase() === 'postcovidhack') return 'Blockchain';
  else if (skillNameExceptions[name.toLowerCase()]) {
    return skillNameExceptions[name.toLowerCase()];
  } else {
    return startCaseHandleSpecialChars(name);
  }
}
