import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  routeAfterClaimCreation: '/profile',
  routeAfterQuizbotUsage: '/profile',
  routeAfterSignUp: '/profile',
  routeAfterClaimVerify: '/validate',
  routeAfterEmailVerify: '/profile',
  isClaimVerify: false,
  isEmailVerify: false,
  locationState: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SMART_ROUTE:
      return state.merge(action.payload);

    case actionTypes.SET_TO_DEFAULT:
      const { field } = action.payload;
      return state.set(field, initialState[field]);

    default:
      return state;
  }
}
