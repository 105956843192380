import { defineMessages } from 'react-intl';

export default defineMessages({
  myClaimsBreadcrumb: {
    id: 'claims.details.breadcrumb.my-claims',
    defaultMessage: 'My Claims',
  },
  myVotesBreadcrumb: {
    id: 'claims.details.breadcrumb.my-votes',
    defaultMessage: 'My Votes',
  },
  titleWithLevel: {
    id: 'claims.details.title-with-level',
    defaultMessage: 'I can code in {skillName} at the level of {level}',
  },
  titleWithoutLevel: {
    id: 'claims.details.title-without-level',
    defaultMessage: 'I can code in {skillName}',
  },
  titleForAssignmentClaim: {
    id: 'claims.details.title-for-assignment-claim',
    defaultMessage: 'I have solved a {jobRole} assignment in {skills}',
  },
  proofLabel: {
    id: 'claims.details.proof.label',
    defaultMessage: 'You can view my work at:',
  },
  claimStatus: {
    id: 'claims.details.claim-status.label',
    defaultMessage: 'Claim Status:',
  },
  voting: {
    id: 'claims.details.voting.label',
    defaultMessage: 'Voting',
  },
  selectSkillToVote: {
    id: 'claims.details.selectSkillToVote.label',
    defaultMessage: 'Please select a skill to vote',
  },
  signVote: {
    id: 'claims.details.signVote.label',
    defaultMessage: 'Sign your vote',
  },
  claimPartnerName: {
    id: 'claims.details.claim-partner-name.label',
    defaultMessage: 'From partner:',
  },
  flagPlagiarismAlert: {
    id: 'claims.details.flagPlagiarismAlert',
    defaultMessage: `Other validators have flagged this repo for plagiarism`,
  },
  voteReserveAlert: {
    id: 'claims.details..voteReserveAlert',
    defaultMessage:
      'Please complete the code review within the time period, exceeding the time period will release the reserved slot.',
  },
  voteReserveDescription: {
    id: 'claims.details..voteReserveDescription',
    defaultMessage: 'Please reserve your slot first, and then you can conduct a code review.',
  },
  noSlotAvailable: {
    id: 'claims.details.noSlotAvailable',
    defaultMessage: 'Slot unavailable.',
  },
  slotsAvailable: {
    id: 'claims.details.slotsAvailable',
    defaultMessage: 'Slot available.',
  },
});

export const content = defineMessages({
  toolTipsHeader: {
    id: 'claims.details.feedbacks.toolTipsHeader',
    defaultMessage: 'What are you voted for?',
  },
  feedbackSubtitle: {
    id: 'claims.details.feedbacks.feedbackSubtitle',
    defaultMessage: 'Feedback by the validators',
  },
  poorRating: {
    id: 'claims.details.feedbacks.rating.poorRating',
    defaultMessage: `Poor`,
  },
  goodRating: {
    id: 'claims.details.feedbacks.rating.goodRating',
    defaultMessage: `Good`,
  },
  explanationTitle: {
    id: 'claims.details.feedbacks.explanationTitle',
    defaultMessage: `General assessment about the skills`,
  },
  suggestedImprovementsTitle: {
    id: 'claims.details.feedbacks.suggestedImprovementsTitle',
    defaultMessage: `Suggestions for improvements`,
  },
});

export const feedbackSectionMessages = defineMessages({
  feedback: {
    id: 'claims.details.feedbackSection.feedback',
    defaultMessage: 'Comments',
  },
  voted: {
    id: 'claims.details.feedbackSection.voted',
    defaultMessage: 'Voted',
  },
  indorsed: {
    id: 'claims.details.feedbackSection.recommended',
    defaultMessage: 'Recommended',
  },
  flagged: {
    id: 'claims.details.feedbackSection.notRecommended',
    defaultMessage: 'Not Recommended',
  },
  reported: {
    id: 'claims.details.feedbackSection.reported',
    defaultMessage: 'Reported',
  },
  actions: {
    id: 'claims.details.feedbackSection.actions',
    defaultMessage: 'Actions',
  },
  hideBy: {
    id: 'claims.details.feedbackSection.hideBy',
    defaultMessage: 'Hidden By',
  },
});

export const buttons = defineMessages({
  back: {
    id: 'claims.details.buttons.back',
    defaultMessage: 'Back',
  },
  viewFeedback: {
    id: 'claims.details.buttons.viewFeedback',
    defaultMessage: 'Feedback by the validators',
  },
  indorse: {
    id: 'claims.details.buttons.indorse',
    defaultMessage: 'Indorse',
  },
  flag: {
    id: 'claims.details.buttons.flag',
    defaultMessage: 'Flag',
  },
  flagPlagiarism: {
    id: 'claims.details.buttons.flagPlagiarism',
    defaultMessage: 'Flag for Plagiarism',
  },
});

export const pdfViewMessages = defineMessages({
  title: {
    id: 'claims.details.pdfViewMessages.title',
    defaultMessage: `Indorse Claimant's Assessment Report`,
  },
  status: {
    id: 'claims.details.pdfViewMessages.status',
    defaultMessage: `Status:`,
  },
  numberOfVotes: {
    id: 'claims.details.pdfViewMessages.numberOfVotes',
    defaultMessage: `No. of Votes:`,
  },
  skill: {
    id: 'claims.details.pdfViewMessages.skill',
    defaultMessage: `Skill:`,
  },
  proficiencyLevel: {
    id: 'claims.details.pdfViewMessages.proficiencyLevel',
    defaultMessage: `Proficiency Level:`,
  },
  votesReceived: {
    id: 'claims.details.pdfViewMessages.votesReceived',
    defaultMessage: `Votes Received:`,
  },
  indorsedVotes: {
    id: 'claims.details.pdfViewMessages.indorsedVotes',
    defaultMessage: `{voteCount, number} {voteCount, plural, 
      one {vote}
      other {votes}
    }`,
  },
  repositoryLink: {
    id: 'claims.details.pdfViewMessages.repositoryLink',
    defaultMessage: `Repository Link:`,
  },
  goToPrintView: {
    id: 'claims.details.pdfViewMessages.goToPrintView',
    defaultMessage: `Go To Print View`,
  },
  description: {
    id: 'claims.details.pdfViewMessages.description',
    defaultMessage: `Description:`,
  },
  generatePDF: {
    id: 'claims.details.pdfViewMessages.generatePDF',
    defaultMessage: `Generate PDF`,
  },
  claimPartnerName: {
    id: 'claims.details.pdfViewMessages.claimPartnerName',
    defaultMessage: `From partner:`,
  },
});

export const dialog = defineMessages({
  confirm: {
    id: 'claims.details.feedbacks.confirm.dialog.message',
    defaultMessage:
      'Hide this comment only if it is inappropriate. If the comment is relevant but not elaborate, downvote instead.',
  },
  pdfDownloadMessage: {
    id: 'claims.details.download.pdf.pricing',
    defaultMessage:
      'Thanks for your interest! Since we are in a beta phase, we are giving out the report to our first 1000 users for free! You can now download PDF from the button on the upper right corner.',
  },
});

export const linkDetails = defineMessages({
  title: {
    id: 'claims.details.linkDetails.title',
    defaultMessage: 'Link Details',
  },
  accessible: {
    id: 'claims.details.linkDetails.accessible',
    defaultMessage: 'Link Accessible',
  },
  duplicate: {
    id: 'claims.details.linkDetails.duplicate',
    defaultMessage: 'Duplicate Claim',
  },
  forked: {
    id: 'claims.details.linkDetails.forked',
    defaultMessage: 'Forked Repository',
  },
  language: {
    id: 'claims.details.linkDetails.language',
    defaultMessage: 'Programming Language',
  },
  ownerCommits: {
    id: 'claims.details.linkDetails.ownerCommits',
    defaultMessage: 'Authenticated Commits',
  },
  totalCommits: {
    id: 'claims.details.linkDetails.totalCommits',
    defaultMessage: 'Total Commits',
  },
  contributors: {
    id: 'claims.details.linkDetails.contributors',
    defaultMessage: 'Repository Contributors',
  },
  positive: {
    id: 'claims.details.linkDetails.positive',
    defaultMessage: 'Yes',
  },
  negative: {
    id: 'claims.details.linkDetails.negative',
    defaultMessage: 'No',
  },
  viewAll: {
    id: 'claims.details.linkDetails.viewAll',
    defaultMessage: 'View All',
  },
  viewLess: {
    id: 'claims.details.linkDetails.viewLess',
    defaultMessage: 'View Less',
  },
});

export const duplicateClaim = defineMessages({
  claimLink: {
    id: 'claims.details.duplicateClaim.claimLink',
    defaultMessage: 'Claim Link',
  },
  status: {
    id: 'claims.details.duplicateClaim.status',
    defaultMessage: 'Status',
  },
  close: {
    id: 'claims.details.duplicateClaim.close',
    defaultMessage: 'Close',
  },
});
