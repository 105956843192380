/*
  GetStartedCompanyAdmin
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import indorseLogo from 'resources/brand/logo-icon.svg';
import styles from './index.module.scss';
import Button from 'ui/common/button';
import COMPETENCY_MAPPING from './Images/tech–competency-mapping.svg';
import SKILLS_GAP_ANALYSIS from './Images/skill_gap_analysis.svg';
// import PEOPLE_SKILL_ANALYTICS from './Images/people_skill_analytics.svg';
// import CUSTOMIZED_SKILLS_TEST from './Images/customized_skills_test.svg';
// import LEARNING_PATH from './Images/learning_paths.svg';
import SKILLS_MATRIX from './Images/skill_matrix.svg';
// import CONTINUOUS_PERFORMANCE from './Images/continuous_performance.svg';
// import REAL_TIME_CAPABILITIES from './Images/real_time_capabilities_insights.svg';
import GetStartedCompanyAdminReducer from 'store/graphql/GetStartedCompanyAdmin/reducer';
import injectReducer from 'utils/injectReducer';

import { selectCompanyByPrettyId } from 'store/features/companies/selectors';
import gql from 'graphql-tag';

import FeatureCard from './FeatureCard';
import { logEvent, logUserProperties } from 'services/amplitude-helper';
import routeGenerators from '../../../common/routes/generators';

const FEATURE_LIST = [
  {
    id: 'performance-analytics',
    image: SKILLS_GAP_ANALYSIS,
    heading: 'Performance Analytics',
  },
  {
    id: 'employee-engagement',
    image: COMPETENCY_MAPPING,
    heading: 'Employee Engagement',
  },
  {
    id: 'productivity',
    image: SKILLS_MATRIX,
    heading: 'Productivity',
  },
];

class GetStartedCompanyAdmin extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedCards: [],
    };
  }

  async submitLookingForFeatures() {
    const { client, company } = this.props;
    this.props.toNextStep();
    logEvent('submit_looking_for_features');
    try {
      await client.mutate({
        mutation: gql`
          mutation($webForm: CompanyAdminWebFormInput!) {
            company_postCompanyAdminWebForm(webForm: $webForm) {
              _id
            }
          }
        `,
        variables: {
          webForm: {
            companyId: company && company.id,
            type: 'EMS_COMPANY_LOOKING_FOR_FEATURES',
            mode: 'UPSERT',
            content: {
              selected: this.state.selectedCards,
            },
          },
        },
      });
      // this.props.history.push(routeGenerators.companies.edit({ prettyId, section: 'analysis' }));
    } catch (error) {
      console.log(error.message);
    }
  }

  selectFeatureCard(fId) {
    const { selectedCards } = this.state;

    if (selectedCards.find(cardId => cardId === fId)) {
      this.setState({ selectedCards: selectedCards.filter(cardId => cardId !== fId) }, function() {
        logUserProperties({
          objective: this.state.selectedCards,
          objective_performance: Boolean(
            this.state.selectedCards.find(id => id === 'performance-analytics')
          ),
          objective_engagement: Boolean(
            this.state.selectedCards.find(id => id === 'employee-engagement')
          ),
          objective_productivity: Boolean(
            this.state.selectedCards.find(id => id === 'productivity')
          ),
        });
      });
    } else {
      logEvent('select-feature');
      this.setState({ selectedCards: [...selectedCards, fId] }, function() {
        logUserProperties({
          objective: this.state.selectedCards,
          objective_performance: Boolean(
            this.state.selectedCards.find(id => id === 'performance-analytics')
          ),
          objective_engagement: Boolean(
            this.state.selectedCards.find(id => id === 'employee-engagement')
          ),
          objective_productivity: Boolean(
            this.state.selectedCards.find(id => id === 'productivity')
          ),
        });
      });
    }
  }

  renderFeatureCard(feature) {
    const isSelected = this.state.selectedCards.find(cardId => cardId === feature.id);
    return (
      <FeatureCard feature={feature} isSelected={isSelected} onSelect={this.selectFeatureCard} />
    );
  }

  render() {
    const { prettyId, setEvent } = this.props;
    return (
      <div className={classnames('container')}>
        <div className={classnames('row justify-content-center align-items-center')}>
          <div className="col-12">
            <img
              src={indorseLogo}
              className="d-block mx-auto pt-3 mt-3"
              width="60px"
              alt="arrow-down"
            />

            <h1 className={classnames(styles.pageTitle, 'indorse-blue text-center')}>Objectives</h1>
            <h3 className={classnames(styles.pageSubTitle, 'text-center pb-4')}>
              What do you want to achieve with Metamorph?
            </h3>

            <div className={classnames('my-3 py-3', styles.skillsGrid)}>
              {FEATURE_LIST.map(feature => this.renderFeatureCard(feature))}
            </div>

            <div className={'text-center'}>
              {this.props.isSlackConnected ? (
                <Button
                  style={{ padding: '13px 30px' }}
                  className={classnames(styles.getStarted)}
                  tag={Link}
                  to={routeGenerators.metamorph.edit({
                    prettyId,
                    section: 'dashboard',
                  })}
                  onClick={setEvent}
                  disabled={this.state.selectedCard === null}
                >
                  Let's Get Started &nbsp;
                  <i className="fa fa-arrow-right ml-3" />
                </Button>
              ) : (
                <div
                  className={classnames(
                    styles.actionBtns,
                    'd-flex align-items-center justify-content-between'
                  )}
                >
                  <Button
                    className={classnames(styles.getStartedSkip)}
                    tag={Link}
                    onClick={this.props.toNextStep}
                    color={'border-black'}
                  >
                    Skip
                  </Button>
                  <Button
                    className={classnames(styles.getStarted)}
                    tag={Link}
                    onClick={this.submitLookingForFeatures}
                    disabled={this.state.selectedCards.length === 0}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps && ownProps.match && ownProps.match.params;

  const prettyId = routeParams && routeParams.prettyId;
  const company = selectCompanyByPrettyId(state, { prettyId }) || {};
  return {
    prettyId,
    company,
  };
}

const withConnect = connect(mapStateToProps, null);

const withReducer = injectReducer({
  key: 'GetStartedCompanyAdmin',
  reducer: GetStartedCompanyAdminReducer,
});

export default withRouter(
  compose(withReducer, withConnect)(injectIntl(withApollo(withRouter(GetStartedCompanyAdmin))))
);
