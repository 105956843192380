import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

function Logo(props) {
  const { isIcon, className: classNameProp, titleAccess, viewBox, isSpecial, ...other } = props;

  const logoClassName = classNames('logo', { 'logo-icon': isIcon }, classNameProp);

  const logo = isIcon ? (
    <svg
      className={logoClassName}
      focusable="false"
      viewBox="0 0 26 26"
      aria-hidden={titleAccess ? 'false' : 'true'}
      {...other}
    >
      {titleAccess ? <title>{titleAccess}</title> : null}
      <g>
        <polygon points="0,13 0,26 13,26 26,26 26,13 13,13 	" />
        <path d="M26,0H0c0,7.2,5.8,13,13,13C20.2,13,26,7.2,26,0z" />
      </g>
    </svg>
  ) : (
    <img
      src="https://indorse-staging-bucket.s3.amazonaws.com/indorse.io.svg"
      alt="indorse-logo"
      width={isSpecial ? '140px' : '174px'}
    />
  );

  return <div className="logo-container">{logo}</div>;
}

Logo.propTypes = {
  // isIcon: PropTypes.bool,
  className: PropTypes.string,
  titleAccess: PropTypes.string,
  viewBox: PropTypes.string,
};

Logo.defaultProps = {
  color: 'inherit',
  viewBox: '0 0 24 24',
};

export default Logo;
