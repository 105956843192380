import React from 'react';

import privacy from 'resources/brand/privacy-policy-metamorph.content.html';

export default function() {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div
          className="col-12 col-md-8"
          dangerouslySetInnerHTML={{
            __html: privacy,
          }}
        />
      </div>
    </div>
  );
}
