import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from 'ui/common/loading';

import * as storage from 'store/features/auth/storage';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import EmsApps from 'store/graphql/EmsApps/reducer';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { errorsToSingleMessage, resultHasErrors } from '../../../utils/graphql-errors';
import { messageTypes } from '../../../store/features/common/flash/builder';
import { addMessage } from '../../../store/features/common/flash/actions';
import { show as showModal } from 'redux-modal';
import { push } from 'react-router-redux';
import routeGenerators from '../../common/routes/generators';
import Button from 'ui/common/button';
const SETUP = 'setup';
const CUSTOMIZE = 'customize';

export const JIRA_INTEGRATION_COMPANY_OBJ_KEY = 'JIRA_INTEGRATION_COMPANY_OBJ_KEY';

const JiraAppIntegration = props => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [projectList, setProjectList] = useState(null);

  const onProjectSelect = async id => {
    try {
      const { client, addMessage, company, push, from } = props;

      const response = await client.mutate({
        mutation: gql`
          mutation jiraApp_postInstallation($resourceId: String!, $companyId: String!) {
            jiraApp_postInstallation(resourceId: $resourceId, companyId: $companyId) {
              companyPrettyId
            }
          }
        `,
        variables: {
          resourceId: id,
          companyId: company && company.id,
        },
      });

      const { data, errors } = response;
      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        setError(true);
      } else {
        const { jiraApp_postInstallation } = data;
        storage.deleteDataInLocalStorage(JIRA_INTEGRATION_COMPANY_OBJ_KEY);
        // console.log('gitlabApp_postInstallation : ', gitlabApp_postInstallation);
        if (from === SETUP) {
          push(
            routeGenerators.ems.gitAppInstallSuccessSetup({
              prettyId: jiraApp_postInstallation.companyPrettyId,
              companyId: company && company.id,
            })
          );
        } else if (from === CUSTOMIZE) {
          push(
            routeGenerators.ems.gitAppInstallSuccessCustomize({
              prettyId: jiraApp_postInstallation.companyPrettyId,
              companyId: company && company.id,
            })
          );
        } else {
          push(
            routeGenerators.metamorph.edit({
              prettyId: jiraApp_postInstallation.companyPrettyId,
              section: 'dashboard',
            })
          );
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const installJiraApp = async () => {
    try {
      const { client, addMessage, bbCode, company } = props;

      const response = await client.mutate({
        mutation: gql`
          mutation jiraApp_installation($code: String!, $companyId: String!) {
            jiraApp_installation(code: $code, companyId: $companyId) {
              resources {
                id
                url
                name
                avatarUrl
              }
            }
          }
        `,
        variables: {
          code: bbCode,
          companyId: company && company.id,
        },
      });

      const { data, errors } = response;
      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        setError(true);
      } else {
        const { jiraApp_installation } = data;
        const { resources } = jiraApp_installation && jiraApp_installation;
        setProjectList(resources);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    // setCaEvent();
    setTimeout(() => installJiraApp(), 2000);
  }, []);

  if (Array.isArray(projectList)) {
    return (
      <div className="mt-5 pt-5">
        <div className={'my-5'}>
          <h1 className="text-center mb-4">Choose a project to integrate</h1>
        </div>
        <div className="mt-4 d-flex align-items-center justify-content-center">
          {projectList.map(projectItem => (
            <div
              key={projectItem.id}
              style={{ border: '1px solid #eeeeee', borderRadius: '3px' }}
              className="mx-4 p-4"
            >
              <img
                className="mx-auto"
                src={projectItem.avatarUrl}
                alt={projectItem.name}
                width="75px"
              />
              <h3 className="text-center mt-4 mb-5">{projectItem.name}</h3>
              <Button onClick={() => onProjectSelect(projectItem.id)} color="primary-rect">
                Connect
              </Button>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (isError) return <h1 className={'mt-5 text-center'}>Error</h1>;

  return isLoading && <Loading />;
};

function mapStateToProps(state, ownProps) {
  const query = new URLSearchParams(ownProps.location.search);
  const bbCode = query.get('code');
  let gitAppData = storage.getDataInLocalStorage(JIRA_INTEGRATION_COMPANY_OBJ_KEY);
  gitAppData = JSON.parse(gitAppData);
  const { company, from } = gitAppData;
  return {
    bbCode,
    company,
    from,
  };
}

const mapDispatchToProps = {
  addMessage,
  showModal,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'EmsApps',
  reducer: EmsApps,
});

export default compose(withReducer, withConnect)(withApollo(JiraAppIntegration));
